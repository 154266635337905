/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FetchPCPsRelatedToSelectedCenter,
    FetchPCPsRelatedToSelectedCenterFromJSON,
    FetchPCPsRelatedToSelectedCenterToJSON,
} from '../models/FetchPCPsRelatedToSelectedCenter'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface PcpsRequest {
    memberId: string;
    selectedPodId: string;
}


export interface IPcpsApi {
      pcps: (requestParameters: PcpsRequest) => Promise<FetchPCPsRelatedToSelectedCenter>
      pcpsWithResponse: (requestParameters: PcpsRequest) => Promise<{ value: FetchPCPsRelatedToSelectedCenter, response: any}>
}

/**
 * no description
 */
export class PcpsApi extends runtime.BaseAPI {

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcpsRaw = async (requestParameters: PcpsRequest): Promise<{ runtime: runtime.ApiResponse<FetchPCPsRelatedToSelectedCenter>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling pcps.');
        }

                        if (requestParameters.selectedPodId === null || requestParameters.selectedPodId === undefined) {
            throw new runtime.RequiredError('selectedPodId','Required parameter requestParameters.selectedPodId was null or undefined when calling pcps.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/selected_pod/{selected_pod_id}/pcps`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"selected_pod_id"}}`, encodeURIComponent(String(requestParameters.selectedPodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchPCPsRelatedToSelectedCenterFromJSON(jsonValue)), response };
    }

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcps = async (requestParameters: PcpsRequest): Promise<FetchPCPsRelatedToSelectedCenter> => {
        const { runtime } = await this.pcpsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcpsWithResponse = async (requestParameters: PcpsRequest): Promise<{ value: FetchPCPsRelatedToSelectedCenter, response: any}> => {
        const { runtime, response } = await this.pcpsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPcpsApi: IPcpsApi  = new PcpsApi();
