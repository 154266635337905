/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CMSDetailsItemSchema,
    CMSDetailsItemSchemaFromJSON,
    CMSDetailsItemSchemaToJSON,
} from '../models/CMSDetailsItemSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchCmsItemRequest {
    cmsContentType: string;
    cmsItemId: string;
}


export interface ICmsItemsApi {
      fetchCmsItem: (requestParameters: FetchCmsItemRequest) => Promise<CMSDetailsItemSchema>
      fetchCmsItemWithResponse: (requestParameters: FetchCmsItemRequest) => Promise<{ value: CMSDetailsItemSchema, response: any}>
}

/**
 * no description
 */
export class CmsItemsApi extends runtime.BaseAPI {

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItemRaw = async (requestParameters: FetchCmsItemRequest): Promise<{ runtime: runtime.ApiResponse<CMSDetailsItemSchema>, response: any}> => {
        if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
            throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling fetchCmsItem.');
        }

                        if (requestParameters.cmsItemId === null || requestParameters.cmsItemId === undefined) {
            throw new runtime.RequiredError('cmsItemId','Required parameter requestParameters.cmsItemId was null or undefined when calling fetchCmsItem.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/{cms_content_type}/{cms_item_id}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))).replace(`{${"cms_item_id"}}`, encodeURIComponent(String(requestParameters.cmsItemId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CMSDetailsItemSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItem = async (requestParameters: FetchCmsItemRequest): Promise<CMSDetailsItemSchema> => {
        const { runtime } = await this.fetchCmsItemRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItemWithResponse = async (requestParameters: FetchCmsItemRequest): Promise<{ value: CMSDetailsItemSchema, response: any}> => {
        const { runtime, response } = await this.fetchCmsItemRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsItemsApi: ICmsItemsApi  = new CmsItemsApi();
