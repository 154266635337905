/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConversationSummariesResponse,
    ConversationSummariesResponseFromJSON,
    ConversationSummariesResponseToJSON,
} from '../models/ConversationSummariesResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface MemberConversationListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
    pageSize?: string;
    page?: string;
    state?: MemberConversationListSummariesStateEnum;
}


export interface IConversationListApi {
      memberConversationListSummaries: (requestParameters: MemberConversationListSummariesRequest) => Promise<ConversationSummariesResponse>
      memberConversationListSummariesWithResponse: (requestParameters: MemberConversationListSummariesRequest) => Promise<{ value: ConversationSummariesResponse, response: any}>
}

/**
 * no description
 */
export class ConversationListApi extends runtime.BaseAPI {

    /**
     * Fetch summaries for the Member Episode list
     * Member conversation summaries
     */
    memberConversationListSummariesRaw = async (requestParameters: MemberConversationListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<ConversationSummariesResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberConversationListSummaries.');
        }

                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/conversations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ConversationSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the Member Episode list
     * Member conversation summaries
     */
    memberConversationListSummaries = async (requestParameters: MemberConversationListSummariesRequest): Promise<ConversationSummariesResponse> => {
        const { runtime } = await this.memberConversationListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the Member Episode list
     * Member conversation summaries
     */
    memberConversationListSummariesWithResponse = async (requestParameters: MemberConversationListSummariesRequest): Promise<{ value: ConversationSummariesResponse, response: any}> => {
        const { runtime, response } = await this.memberConversationListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum MemberConversationListSummariesStateEnum {
    Active = 'active',
    Archived = 'archived',
    Suspended = 'suspended'
}

export const fetchConversationListApi: IConversationListApi  = new ConversationListApi();
