/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CMSListSchema,
    CMSListSchemaFromJSON,
    CMSListSchemaToJSON,
} from '../models/CMSListSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetCMSListRequest {
    cmsContentType: string;
    q?: string;
    offset?: number;
    count?: number;
}


export interface ICmsListsApi {
      getCMSList: (requestParameters: GetCMSListRequest) => Promise<CMSListSchema>
      getCMSListWithResponse: (requestParameters: GetCMSListRequest) => Promise<{ value: CMSListSchema, response: any}>
}

/**
 * no description
 */
export class CmsListsApi extends runtime.BaseAPI {

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSListRaw = async (requestParameters: GetCMSListRequest): Promise<{ runtime: runtime.ApiResponse<CMSListSchema>, response: any}> => {
        if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
            throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling getCMSList.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/{cms_content_type}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CMSListSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSList = async (requestParameters: GetCMSListRequest): Promise<CMSListSchema> => {
        const { runtime } = await this.getCMSListRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSListWithResponse = async (requestParameters: GetCMSListRequest): Promise<{ value: CMSListSchema, response: any}> => {
        const { runtime, response } = await this.getCMSListRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsListsApi: ICmsListsApi  = new CmsListsApi();
