import isEqual from "lodash/isEqual";
import { UserUpcomingAppointmentsListSummariesRequest, userUpcomingAppointmentsListSummaries } from "shared/api/src/apis/UpcomingAppointmentsApi";
import { ReferralAppointment } from "shared/fetch/src/models/ReferralAppointment";
import { UpcomingAppointmentsResponse } from "shared/fetch/src/models/UpcomingAppointmentsResponse";

const getUpcomingAppointmentsRequest = (
  payload: UserUpcomingAppointmentsListSummariesRequest
) =>
  userUpcomingAppointmentsListSummaries(payload, {
    transform: (responseBody: UpcomingAppointmentsResponse) => ({
      upcomingAppointments: { [payload.id]: responseBody.results },
    }),
    update: {
      // @ts-ignore: Object is possibly 'null'.
      upcomingAppointments: (
        prev: { [key: string]: ReferralAppointment[] },
        next: { [key: string]: ReferralAppointment[] }
      ) => {
        if (isEqual(prev, next)) {
          return prev;
        }

        return { ...prev, ...next };
      },
    },
  });

export default getUpcomingAppointmentsRequest;
