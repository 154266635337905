/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberPushNotificationArrayResponse,
    MemberPushNotificationArrayResponseFromJSON,
    MemberPushNotificationArrayResponseToJSON,
} from '../models/MemberPushNotificationArrayResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UserPushNotificationOutput,
    UserPushNotificationOutputFromJSON,
    UserPushNotificationOutputToJSON,
} from '../models/UserPushNotificationOutput'


export interface ClearsAllReadNotificationsForAUserRequest {
    id: string;
}
export interface GetUserPushNotificationsRequest {
    id: string;
    page?: string;
    pageSize?: string;
    state?: GetUserPushNotificationsStateEnum;
}
export interface MarkUserPushNotificationAsReadRequest {
    notificationId: string;
}
export interface MarksAllNotificationsAsReadForAUserRequest {
    id: string;
}


export interface IUserPushNotificationsApi {
      clearsAllReadNotificationsForAUser: (requestParameters: ClearsAllReadNotificationsForAUserRequest) => Promise<void>
      getUserPushNotifications: (requestParameters: GetUserPushNotificationsRequest) => Promise<MemberPushNotificationArrayResponse>
      markUserPushNotificationAsRead: (requestParameters: MarkUserPushNotificationAsReadRequest) => Promise<UserPushNotificationOutput>
      marksAllNotificationsAsReadForAUser: (requestParameters: MarksAllNotificationsAsReadForAUserRequest) => Promise<void>
      clearsAllReadNotificationsForAUserWithResponse: (requestParameters: ClearsAllReadNotificationsForAUserRequest) => Promise<{ value: void, response: any}>
      getUserPushNotificationsWithResponse: (requestParameters: GetUserPushNotificationsRequest) => Promise<{ value: MemberPushNotificationArrayResponse, response: any}>
      markUserPushNotificationAsReadWithResponse: (requestParameters: MarkUserPushNotificationAsReadRequest) => Promise<{ value: UserPushNotificationOutput, response: any}>
      marksAllNotificationsAsReadForAUserWithResponse: (requestParameters: MarksAllNotificationsAsReadForAUserRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class UserPushNotificationsApi extends runtime.BaseAPI {

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUserRaw = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clearsAllReadNotificationsForAUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/{id}/clear_notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUser = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<void> => {
        const { runtime } = await this.clearsAllReadNotificationsForAUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUserWithResponse = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.clearsAllReadNotificationsForAUserRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotificationsRaw = async (requestParameters: GetUserPushNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<MemberPushNotificationArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserPushNotifications.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/user_push_notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberPushNotificationArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotifications = async (requestParameters: GetUserPushNotificationsRequest): Promise<MemberPushNotificationArrayResponse> => {
        const { runtime } = await this.getUserPushNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotificationsWithResponse = async (requestParameters: GetUserPushNotificationsRequest): Promise<{ value: MemberPushNotificationArrayResponse, response: any}> => {
        const { runtime, response } = await this.getUserPushNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsReadRaw = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<{ runtime: runtime.ApiResponse<UserPushNotificationOutput>, response: any}> => {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling markUserPushNotificationAsRead.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/mark_as_read/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserPushNotificationOutputFromJSON(jsonValue)), response };
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsRead = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<UserPushNotificationOutput> => {
        const { runtime } = await this.markUserPushNotificationAsReadRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsReadWithResponse = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<{ value: UserPushNotificationOutput, response: any}> => {
        const { runtime, response } = await this.markUserPushNotificationAsReadRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUserRaw = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling marksAllNotificationsAsReadForAUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/{id}/mark_all_as_read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUser = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<void> => {
        const { runtime } = await this.marksAllNotificationsAsReadForAUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUserWithResponse = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.marksAllNotificationsAsReadForAUserRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetUserPushNotificationsStateEnum {
    Read = 'read',
    Sent = 'sent'
}

export const fetchUserPushNotificationsApi: IUserPushNotificationsApi  = new UserPushNotificationsApi();
