import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import {
  EnrollDevicePushNotificationRequest,
  fetchEnrollDevicePushNotificationApi,
} from "shared/fetch/src/apis/EnrollDevicePushNotificationApi";

const useEnrollDevicePushNotifications = (
  isInitialEnrollment: boolean,
  userId: string | undefined
) => {
  const dispatch = useDispatch();
  const { deviceId } = selectNativeAppState();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (request: EnrollDevicePushNotificationRequest) => {
      return fetchEnrollDevicePushNotificationApi
        .enrollDevicePushNotification(request)
        .then(() => {
          queryClient.invalidateQueries([
            "pushNotificationCategories",
            deviceId,
          ]);
          queryClient.invalidateQueries(["memberEnrolledDevices", userId]);
          if (isInitialEnrollment) {
            dispatch(
              showSnackbar("Device has been enrolled in push notifications.")
            );
          }
        })
        .catch(() => {
          dispatch(
            showSnackbar(
              isInitialEnrollment
                ? "There was an issue enrolling your device, please try again later."
                : "There was a problem updating your device to receive push notifications.",
              "danger"
            )
          );
        });
    }
  );
  return { enrollDevicePushNotifications: mutate };
};

export default useEnrollDevicePushNotifications;
