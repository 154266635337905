/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateDeviceRequestBody,
    UpdateDeviceRequestBodyFromJSON,
    UpdateDeviceRequestBodyToJSON,
} from '../models/UpdateDeviceRequestBody'


export interface UpdateEnrolledDeviceRequest {
    deviceId: string;
    updateDeviceRequestBody?: UpdateDeviceRequestBody;
}


export interface IUpdateEnrolledDeviceApi {
      updateEnrolledDevice: (requestParameters: UpdateEnrolledDeviceRequest) => Promise<void>
      updateEnrolledDeviceWithResponse: (requestParameters: UpdateEnrolledDeviceRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class UpdateEnrolledDeviceApi extends runtime.BaseAPI {

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDeviceRaw = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateEnrolledDevice.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeviceRequestBodyToJSON(requestParameters.updateDeviceRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDevice = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<void> => {
        const { runtime } = await this.updateEnrolledDeviceRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDeviceWithResponse = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.updateEnrolledDeviceRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchUpdateEnrolledDeviceApi: IUpdateEnrolledDeviceApi  = new UpdateEnrolledDeviceApi();
