/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcknowledgeAndRespondToComponentsRequestBody,
    AcknowledgeAndRespondToComponentsRequestBodyFromJSON,
    AcknowledgeAndRespondToComponentsRequestBodyToJSON,
} from '../models/AcknowledgeAndRespondToComponentsRequestBody'
import {
    AcknowledgeComponentsRequestBody,
    AcknowledgeComponentsRequestBodyFromJSON,
    AcknowledgeComponentsRequestBodyToJSON,
} from '../models/AcknowledgeComponentsRequestBody'
import {
    BillingComponent,
    BillingComponentFromJSON,
    BillingComponentToJSON,
} from '../models/BillingComponent'
import {
    Charge,
    ChargeFromJSON,
    ChargeToJSON,
} from '../models/Charge'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ComponentSubmitRequestBody,
    ComponentSubmitRequestBodyFromJSON,
    ComponentSubmitRequestBodyToJSON,
} from '../models/ComponentSubmitRequestBody'
import {
    ComponentsAcknowledgedResponse,
    ComponentsAcknowledgedResponseFromJSON,
    ComponentsAcknowledgedResponseToJSON,
} from '../models/ComponentsAcknowledgedResponse'
import {
    ComponentsCancellationPolicyResponse,
    ComponentsCancellationPolicyResponseFromJSON,
    ComponentsCancellationPolicyResponseToJSON,
} from '../models/ComponentsCancellationPolicyResponse'
import {
    ComponentsEditChargeResponse,
    ComponentsEditChargeResponseFromJSON,
    ComponentsEditChargeResponseToJSON,
} from '../models/ComponentsEditChargeResponse'
import {
    DeclineVisitRequestBody,
    DeclineVisitRequestBodyFromJSON,
    DeclineVisitRequestBodyToJSON,
} from '../models/DeclineVisitRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MarkComponentsAsViewedSchema,
    MarkComponentsAsViewedSchemaFromJSON,
    MarkComponentsAsViewedSchemaToJSON,
} from '../models/MarkComponentsAsViewedSchema'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ReferralComponentDetailsOutput,
    ReferralComponentDetailsOutputFromJSON,
    ReferralComponentDetailsOutputToJSON,
} from '../models/ReferralComponentDetailsOutput'
import {
    SelfScheduleAppointmentComponent,
    SelfScheduleAppointmentComponentFromJSON,
    SelfScheduleAppointmentComponentToJSON,
} from '../models/SelfScheduleAppointmentComponent'
import {
    TimeSlotsFetchResponse,
    TimeSlotsFetchResponseFromJSON,
    TimeSlotsFetchResponseToJSON,
} from '../models/TimeSlotsFetchResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateComponentRequestBody,
    UpdateComponentRequestBodyFromJSON,
    UpdateComponentRequestBodyToJSON,
} from '../models/UpdateComponentRequestBody'


export interface AcknowledgeAndRespondToComponentRequest {
    id: string;
}
export interface AcknowledgeAndRespondToComponentsRequest {
    acknowledgeAndRespondToComponentsRequestBody?: AcknowledgeAndRespondToComponentsRequestBody;
}
export interface AcknowledgeComponentRequest {
    id: string;
}
export interface AcknowledgeComponentsRequest {
    acknowledgeComponentsRequestBody?: AcknowledgeComponentsRequestBody;
}
export interface AppointmentCancellationPolicyOfComponentByIDRequest {
    id: string;
}
export interface DeclineVisitRequest {
    id: string;
    declineVisitRequestBody?: DeclineVisitRequestBody;
}
export interface FetchComponentRequest {
    id: string;
}
export interface GetBookableSlotsForComponentRequest {
    id: string;
    startDate: string;
    endDate: string;
    page?: string;
    staffGlobalId?: string;
    timezone?: string;
    clinicId?: string;
    schedulingType?: GetBookableSlotsForComponentSchedulingTypeEnum;
}
export interface MarkBillingComponentAsRequestedRequest {
    id: string;
}
export interface MarkComponentAsPaidRequest {
    id: string;
}
export interface MarkComponentAsViewedRequest {
    id: string;
}
export interface MarkComponentsAsViewedRequest {
    markComponentsAsViewedSchema: MarkComponentsAsViewedSchema;
}
export interface RespondToComponentByIDRequest {
    id: string;
}
export interface SelfScheduleAppointmentComponentByEpisodeIDRequest {
    id: string;
}
export interface SubmitComponentRequest {
    id: string;
    componentSubmitRequestBody?: ComponentSubmitRequestBody;
}
export interface UpdateChargeRequest {
    id: string;
    charge?: Charge;
}
export interface UpdateComponentRequest {
    id: string;
    updateComponentRequestBody?: UpdateComponentRequestBody;
}


export interface IComponentsApi {
      acknowledgeAndRespondToComponent: (requestParameters: AcknowledgeAndRespondToComponentRequest) => Promise<ComponentDetailsOutput>
      acknowledgeAndRespondToComponents: (requestParameters: AcknowledgeAndRespondToComponentsRequest) => Promise<ComponentsAcknowledgedResponse>
      acknowledgeComponent: (requestParameters: AcknowledgeComponentRequest) => Promise<ComponentDetailsOutput>
      acknowledgeComponents: (requestParameters: AcknowledgeComponentsRequest) => Promise<ComponentsAcknowledgedResponse>
      appointmentCancellationPolicyOfComponentByID: (requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest) => Promise<ComponentsCancellationPolicyResponse>
      declineVisit: (requestParameters: DeclineVisitRequest) => Promise<ReferralComponentDetailsOutput>
      fetchComponent: (requestParameters: FetchComponentRequest) => Promise<ComponentDetailsOutput>
      getBookableSlotsForComponent: (requestParameters: GetBookableSlotsForComponentRequest) => Promise<TimeSlotsFetchResponse>
      markBillingComponentAsRequested: (requestParameters: MarkBillingComponentAsRequestedRequest) => Promise<BillingComponent>
      markComponentAsPaid: (requestParameters: MarkComponentAsPaidRequest) => Promise<BillingComponent>
      markComponentAsViewed: (requestParameters: MarkComponentAsViewedRequest) => Promise<void>
      markComponentsAsViewed: (requestParameters: MarkComponentsAsViewedRequest) => Promise<void>
      respondToComponentByID: (requestParameters: RespondToComponentByIDRequest) => Promise<ComponentDetailsOutput>
      selfScheduleAppointmentComponentByEpisodeID: (requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest) => Promise<SelfScheduleAppointmentComponent>
      submitComponent: (requestParameters: SubmitComponentRequest) => Promise<ComponentDetailsOutput>
      updateCharge: (requestParameters: UpdateChargeRequest) => Promise<ComponentsEditChargeResponse>
      updateComponent: (requestParameters: UpdateComponentRequest) => Promise<ComponentDetailsOutput>
      acknowledgeAndRespondToComponentWithResponse: (requestParameters: AcknowledgeAndRespondToComponentRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      acknowledgeAndRespondToComponentsWithResponse: (requestParameters: AcknowledgeAndRespondToComponentsRequest) => Promise<{ value: ComponentsAcknowledgedResponse, response: any}>
      acknowledgeComponentWithResponse: (requestParameters: AcknowledgeComponentRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      acknowledgeComponentsWithResponse: (requestParameters: AcknowledgeComponentsRequest) => Promise<{ value: ComponentsAcknowledgedResponse, response: any}>
      appointmentCancellationPolicyOfComponentByIDWithResponse: (requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest) => Promise<{ value: ComponentsCancellationPolicyResponse, response: any}>
      declineVisitWithResponse: (requestParameters: DeclineVisitRequest) => Promise<{ value: ReferralComponentDetailsOutput, response: any}>
      fetchComponentWithResponse: (requestParameters: FetchComponentRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      getBookableSlotsForComponentWithResponse: (requestParameters: GetBookableSlotsForComponentRequest) => Promise<{ value: TimeSlotsFetchResponse, response: any}>
      markBillingComponentAsRequestedWithResponse: (requestParameters: MarkBillingComponentAsRequestedRequest) => Promise<{ value: BillingComponent, response: any}>
      markComponentAsPaidWithResponse: (requestParameters: MarkComponentAsPaidRequest) => Promise<{ value: BillingComponent, response: any}>
      markComponentAsViewedWithResponse: (requestParameters: MarkComponentAsViewedRequest) => Promise<{ value: void, response: any}>
      markComponentsAsViewedWithResponse: (requestParameters: MarkComponentsAsViewedRequest) => Promise<{ value: void, response: any}>
      respondToComponentByIDWithResponse: (requestParameters: RespondToComponentByIDRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      selfScheduleAppointmentComponentByEpisodeIDWithResponse: (requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest) => Promise<{ value: SelfScheduleAppointmentComponent, response: any}>
      submitComponentWithResponse: (requestParameters: SubmitComponentRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      updateChargeWithResponse: (requestParameters: UpdateChargeRequest) => Promise<{ value: ComponentsEditChargeResponse, response: any}>
      updateComponentWithResponse: (requestParameters: UpdateComponentRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
}

/**
 * no description
 */
export class ComponentsApi extends runtime.BaseAPI {

    /**
     * Acknowledge and Respond to component by ID
     * Acknowledge and Respond to component by ID
     */
    acknowledgeAndRespondToComponentRaw = async (requestParameters: AcknowledgeAndRespondToComponentRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acknowledgeAndRespondToComponent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/acknowledge_and_respond`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge and Respond to component by ID
     * Acknowledge and Respond to component by ID
     */
    acknowledgeAndRespondToComponent = async (requestParameters: AcknowledgeAndRespondToComponentRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.acknowledgeAndRespondToComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge and Respond to component by ID
     * Acknowledge and Respond to component by ID
     */
    acknowledgeAndRespondToComponentWithResponse = async (requestParameters: AcknowledgeAndRespondToComponentRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.acknowledgeAndRespondToComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Acknowledge and respond to components by IDs
     * Acknowledge and respond to components by IDs
     */
    acknowledgeAndRespondToComponentsRaw = async (requestParameters: AcknowledgeAndRespondToComponentsRequest): Promise<{ runtime: runtime.ApiResponse<ComponentsAcknowledgedResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/acknowledge_and_respond`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcknowledgeAndRespondToComponentsRequestBodyToJSON(requestParameters.acknowledgeAndRespondToComponentsRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentsAcknowledgedResponseFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge and respond to components by IDs
     * Acknowledge and respond to components by IDs
     */
    acknowledgeAndRespondToComponents = async (requestParameters: AcknowledgeAndRespondToComponentsRequest): Promise<ComponentsAcknowledgedResponse> => {
        const { runtime } = await this.acknowledgeAndRespondToComponentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge and respond to components by IDs
     * Acknowledge and respond to components by IDs
     */
    acknowledgeAndRespondToComponentsWithResponse = async (requestParameters: AcknowledgeAndRespondToComponentsRequest): Promise<{ value: ComponentsAcknowledgedResponse, response: any}> => {
        const { runtime, response } = await this.acknowledgeAndRespondToComponentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Acknowledge component by ID
     * Acknowledge component by ID
     */
    acknowledgeComponentRaw = async (requestParameters: AcknowledgeComponentRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acknowledgeComponent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/acknowledge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge component by ID
     * Acknowledge component by ID
     */
    acknowledgeComponent = async (requestParameters: AcknowledgeComponentRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.acknowledgeComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge component by ID
     * Acknowledge component by ID
     */
    acknowledgeComponentWithResponse = async (requestParameters: AcknowledgeComponentRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.acknowledgeComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Acknowledge components by IDs
     * Acknowledge components by IDs
     */
    acknowledgeComponentsRaw = async (requestParameters: AcknowledgeComponentsRequest): Promise<{ runtime: runtime.ApiResponse<ComponentsAcknowledgedResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/acknowledge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcknowledgeComponentsRequestBodyToJSON(requestParameters.acknowledgeComponentsRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentsAcknowledgedResponseFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge components by IDs
     * Acknowledge components by IDs
     */
    acknowledgeComponents = async (requestParameters: AcknowledgeComponentsRequest): Promise<ComponentsAcknowledgedResponse> => {
        const { runtime } = await this.acknowledgeComponentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge components by IDs
     * Acknowledge components by IDs
     */
    acknowledgeComponentsWithResponse = async (requestParameters: AcknowledgeComponentsRequest): Promise<{ value: ComponentsAcknowledgedResponse, response: any}> => {
        const { runtime, response } = await this.acknowledgeComponentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch cancellation policy for the appointment associated with the component
     */
    appointmentCancellationPolicyOfComponentByIDRaw = async (requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest): Promise<{ runtime: runtime.ApiResponse<ComponentsCancellationPolicyResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling appointmentCancellationPolicyOfComponentByID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/cancellation_policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentsCancellationPolicyResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch cancellation policy for the appointment associated with the component
     */
    appointmentCancellationPolicyOfComponentByID = async (requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest): Promise<ComponentsCancellationPolicyResponse> => {
        const { runtime } = await this.appointmentCancellationPolicyOfComponentByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch cancellation policy for the appointment associated with the component
     */
    appointmentCancellationPolicyOfComponentByIDWithResponse = async (requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest): Promise<{ value: ComponentsCancellationPolicyResponse, response: any}> => {
        const { runtime, response } = await this.appointmentCancellationPolicyOfComponentByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Member declines Component by ID
     * Member declines Component by ID
     */
    declineVisitRaw = async (requestParameters: DeclineVisitRequest): Promise<{ runtime: runtime.ApiResponse<ReferralComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declineVisit.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeclineVisitRequestBodyToJSON(requestParameters.declineVisitRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ReferralComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Member declines Component by ID
     * Member declines Component by ID
     */
    declineVisit = async (requestParameters: DeclineVisitRequest): Promise<ReferralComponentDetailsOutput> => {
        const { runtime } = await this.declineVisitRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Member declines Component by ID
     * Member declines Component by ID
     */
    declineVisitWithResponse = async (requestParameters: DeclineVisitRequest): Promise<{ value: ReferralComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.declineVisitRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch a component by ID
     * Fetch a component by ID
     */
    fetchComponentRaw = async (requestParameters: FetchComponentRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchComponent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a component by ID
     * Fetch a component by ID
     */
    fetchComponent = async (requestParameters: FetchComponentRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.fetchComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a component by ID
     * Fetch a component by ID
     */
    fetchComponentWithResponse = async (requestParameters: FetchComponentRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.fetchComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch all bookable slots for given component
     * Fetch all bookable slots for given component
     */
    getBookableSlotsForComponentRaw = async (requestParameters: GetBookableSlotsForComponentRequest): Promise<{ runtime: runtime.ApiResponse<TimeSlotsFetchResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookableSlotsForComponent.');
        }

                        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBookableSlotsForComponent.');
        }

                        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getBookableSlotsForComponent.');
        }

                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.staffGlobalId !== undefined) {
            queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/slots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimeSlotsFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all bookable slots for given component
     * Fetch all bookable slots for given component
     */
    getBookableSlotsForComponent = async (requestParameters: GetBookableSlotsForComponentRequest): Promise<TimeSlotsFetchResponse> => {
        const { runtime } = await this.getBookableSlotsForComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch all bookable slots for given component
     * Fetch all bookable slots for given component
     */
    getBookableSlotsForComponentWithResponse = async (requestParameters: GetBookableSlotsForComponentRequest): Promise<{ value: TimeSlotsFetchResponse, response: any}> => {
        const { runtime, response } = await this.getBookableSlotsForComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Mark a billing component as requested by ID
     * Mark a billing component as requested by ID
     */
    markBillingComponentAsRequestedRaw = async (requestParameters: MarkBillingComponentAsRequestedRequest): Promise<{ runtime: runtime.ApiResponse<BillingComponent>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markBillingComponentAsRequested.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/mark_as_requested`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => BillingComponentFromJSON(jsonValue)), response };
    }

    /**
     * Mark a billing component as requested by ID
     * Mark a billing component as requested by ID
     */
    markBillingComponentAsRequested = async (requestParameters: MarkBillingComponentAsRequestedRequest): Promise<BillingComponent> => {
        const { runtime } = await this.markBillingComponentAsRequestedRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark a billing component as requested by ID
     * Mark a billing component as requested by ID
     */
    markBillingComponentAsRequestedWithResponse = async (requestParameters: MarkBillingComponentAsRequestedRequest): Promise<{ value: BillingComponent, response: any}> => {
        const { runtime, response } = await this.markBillingComponentAsRequestedRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Mark a component as paid by ID
     * Mark a component as paid by ID
     */
    markComponentAsPaidRaw = async (requestParameters: MarkComponentAsPaidRequest): Promise<{ runtime: runtime.ApiResponse<BillingComponent>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markComponentAsPaid.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/mark_as_paid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => BillingComponentFromJSON(jsonValue)), response };
    }

    /**
     * Mark a component as paid by ID
     * Mark a component as paid by ID
     */
    markComponentAsPaid = async (requestParameters: MarkComponentAsPaidRequest): Promise<BillingComponent> => {
        const { runtime } = await this.markComponentAsPaidRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark a component as paid by ID
     * Mark a component as paid by ID
     */
    markComponentAsPaidWithResponse = async (requestParameters: MarkComponentAsPaidRequest): Promise<{ value: BillingComponent, response: any}> => {
        const { runtime, response } = await this.markComponentAsPaidRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Mark a component as viewed by ID
     * Mark a component as viewed by ID
     */
    markComponentAsViewedRaw = async (requestParameters: MarkComponentAsViewedRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markComponentAsViewed.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/viewed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Mark a component as viewed by ID
     * Mark a component as viewed by ID
     */
    markComponentAsViewed = async (requestParameters: MarkComponentAsViewedRequest): Promise<void> => {
        const { runtime } = await this.markComponentAsViewedRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark a component as viewed by ID
     * Mark a component as viewed by ID
     */
    markComponentAsViewedWithResponse = async (requestParameters: MarkComponentAsViewedRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.markComponentAsViewedRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Mark components as viewed by ID\'s
     * Mark components as viewed by ID\'s
     */
    markComponentsAsViewedRaw = async (requestParameters: MarkComponentsAsViewedRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.markComponentsAsViewedSchema === null || requestParameters.markComponentsAsViewedSchema === undefined) {
            throw new runtime.RequiredError('markComponentsAsViewedSchema','Required parameter requestParameters.markComponentsAsViewedSchema was null or undefined when calling markComponentsAsViewed.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/viewed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarkComponentsAsViewedSchemaToJSON(requestParameters.markComponentsAsViewedSchema),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Mark components as viewed by ID\'s
     * Mark components as viewed by ID\'s
     */
    markComponentsAsViewed = async (requestParameters: MarkComponentsAsViewedRequest): Promise<void> => {
        const { runtime } = await this.markComponentsAsViewedRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark components as viewed by ID\'s
     * Mark components as viewed by ID\'s
     */
    markComponentsAsViewedWithResponse = async (requestParameters: MarkComponentsAsViewedRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.markComponentsAsViewedRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Respond to component by ID
     * Respond to component by ID
     */
    respondToComponentByIDRaw = async (requestParameters: RespondToComponentByIDRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling respondToComponentByID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/start_response`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Respond to component by ID
     * Respond to component by ID
     */
    respondToComponentByID = async (requestParameters: RespondToComponentByIDRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.respondToComponentByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Respond to component by ID
     * Respond to component by ID
     */
    respondToComponentByIDWithResponse = async (requestParameters: RespondToComponentByIDRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.respondToComponentByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get SelfScheduleAppointmentComponent By Episode Id
     * Get SelfScheduleAppointmentComponent By Episode Id
     */
    selfScheduleAppointmentComponentByEpisodeIDRaw = async (requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest): Promise<{ runtime: runtime.ApiResponse<SelfScheduleAppointmentComponent>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling selfScheduleAppointmentComponentByEpisodeID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/by_episode_id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SelfScheduleAppointmentComponentFromJSON(jsonValue)), response };
    }

    /**
     * Get SelfScheduleAppointmentComponent By Episode Id
     * Get SelfScheduleAppointmentComponent By Episode Id
     */
    selfScheduleAppointmentComponentByEpisodeID = async (requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest): Promise<SelfScheduleAppointmentComponent> => {
        const { runtime } = await this.selfScheduleAppointmentComponentByEpisodeIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get SelfScheduleAppointmentComponent By Episode Id
     * Get SelfScheduleAppointmentComponent By Episode Id
     */
    selfScheduleAppointmentComponentByEpisodeIDWithResponse = async (requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest): Promise<{ value: SelfScheduleAppointmentComponent, response: any}> => {
        const { runtime, response } = await this.selfScheduleAppointmentComponentByEpisodeIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Submit component by ID
     * Submit component by ID
     */
    submitComponentRaw = async (requestParameters: SubmitComponentRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitComponent.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComponentSubmitRequestBodyToJSON(requestParameters.componentSubmitRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Submit component by ID
     * Submit component by ID
     */
    submitComponent = async (requestParameters: SubmitComponentRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.submitComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Submit component by ID
     * Submit component by ID
     */
    submitComponentWithResponse = async (requestParameters: SubmitComponentRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.submitComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Edit the dollar amount of a charge billing card
     */
    updateChargeRaw = async (requestParameters: UpdateChargeRequest): Promise<{ runtime: runtime.ApiResponse<ComponentsEditChargeResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCharge.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/edit_charge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ChargeToJSON(requestParameters.charge),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentsEditChargeResponseFromJSON(jsonValue)), response };
    }

    /**
     * Edit the dollar amount of a charge billing card
     */
    updateCharge = async (requestParameters: UpdateChargeRequest): Promise<ComponentsEditChargeResponse> => {
        const { runtime } = await this.updateChargeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Edit the dollar amount of a charge billing card
     */
    updateChargeWithResponse = async (requestParameters: UpdateChargeRequest): Promise<{ value: ComponentsEditChargeResponse, response: any}> => {
        const { runtime, response } = await this.updateChargeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update a component by ID
     * Update a component by ID
     */
    updateComponentRaw = async (requestParameters: UpdateComponentRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateComponent.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComponentRequestBodyToJSON(requestParameters.updateComponentRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update a component by ID
     * Update a component by ID
     */
    updateComponent = async (requestParameters: UpdateComponentRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.updateComponentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update a component by ID
     * Update a component by ID
     */
    updateComponentWithResponse = async (requestParameters: UpdateComponentRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.updateComponentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetBookableSlotsForComponentSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}

export const fetchComponentsApi: IComponentsApi  = new ComponentsApi();
