import React, { FunctionComponent } from "react";
import useAccess, { AccessToType } from "hooks/useAccess";
import { selectLoggedInUser } from "shared/features/user/selectors";
import Skeleton from "components/Skeleton";
import { useLocation, useNavigate } from "react-router";
import useGetAdultDependents from "shared/features/dependents/useGetAdultDependents";
import useGetPatientAccess from "shared/features/patientAccess/useGetPatientAccess";
import useDeletePatientAccessToken from "shared/features/patientAccess/useDeletePatientAccessToken";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import useQueryParams from "hooks/useQueryParams";
import { GetDependentsShowEnum } from "shared/fetch/src/apis/MembersApi";
import { Dependent } from "shared/fetch/src/models/Dependent";

export interface IAccessProps {
  to: AccessToType;
  children: any;
  memberOnly?: boolean;
  renderAccessDenied?: () => JSX.Element;
}

export const Access: FunctionComponent<IAccessProps & any> = (
  props
): JSX.Element => {
  const { to, children, renderAccessDenied, memberOnly, ctmOnly, ...rest } =
    props;
  const user = selectLoggedInUser();

  const hasAccess = useAccess({ to, memberOnly, ctmOnly });

  const { adultDependents: dependents } = useGetAdultDependents({
    id: user?.id?.toString() || "",
    show: GetDependentsShowEnum.Nested,
  });
  const { queryParams } = useQueryParams();
  const { mutateAsync: getPatientAccess } = useGetPatientAccess();
  const { deletePatientAccessToken } = useDeletePatientAccessToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hasValidDependentAccessInParams =
    queryParams?.access_id &&
    dependents?.results?.some(
      (dependent: Dependent) =>
        dependent?.userId?.toString() === queryParams?.access_id
    ) &&
    !isDependantAccess() &&
    queryParams?.access_id !== user?.id?.toString();

  const getDependentAccessAndNavigate = () =>
    getPatientAccess({
      memberId: (queryParams?.access_id as string) || "",
      note: "member access from query params",
    }).then((data) => {
      if (data.member) {
        navigate(pathname);
      }
    });

  const accessAsDependentDefinedInParams = () => {
    if (hasValidDependentAccessInParams) {
      deletePatientAccessToken();
      getDependentAccessAndNavigate();
    }
  };

  React.useEffect(() => {
    accessAsDependentDefinedInParams();
  }, [queryParams?.access_id, dependents?.results?.length]);

  const childrenWithProps: any = React.Children.map(children, (child) =>
    React.cloneElement(child, rest)
  );

  if (hasAccess) {
    return childrenWithProps;
  }

  if (renderAccessDenied && user?.permissions) {
    return renderAccessDenied();
  }

  if (renderAccessDenied && !user?.permissions) {
    return <Skeleton />;
  }
  return <></>;
};

export default Access;
