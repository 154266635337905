import { useQuery } from "react-query";
import { fetchDefaultApi } from "shared/fetch/src/apis";

const useGetChallengeStringBiometrics = () => {
  const { data, isLoading } = useQuery(
    "getChallenge",
    () => fetchDefaultApi.getChallengeStringBiometrics(),
    {
      staleTime: 0,
    }
  );

  return {
    challenge: data?.challenge,
    isPending: isLoading,
  };
};

export default useGetChallengeStringBiometrics;
