/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountActivationResponse1,
    AccountActivationResponse1FromJSON,
    AccountActivationResponse1ToJSON,
} from '../models/AccountActivationResponse1'
import {
    MinorTransitionRequestBody,
    MinorTransitionRequestBodyFromJSON,
    MinorTransitionRequestBodyToJSON,
} from '../models/MinorTransitionRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface XOPlatformMinorTransitionRequest {
    minorTransitionRequestBody?: MinorTransitionRequestBody;
}


export interface ITransitionApi {
      xOPlatformMinorTransition: (requestParameters: XOPlatformMinorTransitionRequest) => Promise<AccountActivationResponse1>
      xOPlatformMinorTransitionWithResponse: (requestParameters: XOPlatformMinorTransitionRequest) => Promise<{ value: AccountActivationResponse1, response: any}>
}

/**
 * no description
 */
export class TransitionApi extends runtime.BaseAPI {

    /**
     * XO Platform transition a minor to standalone
     */
    xOPlatformMinorTransitionRaw = async (requestParameters: XOPlatformMinorTransitionRequest): Promise<{ runtime: runtime.ApiResponse<AccountActivationResponse1>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/member/transition/standalone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MinorTransitionRequestBodyToJSON(requestParameters.minorTransitionRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccountActivationResponse1FromJSON(jsonValue)), response };
    }

    /**
     * XO Platform transition a minor to standalone
     */
    xOPlatformMinorTransition = async (requestParameters: XOPlatformMinorTransitionRequest): Promise<AccountActivationResponse1> => {
        const { runtime } = await this.xOPlatformMinorTransitionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * XO Platform transition a minor to standalone
     */
    xOPlatformMinorTransitionWithResponse = async (requestParameters: XOPlatformMinorTransitionRequest): Promise<{ value: AccountActivationResponse1, response: any}> => {
        const { runtime, response } = await this.xOPlatformMinorTransitionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchTransitionApi: ITransitionApi  = new TransitionApi();
