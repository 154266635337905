/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GreenPlusPatientRequestBody,
    GreenPlusPatientRequestBodyFromJSON,
    GreenPlusPatientRequestBodyToJSON,
} from '../models/GreenPlusPatientRequestBody'
import {
    GreenPlusResponseBody,
    GreenPlusResponseBodyFromJSON,
    GreenPlusResponseBodyToJSON,
} from '../models/GreenPlusResponseBody'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from '../models/JsonUser'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PatientAdminRequestBody,
    PatientAdminRequestBodyFromJSON,
    PatientAdminRequestBodyToJSON,
} from '../models/PatientAdminRequestBody'
import {
    PatientRequestBody,
    PatientRequestBodyFromJSON,
    PatientRequestBodyToJSON,
} from '../models/PatientRequestBody'


export interface AddNewPatientRequest {
    patientRequestBody?: PatientRequestBody;
}
export interface V1PatientAdminPostRequest {
    patientAdminRequestBody?: PatientAdminRequestBody;
}
export interface V1PatientPostRequest {
    greenPlusPatientRequestBody?: GreenPlusPatientRequestBody;
}


export interface IPatientAccountApi {
      addNewPatient: (requestParameters: AddNewPatientRequest) => Promise<JsonUser>
      v1PatientAdminPost: (requestParameters: V1PatientAdminPostRequest) => Promise<void>
      v1PatientPost: (requestParameters: V1PatientPostRequest) => Promise<GreenPlusResponseBody>
      addNewPatientWithResponse: (requestParameters: AddNewPatientRequest) => Promise<{ value: JsonUser, response: any}>
      v1PatientAdminPostWithResponse: (requestParameters: V1PatientAdminPostRequest) => Promise<{ value: void, response: any}>
      v1PatientPostWithResponse: (requestParameters: V1PatientPostRequest) => Promise<{ value: GreenPlusResponseBody, response: any}>
}

/**
 * no description
 */
export class PatientAccountApi extends runtime.BaseAPI {

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatientRaw = async (requestParameters: AddNewPatientRequest): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/force`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientRequestBodyToJSON(requestParameters.patientRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatient = async (requestParameters: AddNewPatientRequest): Promise<JsonUser> => {
        const { runtime } = await this.addNewPatientRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatientWithResponse = async (requestParameters: AddNewPatientRequest): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.addNewPatientRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPostRaw = async (requestParameters: V1PatientAdminPostRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientAdminRequestBodyToJSON(requestParameters.patientAdminRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPost = async (requestParameters: V1PatientAdminPostRequest): Promise<void> => {
        const { runtime } = await this.v1PatientAdminPostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPostWithResponse = async (requestParameters: V1PatientAdminPostRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.v1PatientAdminPostRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPostRaw = async (requestParameters: V1PatientPostRequest): Promise<{ runtime: runtime.ApiResponse<GreenPlusResponseBody>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GreenPlusPatientRequestBodyToJSON(requestParameters.greenPlusPatientRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => GreenPlusResponseBodyFromJSON(jsonValue)), response };
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPost = async (requestParameters: V1PatientPostRequest): Promise<GreenPlusResponseBody> => {
        const { runtime } = await this.v1PatientPostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPostWithResponse = async (requestParameters: V1PatientPostRequest): Promise<{ value: GreenPlusResponseBody, response: any}> => {
        const { runtime, response } = await this.v1PatientPostRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPatientAccountApi: IPatientAccountApi  = new PatientAccountApi();
