/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberAccountInfoOutput,
    MemberAccountInfoOutputFromJSON,
    MemberAccountInfoOutputToJSON,
} from '../models/MemberAccountInfoOutput'
import {
    MemberAccountInfoRequestBody,
    MemberAccountInfoRequestBodyFromJSON,
    MemberAccountInfoRequestBodyToJSON,
} from '../models/MemberAccountInfoRequestBody'
import {
    MemberCareTeamOutput,
    MemberCareTeamOutputFromJSON,
    MemberCareTeamOutputToJSON,
} from '../models/MemberCareTeamOutput'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface MemberAccountInfoRequest {
    id: string;
}
export interface MemberCareTeamRequest {
    id: string;
}
export interface UpdateMemberAccountInfoRequest {
    id: string;
    memberAccountInfoRequestBody?: MemberAccountInfoRequestBody;
}


export interface IMemberAccountInfoApi {
      memberAccountInfo: (requestParameters: MemberAccountInfoRequest) => Promise<MemberAccountInfoOutput>
      memberCareTeam: (requestParameters: MemberCareTeamRequest) => Promise<MemberCareTeamOutput>
      updateMemberAccountInfo: (requestParameters: UpdateMemberAccountInfoRequest) => Promise<MemberAccountInfoOutput>
      memberAccountInfoWithResponse: (requestParameters: MemberAccountInfoRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
      memberCareTeamWithResponse: (requestParameters: MemberCareTeamRequest) => Promise<{ value: MemberCareTeamOutput, response: any}>
      updateMemberAccountInfoWithResponse: (requestParameters: UpdateMemberAccountInfoRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
}

/**
 * no description
 */
export class MemberAccountInfoApi extends runtime.BaseAPI {

    /**
     * Fetch member account information
     */
    memberAccountInfoRaw = async (requestParameters: MemberAccountInfoRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberAccountInfo.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member account information
     */
    memberAccountInfo = async (requestParameters: MemberAccountInfoRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.memberAccountInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member account information
     */
    memberAccountInfoWithResponse = async (requestParameters: MemberAccountInfoRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.memberAccountInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member care team information
     */
    memberCareTeamRaw = async (requestParameters: MemberCareTeamRequest): Promise<{ runtime: runtime.ApiResponse<MemberCareTeamOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberCareTeam.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberCareTeamOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member care team information
     */
    memberCareTeam = async (requestParameters: MemberCareTeamRequest): Promise<MemberCareTeamOutput> => {
        const { runtime } = await this.memberCareTeamRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member care team information
     */
    memberCareTeamWithResponse = async (requestParameters: MemberCareTeamRequest): Promise<{ value: MemberCareTeamOutput, response: any}> => {
        const { runtime, response } = await this.memberCareTeamRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfoRaw = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMemberAccountInfo.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MemberAccountInfoRequestBodyToJSON(requestParameters.memberAccountInfoRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfo = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.updateMemberAccountInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfoWithResponse = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.updateMemberAccountInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberAccountInfoApi: IMemberAccountInfoApi  = new MemberAccountInfoApi();
