/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from '../models/CmsServiceLineItem'
import {
    CmsServiceLineRequestBody,
    CmsServiceLineRequestBodyFromJSON,
    CmsServiceLineRequestBodyToJSON,
} from '../models/CmsServiceLineRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceLineEditRequestBody,
    ServiceLineEditRequestBodyFromJSON,
    ServiceLineEditRequestBodyToJSON,
} from '../models/ServiceLineEditRequestBody'
import {
    ServiceLinesSchema,
    ServiceLinesSchemaFromJSON,
    ServiceLinesSchemaToJSON,
} from '../models/ServiceLinesSchema'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface AddServiceLineRequest {
    cmsServiceLineRequestBody?: CmsServiceLineRequestBody;
}
export interface DeleteServiceLineRequest {
    serviceLine: string;
}
export interface GetServiceLineRequest {
    serviceLine: string;
}
export interface UpdateServiceLineRequest {
    serviceLine: string;
    serviceLineEditRequestBody?: ServiceLineEditRequestBody;
}


export interface ICmsServiceLinesApi {
      addServiceLine: (requestParameters: AddServiceLineRequest) => Promise<CmsServiceLineItem>
      deleteServiceLine: (requestParameters: DeleteServiceLineRequest) => Promise<void>
      getServiceLine: (requestParameters: GetServiceLineRequest) => Promise<CmsServiceLineItem>
      getServiceLines: () => Promise<ServiceLinesSchema>
      updateServiceLine: (requestParameters: UpdateServiceLineRequest) => Promise<CmsServiceLineItem>
      addServiceLineWithResponse: (requestParameters: AddServiceLineRequest) => Promise<{ value: CmsServiceLineItem, response: any}>
      deleteServiceLineWithResponse: (requestParameters: DeleteServiceLineRequest) => Promise<{ value: void, response: any}>
      getServiceLineWithResponse: (requestParameters: GetServiceLineRequest) => Promise<{ value: CmsServiceLineItem, response: any}>
      getServiceLinesWithResponse: () => Promise<{ value: ServiceLinesSchema, response: any}>
      updateServiceLineWithResponse: (requestParameters: UpdateServiceLineRequest) => Promise<{ value: CmsServiceLineItem, response: any}>
}

/**
 * no description
 */
export class CmsServiceLinesApi extends runtime.BaseAPI {

    /**
     * creates a service line
     * creates a service line
     */
    addServiceLineRaw = async (requestParameters: AddServiceLineRequest): Promise<{ runtime: runtime.ApiResponse<CmsServiceLineItem>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CmsServiceLineRequestBodyToJSON(requestParameters.cmsServiceLineRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CmsServiceLineItemFromJSON(jsonValue)), response };
    }

    /**
     * creates a service line
     * creates a service line
     */
    addServiceLine = async (requestParameters: AddServiceLineRequest): Promise<CmsServiceLineItem> => {
        const { runtime } = await this.addServiceLineRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * creates a service line
     * creates a service line
     */
    addServiceLineWithResponse = async (requestParameters: AddServiceLineRequest): Promise<{ value: CmsServiceLineItem, response: any}> => {
        const { runtime, response } = await this.addServiceLineRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * deactivate a service line
     */
    deleteServiceLineRaw = async (requestParameters: DeleteServiceLineRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
            throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling deleteServiceLine.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * deactivate a service line
     */
    deleteServiceLine = async (requestParameters: DeleteServiceLineRequest): Promise<void> => {
        const { runtime } = await this.deleteServiceLineRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * deactivate a service line
     */
    deleteServiceLineWithResponse = async (requestParameters: DeleteServiceLineRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteServiceLineRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLineRaw = async (requestParameters: GetServiceLineRequest): Promise<{ runtime: runtime.ApiResponse<CmsServiceLineItem>, response: any}> => {
        if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
            throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling getServiceLine.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CmsServiceLineItemFromJSON(jsonValue)), response };
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLine = async (requestParameters: GetServiceLineRequest): Promise<CmsServiceLineItem> => {
        const { runtime } = await this.getServiceLineRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLineWithResponse = async (requestParameters: GetServiceLineRequest): Promise<{ value: CmsServiceLineItem, response: any}> => {
        const { runtime, response } = await this.getServiceLineRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLinesRaw = async (): Promise<{ runtime: runtime.ApiResponse<ServiceLinesSchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLinesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLines = async (): Promise<ServiceLinesSchema> => {
        const { runtime } = await this.getServiceLinesRaw();
        return await runtime.value();
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLinesWithResponse = async (): Promise<{ value: ServiceLinesSchema, response: any}> => {
        const { runtime, response } = await this.getServiceLinesRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * update service line
     * update service line
     */
    updateServiceLineRaw = async (requestParameters: UpdateServiceLineRequest): Promise<{ runtime: runtime.ApiResponse<CmsServiceLineItem>, response: any}> => {
        if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
            throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling updateServiceLine.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceLineEditRequestBodyToJSON(requestParameters.serviceLineEditRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CmsServiceLineItemFromJSON(jsonValue)), response };
    }

    /**
     * update service line
     * update service line
     */
    updateServiceLine = async (requestParameters: UpdateServiceLineRequest): Promise<CmsServiceLineItem> => {
        const { runtime } = await this.updateServiceLineRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * update service line
     * update service line
     */
    updateServiceLineWithResponse = async (requestParameters: UpdateServiceLineRequest): Promise<{ value: CmsServiceLineItem, response: any}> => {
        const { runtime, response } = await this.updateServiceLineRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsServiceLinesApi: ICmsServiceLinesApi  = new CmsServiceLinesApi();
