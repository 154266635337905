import { useQuery } from "react-query";
import { GetDependentsRequest, GetDependentsShowEnum, GetDependentsStatusEnum, fetchMembersApi } from "shared/fetch/src/apis/MembersApi";

export const dependentsQueryKey = (
  id?: string | number,
  show?: GetDependentsShowEnum,
  status?: GetDependentsStatusEnum
) => ["dependents", id?.toString(), show, status];

const useGetAdultDependents = (request: GetDependentsRequest) => {
  const { data, isLoading } = useQuery(
    dependentsQueryKey(request.id, request.show, request.status),
    () => fetchMembersApi.getDependents(request),
    {
      enabled: !!request.id,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );
  return { adultDependents: data, isLoading };
};

export default useGetAdultDependents;
