/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PatientScreening,
    PatientScreeningFromJSON,
    PatientScreeningToJSON,
} from '../models/PatientScreening'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest {
    id: string;
    outboundScreeningId: string;
    patientScreeningId: string;
}


export interface IPatientScreeningApi {
      v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet: (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest) => Promise<PatientScreening>
      v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetWithResponse: (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest) => Promise<{ value: PatientScreening, response: any}>
}

/**
 * no description
 */
export class PatientScreeningApi extends runtime.BaseAPI {

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<{ runtime: runtime.ApiResponse<PatientScreening>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                        if (requestParameters.patientScreeningId === null || requestParameters.patientScreeningId === undefined) {
            throw new runtime.RequiredError('patientScreeningId','Required parameter requestParameters.patientScreeningId was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/screenings/{patient_screening_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))).replace(`{${"patient_screening_id"}}`, encodeURIComponent(String(requestParameters.patientScreeningId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientScreeningFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<PatientScreening> => {
        const { runtime } = await this.v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetWithResponse = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<{ value: PatientScreening, response: any}> => {
        const { runtime, response } = await this.v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPatientScreeningApi: IPatientScreeningApi  = new PatientScreeningApi();
