/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdHocQuestionDetails,
    AdHocQuestionDetailsFromJSON,
    AdHocQuestionDetailsToJSON,
} from '../models/AdHocQuestionDetails'
import {
    AdHocQuestionRequestBody,
    AdHocQuestionRequestBodyFromJSON,
    AdHocQuestionRequestBodyToJSON,
} from '../models/AdHocQuestionRequestBody'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface CreateAdHocQuestionsRequest {
    adHocQuestionRequestBody?: AdHocQuestionRequestBody;
}


export interface IAdHocQuestionsApi {
      createAdHocQuestions: (requestParameters: CreateAdHocQuestionsRequest) => Promise<AdHocQuestionDetails>
      createAdHocQuestionsWithResponse: (requestParameters: CreateAdHocQuestionsRequest) => Promise<{ value: AdHocQuestionDetails, response: any}>
}

/**
 * no description
 */
export class AdHocQuestionsApi extends runtime.BaseAPI {

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestionsRaw = async (requestParameters: CreateAdHocQuestionsRequest): Promise<{ runtime: runtime.ApiResponse<AdHocQuestionDetails>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdHocQuestionRequestBodyToJSON(requestParameters.adHocQuestionRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AdHocQuestionDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestions = async (requestParameters: CreateAdHocQuestionsRequest): Promise<AdHocQuestionDetails> => {
        const { runtime } = await this.createAdHocQuestionsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestionsWithResponse = async (requestParameters: CreateAdHocQuestionsRequest): Promise<{ value: AdHocQuestionDetails, response: any}> => {
        const { runtime, response } = await this.createAdHocQuestionsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchAdHocQuestionsApi: IAdHocQuestionsApi  = new AdHocQuestionsApi();
