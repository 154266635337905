/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'


export interface V1PatientIdUpdateCoveragePostRequest {
    id: string;
}


export interface IPatientCoverageApi {
      v1PatientIdUpdateCoveragePost: (requestParameters: V1PatientIdUpdateCoveragePostRequest) => Promise<void>
      v1PatientIdUpdateCoveragePostWithResponse: (requestParameters: V1PatientIdUpdateCoveragePostRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class PatientCoverageApi extends runtime.BaseAPI {

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePostRaw = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1PatientIdUpdateCoveragePost.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/{id}/update-coverage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePost = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<void> => {
        const { runtime } = await this.v1PatientIdUpdateCoveragePostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePostWithResponse = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.v1PatientIdUpdateCoveragePostRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchPatientCoverageApi: IPatientCoverageApi  = new PatientCoverageApi();
