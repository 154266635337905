/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EpisodeQueueSummariesResponse,
    EpisodeQueueSummariesResponseFromJSON,
    EpisodeQueueSummariesResponseToJSON,
} from '../models/EpisodeQueueSummariesResponse'
import {
    EpisodeSummariesResponse,
    EpisodeSummariesResponseFromJSON,
    EpisodeSummariesResponseToJSON,
} from '../models/EpisodeSummariesResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchActiveEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
    tab?: FetchActiveEpisodeListSummariesTabEnum;
    page?: string;
    pageSize?: string;
}
export interface FetchArchivedEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
}
export interface FetchCareTeamQueueEpisodeSummariesRequest {
    careTeamId: string;
    roleIds: string;
    ifModifiedSince?: object;
}
export interface FetchPodsQueueEpisodeSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
}
export interface GetMyActiveEpisodesRequest {
    ownedOrClaimedByCtms?: string;
    yours?: string;
    page?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    tab?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: string;
}
export interface GetMyArchivedEpisodesRequest {
    page?: string;
    order?: string;
}
export interface GetMyQueueRequest {
    ownedOrClaimedByCtms?: string;
    yours?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: GetMyQueueOrderEnum;
    page?: string;
}
export interface ListMyPreventiveEpisodesRequest {
    tab?: ListMyPreventiveEpisodesTabEnum;
    page?: string;
    ownedOrClaimedByCtms?: string;
    yours?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: string;
}
export interface ListPreventiveEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
    tab?: ListPreventiveEpisodeListSummariesTabEnum;
    page?: string;
    pageSize?: string;
}
export interface MemberArchivedEpisodeListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
}
export interface MemberEpisodeListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
}


export interface IEpisodeListsApi {
      fetchActiveEpisodeListSummaries: (requestParameters: FetchActiveEpisodeListSummariesRequest) => Promise<EpisodeSummariesResponse>
      fetchArchivedEpisodeListSummaries: (requestParameters: FetchArchivedEpisodeListSummariesRequest) => Promise<EpisodeSummariesResponse>
      fetchCareTeamQueueEpisodeSummaries: (requestParameters: FetchCareTeamQueueEpisodeSummariesRequest) => Promise<EpisodeSummariesResponse>
      fetchPodsQueueEpisodeSummaries: (requestParameters: FetchPodsQueueEpisodeSummariesRequest) => Promise<EpisodeQueueSummariesResponse>
      getMyActiveEpisodes: (requestParameters: GetMyActiveEpisodesRequest) => Promise<EpisodeSummariesResponse>
      getMyArchivedEpisodes: (requestParameters: GetMyArchivedEpisodesRequest) => Promise<EpisodeSummariesResponse>
      getMyQueue: (requestParameters: GetMyQueueRequest) => Promise<EpisodeQueueSummariesResponse>
      listMyPreventiveEpisodes: (requestParameters: ListMyPreventiveEpisodesRequest) => Promise<EpisodeSummariesResponse>
      listPreventiveEpisodeListSummaries: (requestParameters: ListPreventiveEpisodeListSummariesRequest) => Promise<EpisodeSummariesResponse>
      memberArchivedEpisodeListSummaries: (requestParameters: MemberArchivedEpisodeListSummariesRequest) => Promise<EpisodeSummariesResponse>
      memberEpisodeListSummaries: (requestParameters: MemberEpisodeListSummariesRequest) => Promise<EpisodeSummariesResponse>
      fetchActiveEpisodeListSummariesWithResponse: (requestParameters: FetchActiveEpisodeListSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      fetchArchivedEpisodeListSummariesWithResponse: (requestParameters: FetchArchivedEpisodeListSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      fetchCareTeamQueueEpisodeSummariesWithResponse: (requestParameters: FetchCareTeamQueueEpisodeSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      fetchPodsQueueEpisodeSummariesWithResponse: (requestParameters: FetchPodsQueueEpisodeSummariesRequest) => Promise<{ value: EpisodeQueueSummariesResponse, response: any}>
      getMyActiveEpisodesWithResponse: (requestParameters: GetMyActiveEpisodesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      getMyArchivedEpisodesWithResponse: (requestParameters: GetMyArchivedEpisodesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      getMyQueueWithResponse: (requestParameters: GetMyQueueRequest) => Promise<{ value: EpisodeQueueSummariesResponse, response: any}>
      listMyPreventiveEpisodesWithResponse: (requestParameters: ListMyPreventiveEpisodesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      listPreventiveEpisodeListSummariesWithResponse: (requestParameters: ListPreventiveEpisodeListSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      memberArchivedEpisodeListSummariesWithResponse: (requestParameters: MemberArchivedEpisodeListSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
      memberEpisodeListSummariesWithResponse: (requestParameters: MemberEpisodeListSummariesRequest) => Promise<{ value: EpisodeSummariesResponse, response: any}>
}

/**
 * no description
 */
export class EpisodeListsApi extends runtime.BaseAPI {

    /**
     * Fetch summaries for the Active Episode list
     * Care Team active episode summaries
     */
    fetchActiveEpisodeListSummariesRaw = async (requestParameters: FetchActiveEpisodeListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
            throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchActiveEpisodeListSummaries.');
        }

                        if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
            throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchActiveEpisodeListSummaries.');
        }

                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tab !== undefined) {
            queryParameters['tab'] = requestParameters.tab;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/pods/{pod_ids}/roles/{role_ids}/active_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the Active Episode list
     * Care Team active episode summaries
     */
    fetchActiveEpisodeListSummaries = async (requestParameters: FetchActiveEpisodeListSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.fetchActiveEpisodeListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the Active Episode list
     * Care Team active episode summaries
     */
    fetchActiveEpisodeListSummariesWithResponse = async (requestParameters: FetchActiveEpisodeListSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.fetchActiveEpisodeListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch summaries for the Archived Episode list
     * Care Team archived episode summaries
     */
    fetchArchivedEpisodeListSummariesRaw = async (requestParameters: FetchArchivedEpisodeListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
            throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchArchivedEpisodeListSummaries.');
        }

                        if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
            throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchArchivedEpisodeListSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/pods/{pod_ids}/roles/{role_ids}/archived_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the Archived Episode list
     * Care Team archived episode summaries
     */
    fetchArchivedEpisodeListSummaries = async (requestParameters: FetchArchivedEpisodeListSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.fetchArchivedEpisodeListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the Archived Episode list
     * Care Team archived episode summaries
     */
    fetchArchivedEpisodeListSummariesWithResponse = async (requestParameters: FetchArchivedEpisodeListSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.fetchArchivedEpisodeListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Care Team Queue episode summaries
     */
    fetchCareTeamQueueEpisodeSummariesRaw = async (requestParameters: FetchCareTeamQueueEpisodeSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.careTeamId === null || requestParameters.careTeamId === undefined) {
            throw new runtime.RequiredError('careTeamId','Required parameter requestParameters.careTeamId was null or undefined when calling fetchCareTeamQueueEpisodeSummaries.');
        }

                        if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
            throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchCareTeamQueueEpisodeSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_teams/{care_team_id}/roles/{role_ids}/queue`.replace(`{${"care_team_id"}}`, encodeURIComponent(String(requestParameters.careTeamId))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Care Team Queue episode summaries
     */
    fetchCareTeamQueueEpisodeSummaries = async (requestParameters: FetchCareTeamQueueEpisodeSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.fetchCareTeamQueueEpisodeSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Care Team Queue episode summaries
     */
    fetchCareTeamQueueEpisodeSummariesWithResponse = async (requestParameters: FetchCareTeamQueueEpisodeSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.fetchCareTeamQueueEpisodeSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Pods Queue episode summaries
     */
    fetchPodsQueueEpisodeSummariesRaw = async (requestParameters: FetchPodsQueueEpisodeSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeQueueSummariesResponse>, response: any}> => {
        if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
            throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchPodsQueueEpisodeSummaries.');
        }

                        if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
            throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchPodsQueueEpisodeSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/pods/{pod_ids}/roles/{role_ids}/queue`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeQueueSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Pods Queue episode summaries
     */
    fetchPodsQueueEpisodeSummaries = async (requestParameters: FetchPodsQueueEpisodeSummariesRequest): Promise<EpisodeQueueSummariesResponse> => {
        const { runtime } = await this.fetchPodsQueueEpisodeSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch episode summaries for the Care Team Queue
     * Pods Queue episode summaries
     */
    fetchPodsQueueEpisodeSummariesWithResponse = async (requestParameters: FetchPodsQueueEpisodeSummariesRequest): Promise<{ value: EpisodeQueueSummariesResponse, response: any}> => {
        const { runtime, response } = await this.fetchPodsQueueEpisodeSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyActiveEpisodesRaw = async (requestParameters: GetMyActiveEpisodesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
                                                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ownedOrClaimedByCtms !== undefined) {
            queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
        }

        if (requestParameters.yours !== undefined) {
            queryParameters['yours'] = requestParameters.yours;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.yourActionItems !== undefined) {
            queryParameters['your_action_items'] = requestParameters.yourActionItems;
        }

        if (requestParameters.yourEpisodes !== undefined) {
            queryParameters['your_episodes'] = requestParameters.yourEpisodes;
        }

        if (requestParameters.notYourEpisodes !== undefined) {
            queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
        }

        if (requestParameters.yourRole !== undefined) {
            queryParameters['your_role'] = requestParameters.yourRole;
        }

        if (requestParameters.tab !== undefined) {
            queryParameters['tab'] = requestParameters.tab;
        }

        if (requestParameters.havePodIds !== undefined) {
            queryParameters['have_pod_ids'] = requestParameters.havePodIds;
        }

        if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
            queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/my_active_episodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyActiveEpisodes = async (requestParameters: GetMyActiveEpisodesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.getMyActiveEpisodesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyActiveEpisodesWithResponse = async (requestParameters: GetMyActiveEpisodesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.getMyActiveEpisodesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyArchivedEpisodesRaw = async (requestParameters: GetMyArchivedEpisodesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/my_archived_episodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyArchivedEpisodes = async (requestParameters: GetMyArchivedEpisodesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.getMyArchivedEpisodesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get active episodes, including `Content-Location` header for source of list.
     * List of active episodes
     */
    getMyArchivedEpisodesWithResponse = async (requestParameters: GetMyArchivedEpisodesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.getMyArchivedEpisodesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get episodes from my queue, including `Content-Location` header for source of list.
     * Get episodes from my queue
     */
    getMyQueueRaw = async (requestParameters: GetMyQueueRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeQueueSummariesResponse>, response: any}> => {
                                                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ownedOrClaimedByCtms !== undefined) {
            queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
        }

        if (requestParameters.yours !== undefined) {
            queryParameters['yours'] = requestParameters.yours;
        }

        if (requestParameters.yourActionItems !== undefined) {
            queryParameters['your_action_items'] = requestParameters.yourActionItems;
        }

        if (requestParameters.yourEpisodes !== undefined) {
            queryParameters['your_episodes'] = requestParameters.yourEpisodes;
        }

        if (requestParameters.notYourEpisodes !== undefined) {
            queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
        }

        if (requestParameters.yourRole !== undefined) {
            queryParameters['your_role'] = requestParameters.yourRole;
        }

        if (requestParameters.havePodIds !== undefined) {
            queryParameters['have_pod_ids'] = requestParameters.havePodIds;
        }

        if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
            queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/my_queue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeQueueSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get episodes from my queue, including `Content-Location` header for source of list.
     * Get episodes from my queue
     */
    getMyQueue = async (requestParameters: GetMyQueueRequest): Promise<EpisodeQueueSummariesResponse> => {
        const { runtime } = await this.getMyQueueRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get episodes from my queue, including `Content-Location` header for source of list.
     * Get episodes from my queue
     */
    getMyQueueWithResponse = async (requestParameters: GetMyQueueRequest): Promise<{ value: EpisodeQueueSummariesResponse, response: any}> => {
        const { runtime, response } = await this.getMyQueueRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get preventive episodes, including `Content-Location` header for source of list.
     * List of preventive episodes
     */
    listMyPreventiveEpisodesRaw = async (requestParameters: ListMyPreventiveEpisodesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
                                                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tab !== undefined) {
            queryParameters['tab'] = requestParameters.tab;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.ownedOrClaimedByCtms !== undefined) {
            queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
        }

        if (requestParameters.yours !== undefined) {
            queryParameters['yours'] = requestParameters.yours;
        }

        if (requestParameters.yourActionItems !== undefined) {
            queryParameters['your_action_items'] = requestParameters.yourActionItems;
        }

        if (requestParameters.yourEpisodes !== undefined) {
            queryParameters['your_episodes'] = requestParameters.yourEpisodes;
        }

        if (requestParameters.notYourEpisodes !== undefined) {
            queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
        }

        if (requestParameters.yourRole !== undefined) {
            queryParameters['your_role'] = requestParameters.yourRole;
        }

        if (requestParameters.havePodIds !== undefined) {
            queryParameters['have_pod_ids'] = requestParameters.havePodIds;
        }

        if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
            queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/my_preventive_episodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get preventive episodes, including `Content-Location` header for source of list.
     * List of preventive episodes
     */
    listMyPreventiveEpisodes = async (requestParameters: ListMyPreventiveEpisodesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.listMyPreventiveEpisodesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get preventive episodes, including `Content-Location` header for source of list.
     * List of preventive episodes
     */
    listMyPreventiveEpisodesWithResponse = async (requestParameters: ListMyPreventiveEpisodesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.listMyPreventiveEpisodesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch summaries for the preventive Episode list
     * Care Team preventive episodes summaries
     */
    listPreventiveEpisodeListSummariesRaw = async (requestParameters: ListPreventiveEpisodeListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
            throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling listPreventiveEpisodeListSummaries.');
        }

                        if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
            throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling listPreventiveEpisodeListSummaries.');
        }

                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tab !== undefined) {
            queryParameters['tab'] = requestParameters.tab;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/pods/{pod_ids}/roles/{role_ids}/preventive_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the preventive Episode list
     * Care Team preventive episodes summaries
     */
    listPreventiveEpisodeListSummaries = async (requestParameters: ListPreventiveEpisodeListSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.listPreventiveEpisodeListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the preventive Episode list
     * Care Team preventive episodes summaries
     */
    listPreventiveEpisodeListSummariesWithResponse = async (requestParameters: ListPreventiveEpisodeListSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.listPreventiveEpisodeListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch summaries for the Member Archived Episode list
     * Member archived episode summaries
     */
    memberArchivedEpisodeListSummariesRaw = async (requestParameters: MemberArchivedEpisodeListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberArchivedEpisodeListSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/archived_episodes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the Member Archived Episode list
     * Member archived episode summaries
     */
    memberArchivedEpisodeListSummaries = async (requestParameters: MemberArchivedEpisodeListSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.memberArchivedEpisodeListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the Member Archived Episode list
     * Member archived episode summaries
     */
    memberArchivedEpisodeListSummariesWithResponse = async (requestParameters: MemberArchivedEpisodeListSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.memberArchivedEpisodeListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch summaries for the Member Episode list
     * Member episode summaries
     */
    memberEpisodeListSummariesRaw = async (requestParameters: MemberEpisodeListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummariesResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberEpisodeListSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
            headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/episodes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummariesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch summaries for the Member Episode list
     * Member episode summaries
     */
    memberEpisodeListSummaries = async (requestParameters: MemberEpisodeListSummariesRequest): Promise<EpisodeSummariesResponse> => {
        const { runtime } = await this.memberEpisodeListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch summaries for the Member Episode list
     * Member episode summaries
     */
    memberEpisodeListSummariesWithResponse = async (requestParameters: MemberEpisodeListSummariesRequest): Promise<{ value: EpisodeSummariesResponse, response: any}> => {
        const { runtime, response } = await this.memberEpisodeListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FetchActiveEpisodeListSummariesTabEnum {
    You = 'you',
    Team = 'team'
}
/**
    * @export
    * @enum {string}
    */
export enum GetMyQueueOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum ListMyPreventiveEpisodesTabEnum {
    You = 'you',
    Team = 'team'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPreventiveEpisodeListSummariesTabEnum {
    You = 'you',
    Team = 'team'
}

export const fetchEpisodeListsApi: IEpisodeListsApi  = new EpisodeListsApi();
