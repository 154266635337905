import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import {
  getTokenSync,
  logout as logoutUser,
  removeToken,
} from "shared/features/auth/utils";
import { setTimelineDestinationUrl } from "shared/state/ui/member";

import Hidden from "components/Hidden";
import { useMenuAnchorElement } from "components/Menu/useMenuAnchorElement";
import Avatar from "components/Avatar";
import ButtonBase from "components/Button/ButtonBase";
import Menu from "components/Menu";
import MenuItem from "components/Menu/MenuItem";
import Typography from "components/Typography";
import Box from "components/Box";
import DraftReplySafeLink from "components/Button/DraftReplySafeLink";
import useMobileCheck from "hooks/useMobileCheck";
import makeStyles from "@mui/styles/makeStyles";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { ArrowDropUp, Settings, ArrowDropDown } from "@mui/icons-material";
import useGetAdultDependents from "shared/features/dependents/useGetAdultDependents";
import useGetPatientAccess from "shared/features/patientAccess/useGetPatientAccess";
import useDeletePatientAccessToken from "shared/features/patientAccess/useDeletePatientAccessToken";
import ListItemAvatar from "components/List/ListItemAvatar";
import { MEMBER_ACCESS_ID } from "shared/features/auth";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import {
  GetDependentsShowEnum,
  GetDependentsStatusEnum,
} from "shared/fetch/src/apis/MembersApi";
import { visuallyHidden } from "@mui/utils";
import { useAuth0 } from "@auth0/auth0-react";

/*
UserMenuButton - nav dropdown
*/
interface Props {
  user: User;
  dispatch?: Dispatch;
}

/*
NOTE: Menu items are responsible for some warnings in the console. Refs are being
forwarded to functional component; also, under the hood a ref is being used instead of
a forwardRef. See https://mui.com/material-ui/guides/composition/ for caveats to refs and
alternate implementations.
*/

const useStyle = makeStyles(({ palette }) => ({
  subHeading: {
    fontSize: "11.1px",
    color: palette.text.primary,
    padding: "0px 16px",
  },
  minorDependents: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0",
  },
  name: {
    paddingLeft: "8px",
  },
  menuItem: {
    justifyContent: "flex-start !important",
  },
  dependentsMenuItem: {
    paddingLeft: "3px",
    paddingBottom: "16px",
    width: "100%",
  },
  avatarBackground: {
    background: "#CBCED1",
  },
  avatarIcon: {
    color: "#4E5754",
  },
  dropDownIconMobile: {
    color: palette.yellow,
    marginLeft: "-20px",
  },
  dropDownIconDesktop: {
    color: palette.secondary.main,
    marginLeft: "-10px",
  },
  dropDownAndBellMobile: {
    width: "50%",
  },
}));

const UserMenuButton = (props: Props) => {
  const { user } = props;
  const navigate = useNavigate();
  const classes = useStyle();
  const featureFlags = selectFeatures();
  const isMinorDependents = featureFlags.hasMinorDependents();
  const id = user?.id?.toString() || "";
  const { mutateAsync: getPatientAccess } = useGetPatientAccess();
  const { deletePatientAccessToken } = useDeletePatientAccessToken();
  const dependentId = getTokenSync(MEMBER_ACCESS_ID);
  const dependentAccessUser = selectLoggedInUser();
  const { logout: logoutAuth0 } = useAuth0();

  // since this hook is returning adult and minor dependents
  // it should be renamed
  const { adultDependents } = useGetAdultDependents({
    id,
    show: GetDependentsShowEnum.Nested,
    status: GetDependentsStatusEnum.Registered,
  });

  const minorsToShow = adultDependents?.results;

  const hasDependentsToShowInDropdown =
    !user?.ctm && isMinorDependents && minorsToShow?.length !== 0;

  const isMobileView = useMobileCheck();

  const allDependentsRefs = React.useRef<Record<number, HTMLLIElement | null>>(
    {}
  );
  const accountRef = React.useRef<HTMLDivElement>(null);

  const { anchorElement, onAnchorElementClick, onMenuClose } =
    useMenuAnchorElement();

  const logout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isDependantAccess()) {
      handleMemberSwitch();
    }
    const { dispatch } = props;
    logoutUser(dispatch, user, "button", logoutAuth0);
  };

  const reduxDispatch = useDispatch();
  const onClickHandler = (toUrl: string) => {
    reduxDispatch(setTimelineDestinationUrl(toUrl));
    onMenuClose();
  };

  React.useEffect(() => {
    if (anchorElement && hasDependentsToShowInDropdown) {
      allDependentsRefs.current[0]?.focus();
    } else if (anchorElement) {
      accountRef.current?.focus();
    }
  }, [anchorElement]);

  const handleClick = (userId: any) => {
    deletePatientAccessToken();
    getPatientAccess({
      memberId: userId,
      note: "member access",
    }).then((data) => {
      if (data.member) {
        navigate("/");
      }
    });
    onMenuClose();
  };

  const handleMemberSwitch = () => {
    deletePatientAccessToken();
    removeToken(MEMBER_ACCESS_ID);
    onMenuClose();
    navigate("/");
  };

  const getFullName = () =>
    `${dependentAccessUser?.firstName} ${dependentAccessUser?.lastName}`;

  return (
    <Box height="100%">
      <ButtonBase
        focusRipple
        aria-controls="user-dropdownmenu"
        aria-haspopup="true"
        onClick={onAnchorElementClick}
        style={{ height: "100%" }}
        data-testid="user-menu-button"
        aria-label="User Menu"
        role="button"
      >
        {dependentAccessUser?.firstName && (
          <Avatar
            size="small"
            src={dependentAccessUser.avatarUrl || ""}
            imgProps={{ role: "presentation" }}
            className="user-menu-avatar"
            // alt should be empty string since role is presentational
            alt={""}
            displayText={dependentAccessUser.fullName || getFullName()}
            label={
              <>
                {/* Because this label is hidden on small screens it is not a
                reliable accessible label. Hide it from a11y tree */}
                <Hidden lgDown>
                  <Typography aria-hidden variant="body2">
                    {dependentAccessUser.fullName || getFullName()}
                  </Typography>
                </Hidden>
                {/* Instead this invisible, more descriptive label will appear in
                the a11y tree regardless of viewport size */}
                <span style={visuallyHidden}>{`${
                  dependentAccessUser.fullName || getFullName()
                }'s User Menu Options`}</span>
              </>
            }
          />
        )}

        {isMinorDependents && !user?.ctm && (
          <>
            {anchorElement ? (
              <ArrowDropUp
                className={
                  isMobileView
                    ? classes.dropDownIconMobile
                    : classes.dropDownIconDesktop
                }
              />
            ) : (
              <ArrowDropDown
                className={
                  isMobileView
                    ? classes.dropDownIconMobile
                    : classes.dropDownIconDesktop
                }
              />
            )}
          </>
        )}
      </ButtonBase>
      <Menu
        id="user-dropdownmenu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={onMenuClose}
        className="user-menu-dropdown show"
        transitionDuration={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid="user-menu-button-dropdown"
      >
        {hasDependentsToShowInDropdown && (
          <Typography
            tabIndex={-1}
            data-testid="switch-account-heading"
            className={classes.subHeading}
          >
            Switch account
          </Typography>
        )}
        {dependentAccessUser !== user && hasDependentsToShowInDropdown && (
          <MenuItem
            style={{
              paddingLeft: "3px",
              paddingBottom: "16px",
              width: "100%",
            }}
            onClick={handleMemberSwitch}
          >
            <ListItemAvatar size="small">
              <Avatar
                size="small"
                src={user.avatarUrl}
                imgProps={{ role: "presentation" }}
                className="user-menu-avatar"
                // alt should be empty string since role is presentational
                alt={""}
                displayText={user.fullName}
                label={
                  <>
                    <Typography aria-hidden variant="body2">
                      {user.fullName}
                    </Typography>
                    {/* Instead this invisible, more descriptive label will appear in the a11y tree regardless of viewport size */}
                    <span
                      style={visuallyHidden}
                    >{`Go to ${user.fullName}'s account`}</span>
                  </>
                }
              />
            </ListItemAvatar>
          </MenuItem>
        )}
        {minorsToShow?.map((minorDependent, index) => {
          if (Number(dependentId) !== minorDependent.userId) {
            return (
              <MenuItem
                ref={(dependentRef) =>
                  (allDependentsRefs.current[index] = dependentRef)
                }
                key={index}
                divider={index === minorsToShow?.length - 1}
                className={classes.dependentsMenuItem}
                onClick={() => handleClick(minorDependent.userId)}
                disabled={
                  minorDependent?.userId?.toString() === dependentId?.toString()
                }
                data-testid={`registered-minor-${index}`}
                id="registered-minor"
                tabIndex={0}
              >
                <ButtonBase style={{ height: "100%" }} focusRipple>
                  <Avatar
                    size="small"
                    data-testid="dependent-avatar"
                    src={minorDependent.avatarUrl || ""}
                    imgProps={{ role: "presentation" }}
                    className="user-menu-avatar"
                    // alt should be empty string since role is presentational
                    alt={""}
                    displayText={`${
                      minorDependent.preferredName
                        ? minorDependent.preferredName
                        : minorDependent.firstName
                    } ${minorDependent.lastName}`}
                    label={
                      <>
                        <Typography
                          data-testid={
                            minorDependent.preferredName
                              ? "dependent-name-preferred"
                              : "dependent-name"
                          }
                          aria-hidden
                          variant="body2"
                        >
                          {`${
                            minorDependent.preferredName
                              ? minorDependent.preferredName
                              : minorDependent.firstName
                          } ${minorDependent.lastName}`}
                        </Typography>
                        {/* This invisible, more descriptive label will appear in
                the a11y tree regardless of viewport size */}
                        <span style={visuallyHidden}>{`${
                          minorDependent.preferredName
                            ? minorDependent.preferredName
                            : minorDependent.firstName
                        } ${
                          minorDependent.lastName
                        }'s User Menu Options`}</span>
                      </>
                    }
                  />
                </ButtonBase>
              </MenuItem>
            );
          } else {
            return;
          }
        })}

        <div
          // need to disable next line so that the list item is interactive, there may be a better way of doing this
          // eslint-disable-next-line
          tabIndex={0}
          ref={accountRef}
          data-testid={"account-and-settings-list-item"}
        >
          <MenuItem
            component={DraftReplySafeLink}
            data-e2e="user-menu-account-link"
            to="/account"
            onClick={(e: any) => {
              e.preventDefault();
              onClickHandler("/account");
            }}
            className={classes.menuItem}
          >
            <Settings color="primary" />
            {`Account & Settings`}
          </MenuItem>
        </div>
        {(!user?.ctm && !isMobileView) || user?.ctm ? (
          <MenuItem
            data-e2e="log-out-button"
            component={Link}
            to="/"
            onClick={(e: React.SyntheticEvent) => {
              onMenuClose();
              navigate("/");
              logout(e);
            }}
            className={classes.menuItem}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
                strokeWidth="30"
                fill="#F4B542"
              />
            </svg>
            Logout
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
};

export default UserMenuButton;
