import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { BoxProps } from "@mui/material";
import User from "models/User";
import Typography from "components/Typography";
import Box from "components/Box";
import { TypographyProps } from "@mui/material/Typography";

interface EligibilityMessagingProps extends BoxProps {
  memberInfo: { status?: string; becomeIneligibleAt?: Date };
  ctm?: boolean;
  ineligibleMessage?: string;
  inactiveMessage?: string;
  pendingIneligibleMessage?: string;
  typographyProps?: TypographyProps;
}

export const EligibilityRendering = ({
  memberInfo,
  ctm,
  renderIneligible,
  renderPendingIneligible,
  renderActive,
  renderFallback,
  renderInactive,
  ...props
}: any) => {
  const user = useSelector(getUser);

  const Fallback = () =>
    renderFallback ? <Box {...props}>{renderFallback()}</Box> : null;

  if (ctm && !user?.ctm) {
    return <Fallback />;
  }

  const isIneligible = User.isUserIneligible(memberInfo);
  const isPendingIneligible = User.isUserPendingIneliglble(memberInfo);
  const isInactive = User.isUserInactive(memberInfo);
  const isActive = !isIneligible && !isPendingIneligible && !isInactive;

  if (
    (isIneligible && !renderIneligible) ||
    (isPendingIneligible && !renderPendingIneligible) ||
    (isActive && !renderActive) ||
    (isInactive && !renderInactive)
  ) {
    return <Fallback />;
  }

  return (
    <Box {...props}>
      {isIneligible && renderIneligible()}
      {isPendingIneligible && renderPendingIneligible()}
      {isInactive && renderInactive()}
      {isActive && renderActive()}
    </Box>
  );
};

const EligibilityMessaging = ({
  memberInfo,
  ctm,
  ineligibleMessage,
  pendingIneligibleMessage,
  inactiveMessage,
  typographyProps = {},
  ...props
}: EligibilityMessagingProps) => {
  const renderMessage = (message: string) =>
    (function Message() {
      return message ? (
        <Box {...props} data-testid="eligibility-messaging">
          <Typography {...typographyProps}>{message}</Typography>
        </Box>
      ) : null;
    });
  const renderIneligible = useMemo(
    // @ts-ignore: Object is possibly 'null'.
    () => renderMessage(ineligibleMessage),
    [ineligibleMessage]
  );

  const renderPendingIneligible = useMemo(
    // @ts-ignore: Object is possibly 'null'.
    () => renderMessage(pendingIneligibleMessage),
    [pendingIneligibleMessage]
  );
  const renderInactive = useMemo(
    // @ts-ignore: Object is possibly 'null'.
    () => renderMessage(inactiveMessage),
    [pendingIneligibleMessage]
  );

  return (
    <EligibilityRendering
      memberInfo={memberInfo}
      ctm={ctm}
      renderIneligible={renderIneligible}
      renderPendingIneligible={renderPendingIneligible}
      renderInactive={renderInactive}
    />
  );
};

export default EligibilityMessaging;
