// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PermissionsEnum {
    CmsToolsRead = 'cms.tools.read',
    CmsToolsUpdate = 'cms.tools.update',
    MfaReset = 'mfa.reset',
    ProviderRolesAdmin = 'provider_roles.admin',
    ProviderRolesAssign = 'provider_roles.assign',
    EpisodesRead = 'episodes.read',
    EpisodesCreate = 'episodes.create',
    EpisodesUpdate = 'episodes.update',
    CtmEpisodesRead = 'ctm.episodes.read',
    MemberHomepageRead = 'member.homepage.read',
    ServiceMenusAdmin = 'service_menus.admin',
    ServiceLinesAdmin = 'service_lines.admin',
    CareHistoryEpisodesRead = 'care-history.episodes.read',
    CareHistoryVisitsRead = 'care-history.visits.read',
    CareTimelineRead = 'care-timeline.read',
    DashboardEpisodesRead = 'dashboard.episodes.read',
    DashboardBeWellRead = 'dashboard.be-well.read',
    HealthRecordsRead = 'health-records.read',
    MeetYourTeamRead = 'meet-your-team.read',
    MinorProxyAccess = 'minor.proxy.access',
    AccountsDependentsTabRead = 'accounts.dependents-tab.read',
    SelfScheduleCreate = 'self_schedule.create',
    NewMemberCreate = 'new_member.create',
    Noaccess = 'noaccess'
}

export function PermissionsEnumFromJSON(json: any): PermissionsEnum {
    return json as PermissionsEnum;
}

export function PermissionsEnumToJSON(value?: PermissionsEnum): any {
    return value as any;
}

