/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AvailableTimeslotsResponse,
    AvailableTimeslotsResponseFromJSON,
    AvailableTimeslotsResponseToJSON,
} from '../models/AvailableTimeslotsResponse'
import {
    CapacityFetchResponse,
    CapacityFetchResponseFromJSON,
    CapacityFetchResponseToJSON,
} from '../models/CapacityFetchResponse'
import {
    CreateSlotCore,
    CreateSlotCoreFromJSON,
    CreateSlotCoreToJSON,
} from '../models/CreateSlotCore'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NextAvailableSlotsFetchResponse,
    NextAvailableSlotsFetchResponseFromJSON,
    NextAvailableSlotsFetchResponseToJSON,
} from '../models/NextAvailableSlotsFetchResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from '../models/SlotOutput'
import {
    SlotsFetchResponse,
    SlotsFetchResponseFromJSON,
    SlotsFetchResponseToJSON,
} from '../models/SlotsFetchResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface DeleteSlotRequest {
    id: string;
}
export interface GetAvailableTimeslotsRequest {
    id: string;
    startDate: string;
    itemCount: string;
    timezone: string;
    usState: string;
    appointmentType: string;
    schedulingType: GetAvailableTimeslotsSchedulingTypeEnum;
    clinicId?: string;
    staffGlobalId?: string;
}
export interface GetCapacityRequest {
    id: string;
    startDate: string;
    daysCount: string;
    itemCount: string;
    timezone: string;
    appointmentType: string;
    schedulingType: GetCapacitySchedulingTypeEnum;
    usState: string;
    page?: string;
    clinicId?: string;
    staffGlobalId?: string;
}
export interface GetNextAvailableSlotsRequest {
    id: string;
    appointmentType: string;
    schedulingType: GetNextAvailableSlotsSchedulingTypeEnum;
    usState: string;
    timezone?: string;
    clinicId?: string;
}
export interface GetSlotByIDRequest {
    id: string;
}
export interface GetSlotsForDateRangeRequest {
    clinicId?: string;
    appointmentType?: string;
    providerId?: string;
    providerType?: string;
    conflicted?: GetSlotsForDateRangeConflictedEnum;
    start?: string;
    end?: string;
}
export interface UpdateSlotRequest {
    id: string;
    createSlotCore?: CreateSlotCore;
}


export interface ISlotsApi {
      deleteSlot: (requestParameters: DeleteSlotRequest) => Promise<void>
      getAvailableTimeslots: (requestParameters: GetAvailableTimeslotsRequest) => Promise<AvailableTimeslotsResponse>
      getCapacity: (requestParameters: GetCapacityRequest) => Promise<CapacityFetchResponse>
      getNextAvailableSlots: (requestParameters: GetNextAvailableSlotsRequest) => Promise<NextAvailableSlotsFetchResponse>
      getSlotByID: (requestParameters: GetSlotByIDRequest) => Promise<SlotOutput>
      getSlotsForDateRange: (requestParameters: GetSlotsForDateRangeRequest) => Promise<Array<SlotsFetchResponse>>
      updateSlot: (requestParameters: UpdateSlotRequest) => Promise<CreateSlotCore>
      deleteSlotWithResponse: (requestParameters: DeleteSlotRequest) => Promise<{ value: void, response: any}>
      getAvailableTimeslotsWithResponse: (requestParameters: GetAvailableTimeslotsRequest) => Promise<{ value: AvailableTimeslotsResponse, response: any}>
      getCapacityWithResponse: (requestParameters: GetCapacityRequest) => Promise<{ value: CapacityFetchResponse, response: any}>
      getNextAvailableSlotsWithResponse: (requestParameters: GetNextAvailableSlotsRequest) => Promise<{ value: NextAvailableSlotsFetchResponse, response: any}>
      getSlotByIDWithResponse: (requestParameters: GetSlotByIDRequest) => Promise<{ value: SlotOutput, response: any}>
      getSlotsForDateRangeWithResponse: (requestParameters: GetSlotsForDateRangeRequest) => Promise<{ value: Array<SlotsFetchResponse>, response: any}>
      updateSlotWithResponse: (requestParameters: UpdateSlotRequest) => Promise<{ value: CreateSlotCore, response: any}>
}

/**
 * no description
 */
export class SlotsApi extends runtime.BaseAPI {

    /**
     * Delete a slot
     * Delete a slot
     */
    deleteSlotRaw = async (requestParameters: DeleteSlotRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSlot.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete a slot
     * Delete a slot
     */
    deleteSlot = async (requestParameters: DeleteSlotRequest): Promise<void> => {
        const { runtime } = await this.deleteSlotRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a slot
     * Delete a slot
     */
    deleteSlotWithResponse = async (requestParameters: DeleteSlotRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteSlotRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Returns all non booked timeslots for given appt details
     * Fetch available timeslots
     */
    getAvailableTimeslotsRaw = async (requestParameters: GetAvailableTimeslotsRequest): Promise<{ runtime: runtime.ApiResponse<AvailableTimeslotsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.itemCount === null || requestParameters.itemCount === undefined) {
            throw new runtime.RequiredError('itemCount','Required parameter requestParameters.itemCount was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.usState === null || requestParameters.usState === undefined) {
            throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
            throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getAvailableTimeslots.');
        }

                        if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
            throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getAvailableTimeslots.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.itemCount !== undefined) {
            queryParameters['item_count'] = requestParameters.itemCount;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.usState !== undefined) {
            queryParameters['us_state'] = requestParameters.usState;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.staffGlobalId !== undefined) {
            queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/available_timeslots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AvailableTimeslotsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Returns all non booked timeslots for given appt details
     * Fetch available timeslots
     */
    getAvailableTimeslots = async (requestParameters: GetAvailableTimeslotsRequest): Promise<AvailableTimeslotsResponse> => {
        const { runtime } = await this.getAvailableTimeslotsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Returns all non booked timeslots for given appt details
     * Fetch available timeslots
     */
    getAvailableTimeslotsWithResponse = async (requestParameters: GetAvailableTimeslotsRequest): Promise<{ value: AvailableTimeslotsResponse, response: any}> => {
        const { runtime, response } = await this.getAvailableTimeslotsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch the number of slots per day for given appointment details
     * Fetch capacity
     */
    getCapacityRaw = async (requestParameters: GetCapacityRequest): Promise<{ runtime: runtime.ApiResponse<CapacityFetchResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.daysCount === null || requestParameters.daysCount === undefined) {
            throw new runtime.RequiredError('daysCount','Required parameter requestParameters.daysCount was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.itemCount === null || requestParameters.itemCount === undefined) {
            throw new runtime.RequiredError('itemCount','Required parameter requestParameters.itemCount was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
            throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
            throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getCapacity.');
        }

                        if (requestParameters.usState === null || requestParameters.usState === undefined) {
            throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getCapacity.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.daysCount !== undefined) {
            queryParameters['days_count'] = requestParameters.daysCount;
        }

        if (requestParameters.itemCount !== undefined) {
            queryParameters['item_count'] = requestParameters.itemCount;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.staffGlobalId !== undefined) {
            queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
        }

        if (requestParameters.usState !== undefined) {
            queryParameters['us_state'] = requestParameters.usState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/capacity`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CapacityFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch the number of slots per day for given appointment details
     * Fetch capacity
     */
    getCapacity = async (requestParameters: GetCapacityRequest): Promise<CapacityFetchResponse> => {
        const { runtime } = await this.getCapacityRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch the number of slots per day for given appointment details
     * Fetch capacity
     */
    getCapacityWithResponse = async (requestParameters: GetCapacityRequest): Promise<{ value: CapacityFetchResponse, response: any}> => {
        const { runtime, response } = await this.getCapacityRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get the next available slots for given appointment details
     */
    getNextAvailableSlotsRaw = async (requestParameters: GetNextAvailableSlotsRequest): Promise<{ runtime: runtime.ApiResponse<NextAvailableSlotsFetchResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNextAvailableSlots.');
        }

                        if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
            throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getNextAvailableSlots.');
        }

                        if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
            throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getNextAvailableSlots.');
        }

                        if (requestParameters.usState === null || requestParameters.usState === undefined) {
            throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getNextAvailableSlots.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        if (requestParameters.usState !== undefined) {
            queryParameters['us_state'] = requestParameters.usState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/next_available_slots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => NextAvailableSlotsFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get the next available slots for given appointment details
     */
    getNextAvailableSlots = async (requestParameters: GetNextAvailableSlotsRequest): Promise<NextAvailableSlotsFetchResponse> => {
        const { runtime } = await this.getNextAvailableSlotsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get the next available slots for given appointment details
     */
    getNextAvailableSlotsWithResponse = async (requestParameters: GetNextAvailableSlotsRequest): Promise<{ value: NextAvailableSlotsFetchResponse, response: any}> => {
        const { runtime, response } = await this.getNextAvailableSlotsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get slot by ID
     * Get slot by ID
     */
    getSlotByIDRaw = async (requestParameters: GetSlotByIDRequest): Promise<{ runtime: runtime.ApiResponse<SlotOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSlotByID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotOutputFromJSON(jsonValue)), response };
    }

    /**
     * Get slot by ID
     * Get slot by ID
     */
    getSlotByID = async (requestParameters: GetSlotByIDRequest): Promise<SlotOutput> => {
        const { runtime } = await this.getSlotByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get slot by ID
     * Get slot by ID
     */
    getSlotByIDWithResponse = async (requestParameters: GetSlotByIDRequest): Promise<{ value: SlotOutput, response: any}> => {
        const { runtime, response } = await this.getSlotByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all slots for a given date range
     * Get all slots for a given date range
     */
    getSlotsForDateRangeRaw = async (requestParameters: GetSlotsForDateRangeRequest): Promise<{ runtime: runtime.ApiResponse<Array<SlotsFetchResponse>>, response: any}> => {
                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.providerId !== undefined) {
            queryParameters['provider_id'] = requestParameters.providerId;
        }

        if (requestParameters.providerType !== undefined) {
            queryParameters['provider_type'] = requestParameters.providerType;
        }

        if (requestParameters.conflicted !== undefined) {
            queryParameters['conflicted'] = requestParameters.conflicted;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SlotsFetchResponseFromJSON)), response };
    }

    /**
     * Get all slots for a given date range
     * Get all slots for a given date range
     */
    getSlotsForDateRange = async (requestParameters: GetSlotsForDateRangeRequest): Promise<Array<SlotsFetchResponse>> => {
        const { runtime } = await this.getSlotsForDateRangeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get all slots for a given date range
     * Get all slots for a given date range
     */
    getSlotsForDateRangeWithResponse = async (requestParameters: GetSlotsForDateRangeRequest): Promise<{ value: Array<SlotsFetchResponse>, response: any}> => {
        const { runtime, response } = await this.getSlotsForDateRangeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update a slot
     * Update a slot
     */
    updateSlotRaw = async (requestParameters: UpdateSlotRequest): Promise<{ runtime: runtime.ApiResponse<CreateSlotCore>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSlot.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSlotCoreToJSON(requestParameters.createSlotCore),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CreateSlotCoreFromJSON(jsonValue)), response };
    }

    /**
     * Update a slot
     * Update a slot
     */
    updateSlot = async (requestParameters: UpdateSlotRequest): Promise<CreateSlotCore> => {
        const { runtime } = await this.updateSlotRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update a slot
     * Update a slot
     */
    updateSlotWithResponse = async (requestParameters: UpdateSlotRequest): Promise<{ value: CreateSlotCore, response: any}> => {
        const { runtime, response } = await this.updateSlotRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAvailableTimeslotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCapacitySchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetNextAvailableSlotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetSlotsForDateRangeConflictedEnum {
    True = 'true',
    False = 'false'
}

export const fetchSlotsApi: ISlotsApi  = new SlotsApi();
