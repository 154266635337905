/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EpisodeOutput,
    EpisodeOutputFromJSON,
    EpisodeOutputToJSON,
} from '../models/EpisodeOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface UnarchiveEpisodeRequest {
    episodeId: string;
}


export interface IEpisodeApi {
      unarchiveEpisode: (requestParameters: UnarchiveEpisodeRequest) => Promise<EpisodeOutput>
      unarchiveEpisodeWithResponse: (requestParameters: UnarchiveEpisodeRequest) => Promise<{ value: EpisodeOutput, response: any}>
}

/**
 * no description
 */
export class EpisodeApi extends runtime.BaseAPI {

    /**
     * Update an archived episode\'s status to active
     * Update an archived episode to be unarchived
     */
    unarchiveEpisodeRaw = async (requestParameters: UnarchiveEpisodeRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeOutput>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling unarchiveEpisode.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/unarchive`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update an archived episode\'s status to active
     * Update an archived episode to be unarchived
     */
    unarchiveEpisode = async (requestParameters: UnarchiveEpisodeRequest): Promise<EpisodeOutput> => {
        const { runtime } = await this.unarchiveEpisodeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update an archived episode\'s status to active
     * Update an archived episode to be unarchived
     */
    unarchiveEpisodeWithResponse = async (requestParameters: UnarchiveEpisodeRequest): Promise<{ value: EpisodeOutput, response: any}> => {
        const { runtime, response } = await this.unarchiveEpisodeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchEpisodeApi: IEpisodeApi  = new EpisodeApi();
