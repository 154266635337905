import { colors } from "@mui/material";
import { PaletteColor } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    white: string;
    black?: string;
    accent?: PaletteColor;
    appBackground?: BackgroundOptions;
    logo?: PaletteColor;
    lightBackground?: PaletteColor;
    danger?: PaletteColor;
    strokes?: StrokesOptions;
    linkColorHover?: string;
    successColor?: string;
    redPeruTan?: string;
    yellow?: string;
    gray?: string;
    boxShadowLightGray?: string;
    blueHover?: string;
    calendar?: CalendarOptions;
    tab?: TabOptions;
    links?: LinkOptions;
    darkestGreyHover?: string;
    redHue?: string;
    lightGrayBackdrop?: string;
    lightGrayBackdropVariation?: string;
    borderGray?: string;
    icon?: string;
    yellowHover?: string;
    badgeGreen?: string;
    badgePurple?: string;
    badgeLightGreen?: string;
    tertiary?: string;
  }

  interface PaletteOptions {
    accent?: PaletteColor;
    appBackground?: PaletteColor;
    logo?: PaletteColor;
    lightBackground?: PaletteColor;
    danger?: PaletteColor;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary: string;
    strokes: string;
    disabled: string;
    hint: string;
    link: string;
    mediumDarkGray: string;
    mediumDarkGrayHover: string;
    placeholderForLightGreyBackgroundInputs: string;
  }
  interface StrokesOptions {
    tertiary: string;
    divider: string;
    borderLight: string;
  }

  interface BackgroundOptions extends PaletteColor {
    lightGrey?: string;
    medGrey?: string;
    darkGrey?: string;
    blue?: string;
    black?: string;
    darkBlue?: string;
    lightestGray?: string;
    darkestGray?: string;
    lightestGrayHover?: string;
    lightGrayBackdrop?: string;
    navy?: string;
    bluePastel?: string;
    lightBlue?: string;
  }

  interface CalendarOptions {
    primary?: string;
    selected?: string;
    textHover?: string;
    background?: string;
    lighterBackground?: string;
    hoverBackground?: string;
    selectedBackground?: string;
  }
  interface PillInterface {
    primary?: string;
    secondary?: string;
  }

  interface TabOptions {
    pill?: PillInterface;
  }

  interface LinkOptions {
    primary?: string;
    primaryHover?: string;
    secondary?: string;
    secondaryHover?: string;
    tertiary?: string;
    tertiaryHover?: string;
  }
}

const white = "#FFFFFF";
const black = "#000000";
const lightGrey = "rgba(43, 57, 73, 0.15)";
const medGrey = "#6B7480";
// used for placeholder text that needs to be slightly darker than medium grey to pass
// color constrast standards against light grey backgronds, but remains light enough
// that it still looks like placeholder text
const medGreyWithContrastForLightGreyBackground = "#5C6470";
const darkGrey = "#606A76";
const darkBlue = "#00293A";
const darkestGray = "#2B3949";
const blue = "#1C74AB";
const blueHover = "#035B92";
const darkestGreyHover = "#0C1A2A";
const redHue = "#CA4004";
const lightGrayBackdrop = "rgba(43, 57, 73, 0.6)";
const borderGray = "#DFE1E4";
const lightBlue = "#CEE4F6";
const badgeGreen = "#437800";
const badgePurple = "#6137D2";
const badgeLightGreen = "#ADEED1";

export default {
  black,
  white,
  primary: {
    main: "#F4B542",
    dark: "#E3A431",
  },
  tertiary: {
    main: "#e0e0e0",
    contrastText: "#1C74AB",
  },
  secondary: {
    contrastText: white,
    main: blue,
  },
  accent: {
    contrastText: white,
    main: "#0B665C",
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: "#E3F4DD",
    light: colors.green[400],
  },
  danger: {
    contrastText: white,
    dark: colors.red[900],
    main: "#F8ECEF",
    light: colors.red[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  appBackground: {
    main: "#F3F6F8",
    lightGrey,
    medGrey,
    darkestGray,
    darkGrey,
    black: "#2b3949",
    darkBlue,
    blue,
    lightestGray: "#F7F7F8",
    lightestGrayHover: "#EEEFF0",
    navy: "#4C5A73",
    bluePastel: "#CAD8E4",
    lightBlue,
  },
  logo: {
    main: "#E0AA5A",
  },
  text: {
    primary: darkestGray,
    secondary: darkGrey,
    tertiary: "#838B95",
    strokes: "#dfe1e4",
    link: blue,
    mediumDarkGray: "#838B95",
    mediumDarkGrayHover: "#A0A5A9",
    placeholderForLightGreyBackgroundInputs:
      medGreyWithContrastForLightGreyBackground,
  },
  tab: {
    pill: {
      primary: blue,
      secondary: "rgba(43, 57, 73, 0.6)",
    },
  },
  links: {
    primary: blue,
    primaryHover: blueHover,
    secondary: darkGrey,
    secondaryHover: "#4C5561",
    tertiary: darkestGray,
    tertiaryHover: darkestGreyHover,
  },
  strokes: {
    divider: lightGrey,
    tertiary: "rgba(43, 57, 73, 0.3)",
    borderLight: "#e1e1e1",
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  calendar: {
    primary: blue,
    selected: "#F4B542",
    textHover: "#2B3949",
    background: "#DFE1E4",
    lighterBackground: "#F5F5F5",
    hoverBackground: "#4C5A73",
    selectedBackground: "#1C74AB",
  },
  lightBackground: {
    main: "rgba(43, 57, 73, 0.08)",
  },
  linkColorHover: blueHover,
  redPeruTan: "#8e2d03",
  icon: colors.blueGrey[600],
  yellow: "#F4B542",
  yellowHover: "#e3a431",
  boxShadowLightGray: "rgba(135, 160, 182, 0.2)",
  blueHover,
  darkestGreyHover,
  lightGrayBackdrop,
  redHue,
  lightGrayBackdropVariation: "rgba(43,57,73,0.7)",
  borderGray,
  badgeGreen,
  badgePurple,
  badgeLightGreen,
} as any;
