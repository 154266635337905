/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OutboundScreeningObjectDetail,
    OutboundScreeningObjectDetailFromJSON,
    OutboundScreeningObjectDetailToJSON,
} from '../models/OutboundScreeningObjectDetail'
import {
    OutboundScreeningsDetailResponse,
    OutboundScreeningsDetailResponseFromJSON,
    OutboundScreeningsDetailResponseToJSON,
} from '../models/OutboundScreeningsDetailResponse'
import {
    OutboundScreeningsListResponse,
    OutboundScreeningsListResponseFromJSON,
    OutboundScreeningsListResponseToJSON,
} from '../models/OutboundScreeningsListResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface CompleteOutboundScreeningRequest {
    id: string;
    outboundScreeningId: string;
}
export interface ContinueOutboundScreeningRequest {
    id: string;
    outboundScreeningId: string;
}
export interface FetchUserOutboundScreeningsRequest {
    id: string;
}
export interface FetchUserOutboundScreeningsByIdRequest {
    id: string;
    outboundScreeningId: string;
}


export interface IOutboundScreeningApi {
      completeOutboundScreening: (requestParameters: CompleteOutboundScreeningRequest) => Promise<OutboundScreeningsDetailResponse>
      continueOutboundScreening: (requestParameters: ContinueOutboundScreeningRequest) => Promise<OutboundScreeningsDetailResponse>
      fetchUserOutboundScreenings: (requestParameters: FetchUserOutboundScreeningsRequest) => Promise<OutboundScreeningsListResponse>
      fetchUserOutboundScreeningsById: (requestParameters: FetchUserOutboundScreeningsByIdRequest) => Promise<OutboundScreeningObjectDetail>
      completeOutboundScreeningWithResponse: (requestParameters: CompleteOutboundScreeningRequest) => Promise<{ value: OutboundScreeningsDetailResponse, response: any}>
      continueOutboundScreeningWithResponse: (requestParameters: ContinueOutboundScreeningRequest) => Promise<{ value: OutboundScreeningsDetailResponse, response: any}>
      fetchUserOutboundScreeningsWithResponse: (requestParameters: FetchUserOutboundScreeningsRequest) => Promise<{ value: OutboundScreeningsListResponse, response: any}>
      fetchUserOutboundScreeningsByIdWithResponse: (requestParameters: FetchUserOutboundScreeningsByIdRequest) => Promise<{ value: OutboundScreeningObjectDetail, response: any}>
}

/**
 * no description
 */
export class OutboundScreeningApi extends runtime.BaseAPI {

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreeningRaw = async (requestParameters: CompleteOutboundScreeningRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsDetailResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeOutboundScreening.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling completeOutboundScreening.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsDetailResponseFromJSON(jsonValue)), response };
    }

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreening = async (requestParameters: CompleteOutboundScreeningRequest): Promise<OutboundScreeningsDetailResponse> => {
        const { runtime } = await this.completeOutboundScreeningRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreeningWithResponse = async (requestParameters: CompleteOutboundScreeningRequest): Promise<{ value: OutboundScreeningsDetailResponse, response: any}> => {
        const { runtime, response } = await this.completeOutboundScreeningRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreeningRaw = async (requestParameters: ContinueOutboundScreeningRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsDetailResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling continueOutboundScreening.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling continueOutboundScreening.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/screen`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsDetailResponseFromJSON(jsonValue)), response };
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreening = async (requestParameters: ContinueOutboundScreeningRequest): Promise<OutboundScreeningsDetailResponse> => {
        const { runtime } = await this.continueOutboundScreeningRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreeningWithResponse = async (requestParameters: ContinueOutboundScreeningRequest): Promise<{ value: OutboundScreeningsDetailResponse, response: any}> => {
        const { runtime, response } = await this.continueOutboundScreeningRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsRaw = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsListResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserOutboundScreenings.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsListResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreenings = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<OutboundScreeningsListResponse> => {
        const { runtime } = await this.fetchUserOutboundScreeningsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsWithResponse = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<{ value: OutboundScreeningsListResponse, response: any}> => {
        const { runtime, response } = await this.fetchUserOutboundScreeningsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsByIdRaw = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningObjectDetail>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserOutboundScreeningsById.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling fetchUserOutboundScreeningsById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningObjectDetailFromJSON(jsonValue)), response };
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsById = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<OutboundScreeningObjectDetail> => {
        const { runtime } = await this.fetchUserOutboundScreeningsByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsByIdWithResponse = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<{ value: OutboundScreeningObjectDetail, response: any}> => {
        const { runtime, response } = await this.fetchUserOutboundScreeningsByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchOutboundScreeningApi: IOutboundScreeningApi  = new OutboundScreeningApi();
