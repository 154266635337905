/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'


export interface FetchPatientAllergiesRequest {
    id: string;
}


export interface IAllergiesApi {
      fetchPatientAllergies: (requestParameters: FetchPatientAllergiesRequest) => Promise<Array<object>>
      fetchPatientAllergiesWithResponse: (requestParameters: FetchPatientAllergiesRequest) => Promise<{ value: Array<object>, response: any}>
}

/**
 * no description
 */
export class AllergiesApi extends runtime.BaseAPI {

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergiesRaw = async (requestParameters: FetchPatientAllergiesRequest): Promise<{ runtime: runtime.ApiResponse<Array<object>>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPatientAllergies.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/{id}/allergies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergies = async (requestParameters: FetchPatientAllergiesRequest): Promise<Array<object>> => {
        const { runtime } = await this.fetchPatientAllergiesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergiesWithResponse = async (requestParameters: FetchPatientAllergiesRequest): Promise<{ value: Array<object>, response: any}> => {
        const { runtime, response } = await this.fetchPatientAllergiesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchAllergiesApi: IAllergiesApi  = new AllergiesApi();
