/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PushNotificationCategoryResponse,
    PushNotificationCategoryResponseFromJSON,
    PushNotificationCategoryResponseToJSON,
} from '../models/PushNotificationCategoryResponse'


export interface DeletePushNotificationOptOutRequest {
    deviceId: string;
    pushNotificationCategoryId: string;
}


export interface IPushNotificationOptInApi {
      deletePushNotificationOptOut: (requestParameters: DeletePushNotificationOptOutRequest) => Promise<PushNotificationCategoryResponse>
      deletePushNotificationOptOutWithResponse: (requestParameters: DeletePushNotificationOptOutRequest) => Promise<{ value: PushNotificationCategoryResponse, response: any}>
}

/**
 * no description
 */
export class PushNotificationOptInApi extends runtime.BaseAPI {

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOutRaw = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoryResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deletePushNotificationOptOut.');
        }

                        if (requestParameters.pushNotificationCategoryId === null || requestParameters.pushNotificationCategoryId === undefined) {
            throw new runtime.RequiredError('pushNotificationCategoryId','Required parameter requestParameters.pushNotificationCategoryId was null or undefined when calling deletePushNotificationOptOut.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_category_id/{push_notification_category_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"push_notification_category_id"}}`, encodeURIComponent(String(requestParameters.pushNotificationCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoryResponseFromJSON(jsonValue)), response };
    }

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOut = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<PushNotificationCategoryResponse> => {
        const { runtime } = await this.deletePushNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOutWithResponse = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<{ value: PushNotificationCategoryResponse, response: any}> => {
        const { runtime, response } = await this.deletePushNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPushNotificationOptInApi: IPushNotificationOptInApi  = new PushNotificationOptInApi();
