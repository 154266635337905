/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    QuestionSetDetailsSchema,
    QuestionSetDetailsSchemaFromJSON,
    QuestionSetDetailsSchemaToJSON,
} from '../models/QuestionSetDetailsSchema'
import {
    QuestionSetRequestBody,
    QuestionSetRequestBodyFromJSON,
    QuestionSetRequestBodyToJSON,
} from '../models/QuestionSetRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'
import {
    XopQuestionSetCoreOutputSchema,
    XopQuestionSetCoreOutputSchemaFromJSON,
    XopQuestionSetCoreOutputSchemaToJSON,
} from '../models/XopQuestionSetCoreOutputSchema'


export interface AddNewQuestionSetRequest {
    questionSetRequestBody?: QuestionSetRequestBody;
}
export interface DeleteQuestionSetRequest {
    id: string;
}
export interface FetchQuestionSetByIdRequest {
    episodeId: string;
    questionSetId: string;
}
export interface GetQuestionSetRequest {
    id: string;
}
export interface UpdateQuestionSetByIdRequest {
    id: string;
    questionSetDetailsSchema?: QuestionSetDetailsSchema;
}


export interface IQuestionSetsApi {
      addNewQuestionSet: (requestParameters: AddNewQuestionSetRequest) => Promise<QuestionSetDetailsSchema>
      deleteQuestionSet: (requestParameters: DeleteQuestionSetRequest) => Promise<void>
      fetchQuestionSetById: (requestParameters: FetchQuestionSetByIdRequest) => Promise<XopQuestionSetCoreOutputSchema>
      getQuestionSet: (requestParameters: GetQuestionSetRequest) => Promise<QuestionSetDetailsSchema>
      updateQuestionSetById: (requestParameters: UpdateQuestionSetByIdRequest) => Promise<QuestionSetDetailsSchema>
      addNewQuestionSetWithResponse: (requestParameters: AddNewQuestionSetRequest) => Promise<{ value: QuestionSetDetailsSchema, response: any}>
      deleteQuestionSetWithResponse: (requestParameters: DeleteQuestionSetRequest) => Promise<{ value: void, response: any}>
      fetchQuestionSetByIdWithResponse: (requestParameters: FetchQuestionSetByIdRequest) => Promise<{ value: XopQuestionSetCoreOutputSchema, response: any}>
      getQuestionSetWithResponse: (requestParameters: GetQuestionSetRequest) => Promise<{ value: QuestionSetDetailsSchema, response: any}>
      updateQuestionSetByIdWithResponse: (requestParameters: UpdateQuestionSetByIdRequest) => Promise<{ value: QuestionSetDetailsSchema, response: any}>
}

/**
 * no description
 */
export class QuestionSetsApi extends runtime.BaseAPI {

    /**
     * add a new unique question set
     */
    addNewQuestionSetRaw = async (requestParameters: AddNewQuestionSetRequest): Promise<{ runtime: runtime.ApiResponse<QuestionSetDetailsSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionSetRequestBodyToJSON(requestParameters.questionSetRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetDetailsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * add a new unique question set
     */
    addNewQuestionSet = async (requestParameters: AddNewQuestionSetRequest): Promise<QuestionSetDetailsSchema> => {
        const { runtime } = await this.addNewQuestionSetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * add a new unique question set
     */
    addNewQuestionSetWithResponse = async (requestParameters: AddNewQuestionSetRequest): Promise<{ value: QuestionSetDetailsSchema, response: any}> => {
        const { runtime, response } = await this.addNewQuestionSetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * deactivate a question set by id
     */
    deleteQuestionSetRaw = async (requestParameters: DeleteQuestionSetRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQuestionSet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * deactivate a question set by id
     */
    deleteQuestionSet = async (requestParameters: DeleteQuestionSetRequest): Promise<void> => {
        const { runtime } = await this.deleteQuestionSetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * deactivate a question set by id
     */
    deleteQuestionSetWithResponse = async (requestParameters: DeleteQuestionSetRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteQuestionSetRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch question set by ID
     * Fetch question set by ID
     */
    fetchQuestionSetByIdRaw = async (requestParameters: FetchQuestionSetByIdRequest): Promise<{ runtime: runtime.ApiResponse<XopQuestionSetCoreOutputSchema>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling fetchQuestionSetById.');
        }

                        if (requestParameters.questionSetId === null || requestParameters.questionSetId === undefined) {
            throw new runtime.RequiredError('questionSetId','Required parameter requestParameters.questionSetId was null or undefined when calling fetchQuestionSetById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/question_sets/{question_set_id}`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"question_set_id"}}`, encodeURIComponent(String(requestParameters.questionSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => XopQuestionSetCoreOutputSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch question set by ID
     * Fetch question set by ID
     */
    fetchQuestionSetById = async (requestParameters: FetchQuestionSetByIdRequest): Promise<XopQuestionSetCoreOutputSchema> => {
        const { runtime } = await this.fetchQuestionSetByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch question set by ID
     * Fetch question set by ID
     */
    fetchQuestionSetByIdWithResponse = async (requestParameters: FetchQuestionSetByIdRequest): Promise<{ value: XopQuestionSetCoreOutputSchema, response: any}> => {
        const { runtime, response } = await this.fetchQuestionSetByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSetRaw = async (requestParameters: GetQuestionSetRequest): Promise<{ runtime: runtime.ApiResponse<QuestionSetDetailsSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQuestionSet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetDetailsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSet = async (requestParameters: GetQuestionSetRequest): Promise<QuestionSetDetailsSchema> => {
        const { runtime } = await this.getQuestionSetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSetWithResponse = async (requestParameters: GetQuestionSetRequest): Promise<{ value: QuestionSetDetailsSchema, response: any}> => {
        const { runtime, response } = await this.getQuestionSetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * update the specified question set
     * update the specified question set
     */
    updateQuestionSetByIdRaw = async (requestParameters: UpdateQuestionSetByIdRequest): Promise<{ runtime: runtime.ApiResponse<QuestionSetDetailsSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateQuestionSetById.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionSetDetailsSchemaToJSON(requestParameters.questionSetDetailsSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetDetailsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * update the specified question set
     * update the specified question set
     */
    updateQuestionSetById = async (requestParameters: UpdateQuestionSetByIdRequest): Promise<QuestionSetDetailsSchema> => {
        const { runtime } = await this.updateQuestionSetByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * update the specified question set
     * update the specified question set
     */
    updateQuestionSetByIdWithResponse = async (requestParameters: UpdateQuestionSetByIdRequest): Promise<{ value: QuestionSetDetailsSchema, response: any}> => {
        const { runtime, response } = await this.updateQuestionSetByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchQuestionSetsApi: IQuestionSetsApi  = new QuestionSetsApi();
