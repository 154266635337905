/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommonQuestionDetailsItem,
    CommonQuestionDetailsItemFromJSON,
    CommonQuestionDetailsItemToJSON,
} from '../models/CommonQuestionDetailsItem'
import {
    CommonQuestionRequestBody,
    CommonQuestionRequestBodyFromJSON,
    CommonQuestionRequestBodyToJSON,
} from '../models/CommonQuestionRequestBody'
import {
    CommonQuestionsSchema,
    CommonQuestionsSchemaFromJSON,
    CommonQuestionsSchemaToJSON,
} from '../models/CommonQuestionsSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AddCommonQuestionRequest {
    commonQuestionRequestBody?: CommonQuestionRequestBody;
}
export interface GetCommonQuestionsRequest {
    q?: string;
}


export interface ICommonQuestionsApi {
      addCommonQuestion: (requestParameters: AddCommonQuestionRequest) => Promise<CommonQuestionDetailsItem>
      getCommonQuestions: (requestParameters: GetCommonQuestionsRequest) => Promise<CommonQuestionsSchema>
      addCommonQuestionWithResponse: (requestParameters: AddCommonQuestionRequest) => Promise<{ value: CommonQuestionDetailsItem, response: any}>
      getCommonQuestionsWithResponse: (requestParameters: GetCommonQuestionsRequest) => Promise<{ value: CommonQuestionsSchema, response: any}>
}

/**
 * no description
 */
export class CommonQuestionsApi extends runtime.BaseAPI {

    /**
     * creates a common question
     * creates a common question
     */
    addCommonQuestionRaw = async (requestParameters: AddCommonQuestionRequest): Promise<{ runtime: runtime.ApiResponse<CommonQuestionDetailsItem>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/common_questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommonQuestionRequestBodyToJSON(requestParameters.commonQuestionRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CommonQuestionDetailsItemFromJSON(jsonValue)), response };
    }

    /**
     * creates a common question
     * creates a common question
     */
    addCommonQuestion = async (requestParameters: AddCommonQuestionRequest): Promise<CommonQuestionDetailsItem> => {
        const { runtime } = await this.addCommonQuestionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * creates a common question
     * creates a common question
     */
    addCommonQuestionWithResponse = async (requestParameters: AddCommonQuestionRequest): Promise<{ value: CommonQuestionDetailsItem, response: any}> => {
        const { runtime, response } = await this.addCommonQuestionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestionsRaw = async (requestParameters: GetCommonQuestionsRequest): Promise<{ runtime: runtime.ApiResponse<CommonQuestionsSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/common_questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CommonQuestionsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestions = async (requestParameters: GetCommonQuestionsRequest): Promise<CommonQuestionsSchema> => {
        const { runtime } = await this.getCommonQuestionsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestionsWithResponse = async (requestParameters: GetCommonQuestionsRequest): Promise<{ value: CommonQuestionsSchema, response: any}> => {
        const { runtime, response } = await this.getCommonQuestionsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCommonQuestionsApi: ICommonQuestionsApi  = new CommonQuestionsApi();
