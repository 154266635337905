/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentTypeSchema,
    ContentTypeSchemaFromJSON,
    ContentTypeSchemaToJSON,
} from '../models/ContentTypeSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetContentTypeListRequest {
    contentType: string;
    q?: string;
}


export interface ICmsToolsApi {
      getContentTypeList: (requestParameters: GetContentTypeListRequest) => Promise<ContentTypeSchema>
      getContentTypeListWithResponse: (requestParameters: GetContentTypeListRequest) => Promise<{ value: ContentTypeSchema, response: any}>
}

/**
 * no description
 */
export class CmsToolsApi extends runtime.BaseAPI {

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeListRaw = async (requestParameters: GetContentTypeListRequest): Promise<{ runtime: runtime.ApiResponse<ContentTypeSchema>, response: any}> => {
        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling getContentTypeList.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/tools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ContentTypeSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeList = async (requestParameters: GetContentTypeListRequest): Promise<ContentTypeSchema> => {
        const { runtime } = await this.getContentTypeListRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeListWithResponse = async (requestParameters: GetContentTypeListRequest): Promise<{ value: ContentTypeSchema, response: any}> => {
        const { runtime, response } = await this.getContentTypeListRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsToolsApi: ICmsToolsApi  = new CmsToolsApi();
