import { useQuery } from "react-query";
import { GetPrescriptionRequest, fetchPrescriptionsApi } from "shared/fetch/src/apis/PrescriptionsApi";

export const memberPrescriptionsListQueryKey = (id?: string) => [
  "memberPrescriptionsList",
  id?.toString(),
];

const useGetPrescriptions = (request: GetPrescriptionRequest) => {
  const query = useQuery(
    memberPrescriptionsListQueryKey(request.id),
    () => fetchPrescriptionsApi.getPrescription(request),
    {
      enabled: !!request.id && request.id !== "undefined",
      keepPreviousData: true,
    }
  );
  return query;
};

export default useGetPrescriptions;
