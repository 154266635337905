/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ProxyHistoryOutput,
    ProxyHistoryOutputFromJSON,
    ProxyHistoryOutputToJSON,
} from '../models/ProxyHistoryOutput'
import {
    ProxyMinorRequestBody,
    ProxyMinorRequestBodyFromJSON,
    ProxyMinorRequestBodyToJSON,
} from '../models/ProxyMinorRequestBody'
import {
    RevokeProxyRequestBody,
    RevokeProxyRequestBodyFromJSON,
    RevokeProxyRequestBodyToJSON,
} from '../models/RevokeProxyRequestBody'
import {
    StandaloneTransitionComplete,
    StandaloneTransitionCompleteFromJSON,
    StandaloneTransitionCompleteToJSON,
} from '../models/StandaloneTransitionComplete'
import {
    StandaloneTransitionRequest,
    StandaloneTransitionRequestFromJSON,
    StandaloneTransitionRequestToJSON,
} from '../models/StandaloneTransitionRequest'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ProxyMinorRequest {
    memberId: string;
    minorId: string;
    proxyMinorRequestBody?: ProxyMinorRequestBody;
}
export interface RevokeProxyRequest {
    memberId: string;
    minorId: string;
    revokeProxyRequestBody?: RevokeProxyRequestBody;
}
export interface V1ConsentsMinorIdProxyHistoryGetRequest {
    minorId: string;
}
export interface XOPlatformStandaloneTransitionRequest {
    id: string;
    standaloneTransitionRequest?: StandaloneTransitionRequest;
}


export interface INestedTransitionApi {
      proxyMinor: (requestParameters: ProxyMinorRequest) => Promise<void>
      revokeProxy: (requestParameters: RevokeProxyRequest) => Promise<void>
      v1ConsentsMinorIdProxyHistoryGet: (requestParameters: V1ConsentsMinorIdProxyHistoryGetRequest) => Promise<Array<ProxyHistoryOutput>>
      xOPlatformStandaloneTransition: (requestParameters: XOPlatformStandaloneTransitionRequest) => Promise<StandaloneTransitionComplete>
      proxyMinorWithResponse: (requestParameters: ProxyMinorRequest) => Promise<{ value: void, response: any}>
      revokeProxyWithResponse: (requestParameters: RevokeProxyRequest) => Promise<{ value: void, response: any}>
      v1ConsentsMinorIdProxyHistoryGetWithResponse: (requestParameters: V1ConsentsMinorIdProxyHistoryGetRequest) => Promise<{ value: Array<ProxyHistoryOutput>, response: any}>
      xOPlatformStandaloneTransitionWithResponse: (requestParameters: XOPlatformStandaloneTransitionRequest) => Promise<{ value: StandaloneTransitionComplete, response: any}>
}

/**
 * no description
 */
export class NestedTransitionApi extends runtime.BaseAPI {

    /**
     * Allows a parent to proxy for a standalone minor and nest them
     */
    proxyMinorRaw = async (requestParameters: ProxyMinorRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling proxyMinor.');
        }

                        if (requestParameters.minorId === null || requestParameters.minorId === undefined) {
            throw new runtime.RequiredError('minorId','Required parameter requestParameters.minorId was null or undefined when calling proxyMinor.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/proxy_minor/{minor_id}`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"minor_id"}}`, encodeURIComponent(String(requestParameters.minorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProxyMinorRequestBodyToJSON(requestParameters.proxyMinorRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Allows a parent to proxy for a standalone minor and nest them
     */
    proxyMinor = async (requestParameters: ProxyMinorRequest): Promise<void> => {
        const { runtime } = await this.proxyMinorRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Allows a parent to proxy for a standalone minor and nest them
     */
    proxyMinorWithResponse = async (requestParameters: ProxyMinorRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.proxyMinorRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Allows a parent to revoke proxy consent for a nested minor and return them to standalone
     */
    revokeProxyRaw = async (requestParameters: RevokeProxyRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling revokeProxy.');
        }

                        if (requestParameters.minorId === null || requestParameters.minorId === undefined) {
            throw new runtime.RequiredError('minorId','Required parameter requestParameters.minorId was null or undefined when calling revokeProxy.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/revoke_proxy/{minor_id}`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"minor_id"}}`, encodeURIComponent(String(requestParameters.minorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevokeProxyRequestBodyToJSON(requestParameters.revokeProxyRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Allows a parent to revoke proxy consent for a nested minor and return them to standalone
     */
    revokeProxy = async (requestParameters: RevokeProxyRequest): Promise<void> => {
        const { runtime } = await this.revokeProxyRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Allows a parent to revoke proxy consent for a nested minor and return them to standalone
     */
    revokeProxyWithResponse = async (requestParameters: RevokeProxyRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.revokeProxyRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Retrieves the proxy history of consent actions for a specified minor.
     * Get consent proxy history for a minor
     */
    v1ConsentsMinorIdProxyHistoryGetRaw = async (requestParameters: V1ConsentsMinorIdProxyHistoryGetRequest): Promise<{ runtime: runtime.ApiResponse<Array<ProxyHistoryOutput>>, response: any}> => {
        if (requestParameters.minorId === null || requestParameters.minorId === undefined) {
            throw new runtime.RequiredError('minorId','Required parameter requestParameters.minorId was null or undefined when calling v1ConsentsMinorIdProxyHistoryGet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/consents/{minor_id}/proxy_history`.replace(`{${"minor_id"}}`, encodeURIComponent(String(requestParameters.minorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProxyHistoryOutputFromJSON)), response };
    }

    /**
     * Retrieves the proxy history of consent actions for a specified minor.
     * Get consent proxy history for a minor
     */
    v1ConsentsMinorIdProxyHistoryGet = async (requestParameters: V1ConsentsMinorIdProxyHistoryGetRequest): Promise<Array<ProxyHistoryOutput>> => {
        const { runtime } = await this.v1ConsentsMinorIdProxyHistoryGetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieves the proxy history of consent actions for a specified minor.
     * Get consent proxy history for a minor
     */
    v1ConsentsMinorIdProxyHistoryGetWithResponse = async (requestParameters: V1ConsentsMinorIdProxyHistoryGetRequest): Promise<{ value: Array<ProxyHistoryOutput>, response: any}> => {
        const { runtime, response } = await this.v1ConsentsMinorIdProxyHistoryGetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * XO Platform transition a standalone to nested
     */
    xOPlatformStandaloneTransitionRaw = async (requestParameters: XOPlatformStandaloneTransitionRequest): Promise<{ runtime: runtime.ApiResponse<StandaloneTransitionComplete>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling xOPlatformStandaloneTransition.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/member/transition/nested/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandaloneTransitionRequestToJSON(requestParameters.standaloneTransitionRequest),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => StandaloneTransitionCompleteFromJSON(jsonValue)), response };
    }

    /**
     * XO Platform transition a standalone to nested
     */
    xOPlatformStandaloneTransition = async (requestParameters: XOPlatformStandaloneTransitionRequest): Promise<StandaloneTransitionComplete> => {
        const { runtime } = await this.xOPlatformStandaloneTransitionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * XO Platform transition a standalone to nested
     */
    xOPlatformStandaloneTransitionWithResponse = async (requestParameters: XOPlatformStandaloneTransitionRequest): Promise<{ value: StandaloneTransitionComplete, response: any}> => {
        const { runtime, response } = await this.xOPlatformStandaloneTransitionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchNestedTransitionApi: INestedTransitionApi  = new NestedTransitionApi();
