/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    PushNotificationCategoryResponse,
    PushNotificationCategoryResponseFromJSON,
    PushNotificationCategoryResponseToJSON,
} from '../models/PushNotificationCategoryResponse'
import {
    PushNotificationOptOutRequestBody,
    PushNotificationOptOutRequestBodyFromJSON,
    PushNotificationOptOutRequestBodyToJSON,
} from '../models/PushNotificationOptOutRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnenrollDevicePushNotificationsRequestBody,
    UnenrollDevicePushNotificationsRequestBodyFromJSON,
    UnenrollDevicePushNotificationsRequestBodyToJSON,
} from '../models/UnenrollDevicePushNotificationsRequestBody'


export interface PushNotificationOptOutRequest {
    deviceId: string;
    pushNotificationOptOutRequestBody?: PushNotificationOptOutRequestBody;
}
export interface UnenrollDevicePushNotificationsRequest {
    unenrollDevicePushNotificationsRequestBody?: UnenrollDevicePushNotificationsRequestBody;
}


export interface IUnenrollDevicePushNotificationsApi {
      pushNotificationOptOut: (requestParameters: PushNotificationOptOutRequest) => Promise<PushNotificationCategoryResponse>
      unenrollDevicePushNotifications: (requestParameters: UnenrollDevicePushNotificationsRequest) => Promise<void>
      pushNotificationOptOutWithResponse: (requestParameters: PushNotificationOptOutRequest) => Promise<{ value: PushNotificationCategoryResponse, response: any}>
      unenrollDevicePushNotificationsWithResponse: (requestParameters: UnenrollDevicePushNotificationsRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class UnenrollDevicePushNotificationsApi extends runtime.BaseAPI {

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOutRaw = async (requestParameters: PushNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoryResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling pushNotificationOptOut.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_category`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushNotificationOptOutRequestBodyToJSON(requestParameters.pushNotificationOptOutRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoryResponseFromJSON(jsonValue)), response };
    }

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOut = async (requestParameters: PushNotificationOptOutRequest): Promise<PushNotificationCategoryResponse> => {
        const { runtime } = await this.pushNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOutWithResponse = async (requestParameters: PushNotificationOptOutRequest): Promise<{ value: PushNotificationCategoryResponse, response: any}> => {
        const { runtime, response } = await this.pushNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotificationsRaw = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/unenroll_push_notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnenrollDevicePushNotificationsRequestBodyToJSON(requestParameters.unenrollDevicePushNotificationsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotifications = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<void> => {
        const { runtime } = await this.unenrollDevicePushNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotificationsWithResponse = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.unenrollDevicePushNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchUnenrollDevicePushNotificationsApi: IUnenrollDevicePushNotificationsApi  = new UnenrollDevicePushNotificationsApi();
