import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { useMutation, useQueryClient } from "react-query";
import { CreatePrescriptionRefillRequest, fetchPrescriptionsRefillsApi } from "shared/fetch/src/apis/PrescriptionsRefillsApi";

const usePrescriptionRefillMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (request: CreatePrescriptionRefillRequest) =>
      fetchPrescriptionsRefillsApi.createPrescriptionRefill(request),
    {
      onSuccess: (_response, request) => {
        dispatch(showSnackbar("Renewal has been requested"));
        queryClient.invalidateQueries(["memberPrescriptionsList", request.id]);
      },
    }
  );

  return mutation;
};

export default usePrescriptionRefillMutation;
