/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    MemberAppointmentTypesSchema,
    MemberAppointmentTypesSchemaFromJSON,
    MemberAppointmentTypesSchemaToJSON,
} from '../models/MemberAppointmentTypesSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMemberAppointmentTypesRequest {
    id: number;
    serviceLineId: string;
    podId?: number;
}


export interface IMemberAppointmentTypesApi {
      fetchMemberAppointmentTypes: (requestParameters: FetchMemberAppointmentTypesRequest) => Promise<MemberAppointmentTypesSchema>
      fetchMemberAppointmentTypesWithResponse: (requestParameters: FetchMemberAppointmentTypesRequest) => Promise<{ value: MemberAppointmentTypesSchema, response: any}>
}

/**
 * no description
 */
export class MemberAppointmentTypesApi extends runtime.BaseAPI {

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypesRaw = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<{ runtime: runtime.ApiResponse<MemberAppointmentTypesSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberAppointmentTypes.');
        }

                        if (requestParameters.serviceLineId === null || requestParameters.serviceLineId === undefined) {
            throw new runtime.RequiredError('serviceLineId','Required parameter requestParameters.serviceLineId was null or undefined when calling fetchMemberAppointmentTypes.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.serviceLineId !== undefined) {
            queryParameters['service_line_id'] = requestParameters.serviceLineId;
        }

        if (requestParameters.podId !== undefined) {
            queryParameters['pod_id'] = requestParameters.podId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/appointment_types`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAppointmentTypesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypes = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<MemberAppointmentTypesSchema> => {
        const { runtime } = await this.fetchMemberAppointmentTypesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypesWithResponse = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<{ value: MemberAppointmentTypesSchema, response: any}> => {
        const { runtime, response } = await this.fetchMemberAppointmentTypesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberAppointmentTypesApi: IMemberAppointmentTypesApi  = new MemberAppointmentTypesApi();
