/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EnrollPushNotificationRequestBody,
    EnrollPushNotificationRequestBodyFromJSON,
    EnrollPushNotificationRequestBodyToJSON,
} from '../models/EnrollPushNotificationRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PushNotificationCategoriesResponse,
    PushNotificationCategoriesResponseFromJSON,
    PushNotificationCategoriesResponseToJSON,
} from '../models/PushNotificationCategoriesResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface EnrollDevicePushNotificationRequest {
    enrollPushNotificationRequestBody?: EnrollPushNotificationRequestBody;
}
export interface GetEnrolledDevicePushNotificationCategoriesRequest {
    deviceId: string;
}


export interface IEnrollDevicePushNotificationApi {
      enrollDevicePushNotification: (requestParameters: EnrollDevicePushNotificationRequest) => Promise<void>
      getEnrolledDevicePushNotificationCategories: (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest) => Promise<PushNotificationCategoriesResponse>
      enrollDevicePushNotificationWithResponse: (requestParameters: EnrollDevicePushNotificationRequest) => Promise<{ value: void, response: any}>
      getEnrolledDevicePushNotificationCategoriesWithResponse: (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest) => Promise<{ value: PushNotificationCategoriesResponse, response: any}>
}

/**
 * no description
 */
export class EnrollDevicePushNotificationApi extends runtime.BaseAPI {

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotificationRaw = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enroll_push_notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnrollPushNotificationRequestBodyToJSON(requestParameters.enrollPushNotificationRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotification = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<void> => {
        const { runtime } = await this.enrollDevicePushNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotificationWithResponse = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.enrollDevicePushNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategoriesRaw = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoriesResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getEnrolledDevicePushNotificationCategories.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_categories`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoriesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategories = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<PushNotificationCategoriesResponse> => {
        const { runtime } = await this.getEnrolledDevicePushNotificationCategoriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategoriesWithResponse = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<{ value: PushNotificationCategoriesResponse, response: any}> => {
        const { runtime, response } = await this.getEnrolledDevicePushNotificationCategoriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchEnrollDevicePushNotificationApi: IEnrollDevicePushNotificationApi  = new EnrollDevicePushNotificationApi();
