/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticateWithChallenge,
    AuthenticateWithChallengeFromJSON,
    AuthenticateWithChallengeToJSON,
} from '../models/AuthenticateWithChallenge'
import {
    BiometricAuthenticationSuccess,
    BiometricAuthenticationSuccessFromJSON,
    BiometricAuthenticationSuccessToJSON,
} from '../models/BiometricAuthenticationSuccess'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface BiometricLoginRequest {
    authenticateWithChallenge?: AuthenticateWithChallenge;
}


export interface IAuthChallengeApi {
      biometricLogin: (requestParameters: BiometricLoginRequest) => Promise<BiometricAuthenticationSuccess>
      biometricLoginWithResponse: (requestParameters: BiometricLoginRequest) => Promise<{ value: BiometricAuthenticationSuccess, response: any}>
}

/**
 * no description
 */
export class AuthChallengeApi extends runtime.BaseAPI {

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLoginRaw = async (requestParameters: BiometricLoginRequest): Promise<{ runtime: runtime.ApiResponse<BiometricAuthenticationSuccess>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/oauth2/authenticate_with_challenge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateWithChallengeToJSON(requestParameters.authenticateWithChallenge),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => BiometricAuthenticationSuccessFromJSON(jsonValue)), response };
    }

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLogin = async (requestParameters: BiometricLoginRequest): Promise<BiometricAuthenticationSuccess> => {
        const { runtime } = await this.biometricLoginRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLoginWithResponse = async (requestParameters: BiometricLoginRequest): Promise<{ value: BiometricAuthenticationSuccess, response: any}> => {
        const { runtime, response } = await this.biometricLoginRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchAuthChallengeApi: IAuthChallengeApi  = new AuthChallengeApi();
