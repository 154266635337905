/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberServicesAndCentersObject,
    MemberServicesAndCentersObjectFromJSON,
    MemberServicesAndCentersObjectToJSON,
} from '../models/MemberServicesAndCentersObject'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMemberAccessibleTeamsRequest {
    id: string;
    pageSize?: string;
    page?: string;
    podId?: string;
    serviceLineName?: string;
    coreCareTeam?: boolean;
}


export interface IMemberTeamsApi {
      fetchMemberAccessibleTeams: (requestParameters: FetchMemberAccessibleTeamsRequest) => Promise<MemberServicesAndCentersObject>
      fetchMemberAccessibleTeamsWithResponse: (requestParameters: FetchMemberAccessibleTeamsRequest) => Promise<{ value: MemberServicesAndCentersObject, response: any}>
}

/**
 * no description
 */
export class MemberTeamsApi extends runtime.BaseAPI {

    /**
     * Fetch a Members Accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeamsRaw = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<{ runtime: runtime.ApiResponse<MemberServicesAndCentersObject>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberAccessibleTeams.');
        }

                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.podId !== undefined) {
            queryParameters['pod_id'] = requestParameters.podId;
        }

        if (requestParameters.serviceLineName !== undefined) {
            queryParameters['service_line_name'] = requestParameters.serviceLineName;
        }

        if (requestParameters.coreCareTeam !== undefined) {
            queryParameters['core_care_team'] = requestParameters.coreCareTeam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/member_teams`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberServicesAndCentersObjectFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a Members Accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeams = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<MemberServicesAndCentersObject> => {
        const { runtime } = await this.fetchMemberAccessibleTeamsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a Members Accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeamsWithResponse = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<{ value: MemberServicesAndCentersObject, response: any}> => {
        const { runtime, response } = await this.fetchMemberAccessibleTeamsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberTeamsApi: IMemberTeamsApi  = new MemberTeamsApi();
