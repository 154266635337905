/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnenrollBiometicsRequestBody,
    UnenrollBiometicsRequestBodyFromJSON,
    UnenrollBiometicsRequestBodyToJSON,
} from '../models/UnenrollBiometicsRequestBody'


export interface UnenrollDeviceBiometricsRequest {
    unenrollBiometicsRequestBody?: UnenrollBiometicsRequestBody;
}


export interface IUnenrollDeviceApi {
      unenrollDeviceBiometrics: (requestParameters: UnenrollDeviceBiometricsRequest) => Promise<void>
      unenrollDeviceBiometricsWithResponse: (requestParameters: UnenrollDeviceBiometricsRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class UnenrollDeviceApi extends runtime.BaseAPI {

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometricsRaw = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/unenroll_biometrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnenrollBiometicsRequestBodyToJSON(requestParameters.unenrollBiometicsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometrics = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<void> => {
        const { runtime } = await this.unenrollDeviceBiometricsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometricsWithResponse = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.unenrollDeviceBiometricsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchUnenrollDeviceApi: IUnenrollDeviceApi  = new UnenrollDeviceApi();
