/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CTMInfoArrayResponse1,
    CTMInfoArrayResponse1FromJSON,
    CTMInfoArrayResponse1ToJSON,
} from '../models/CTMInfoArrayResponse1'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetActiveEpisodesPortalRequest {
    id: string;
    yourRole?: string;
}


export interface IActiveEpisodesPortalApi {
      getActiveEpisodesPortal: (requestParameters: GetActiveEpisodesPortalRequest) => Promise<CTMInfoArrayResponse1>
      getActiveEpisodesPortalWithResponse: (requestParameters: GetActiveEpisodesPortalRequest) => Promise<{ value: CTMInfoArrayResponse1, response: any}>
}

/**
 * no description
 */
export class ActiveEpisodesPortalApi extends runtime.BaseAPI {

    /**
     * Get active episodes
     */
    getActiveEpisodesPortalRaw = async (requestParameters: GetActiveEpisodesPortalRequest): Promise<{ runtime: runtime.ApiResponse<CTMInfoArrayResponse1>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActiveEpisodesPortal.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.yourRole !== undefined) {
            queryParameters['your_role'] = requestParameters.yourRole;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/active-episodes-for-portal`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CTMInfoArrayResponse1FromJSON(jsonValue)), response };
    }

    /**
     * Get active episodes
     */
    getActiveEpisodesPortal = async (requestParameters: GetActiveEpisodesPortalRequest): Promise<CTMInfoArrayResponse1> => {
        const { runtime } = await this.getActiveEpisodesPortalRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get active episodes
     */
    getActiveEpisodesPortalWithResponse = async (requestParameters: GetActiveEpisodesPortalRequest): Promise<{ value: CTMInfoArrayResponse1, response: any}> => {
        const { runtime, response } = await this.getActiveEpisodesPortalRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchActiveEpisodesPortalApi: IActiveEpisodesPortalApi  = new ActiveEpisodesPortalApi();
