// @ts-ignore
import React from "react";

const Markup = () => {
  return (
    <div>
      <h2
        style={{ lineHeight: "1.2", marginBottom: "4pt", marginTop: "14pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              All patients must acknowledge and agree to the following Universal
              Consent to receive care services from Crossover Health Medical
              Group, APC, a California professional corporation, and/or its
              affiliates, Crossover Health Medical Group, P.A., a Kansas
              professional association; Crossover Health Medical Group, P.C., a
              New Jersey professional corporation; Crossover Health Medical
              Group Ezeji-Okoye, P.C., a Nevada professional corporation; and
              Crossover Health Medical Group, S.C., a Wisconsin service
              corporation (collectively, “Crossover Health”).
            </strong>
          </span>
        </span>
      </h2>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Please read this Universal Consent carefully; it is a legally
            binding contract. By checking the box indicating you "Consent”
            (“Acceptance”), you acknowledge that you understand and agree to be
            bound by this Universal Consent for yourself, if you are the
            patient, or on behalf of your child, if they are the patient, in
            which case, you acknowledge that you are the parent or legal
            guardian, and acknowledge receipt and understanding of, and agree to
            be bound by, the, General Consent, Patient Rights and
            Responsibilities, State-Specific Notices contained herein and
            acknowledge receipt of the Notice of Privacy Practices.&nbsp;
          </span>
        </span>
      </p>
      <h3
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginTop: "14pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>Notice Of Privacy Practices</u>
            </strong>
          </span>
        </span>
      </h3>
      <h3
        style={{
          lineHeight: "1.2",
          marginBottom: "4pt",
          marginTop: "0pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <u>(Effective Date: April 2023)</u>
          </span>
        </span>
      </h3>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "9pt",
          marginTop: "9pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
            AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
            REVIEW IT CAREFULLY.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            As required by the privacy regulations created as a result of the
            Health Insurance Portability and Accountability Act of 1996 (HIPAA):
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>A. Our commitment to your privacy:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our practice is dedicated to maintaining the privacy of your
            individually identifiable health information (also called protected
            health information, or PHI). In conducting our business, we will
            receive information and create records regarding you and the
            treatment and services we provide to you. We are required by law to
            maintain the confidentiality of health information that identifies
            you, whether that information is obtained from you in person, on the
            phone, via a telehealth appointment, via Crossover-maintained web
            pages, or via any other mechanism of communication . We also are
            required by law to provide you with this notice of our legal duties
            and the privacy practices that we maintain in our practice
            concerning your PHI. By federal and state law, we must follow the
            terms of the Notice of Privacy Practices that we have in effect at
            the time.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We realize that these laws are complicated, but we must provide you
            with the following important information:
          </span>
        </span>
      </p>
      <ul style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
          }}
        >
          How we may use and disclose your PHI
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your privacy rights in your PHI
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Our obligations concerning the use and disclosure of your PHI
            </span>
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The terms of this notice apply to all records containing your PHI
            that are created or retained by our practice. We reserve the right
            to revise or amend this Notice of Privacy Practices (the "Notice").
            Any revision or amendment to the Notice will be effective for all of
            your records that our practice has created or maintained in the
            past, and for any of your records that we may create or maintain in
            the future. Our practice will post a copy of our current Notice in
            our clinics in a visible location at all times and on our website,
            and you may request a copy of our most current Notice at any time.
            We are required to abide by the terms of the notice currently in
            effect. A revised Notice may be obtained by forwarding a written
            request to Crossover Health, 101 W. Avenida Vista Hermosa Suite 120,
            San Clemente, CA 92672.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>B. Your personal information:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We keep records of the medical care we provide you, and we may
            receive similar records from others. We use this information so that
            we, or other health care providers, can render quality medical care,
            obtain payment for services and enable us to meet our professional
            and legal responsibilities to operate our medical practice. We may
            store this information in a chart and in our computers. This
            information makes up your medical record. The medical record is our
            property; however, this notice explains how we use information about
            you and when we are allowed to share that information with others.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>C. Our privacy practices:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We have a HIPAA and Health Information Technology for Economic and
            Clinical Health ("HITECH") Act Policy in place to help ensure your
            PHI is protected. Crossover Health not only uses traditional methods
            to deliver care but also cutting edge technology to help deliver
            quality care to our patients. It is our policy to maintain
            reasonable and feasible physical, electronic and process safeguards
            to restrict unauthorized access to and protect the availability and
            integrity of your health information. Our protective measures may
            include secured office facilities, locked file cabinets, managed
            computer network systems and password protected accounts. Access to
            health information is only granted on a "need-to-know" basis. Once
            the need is established the access is limited to the minimum
            necessary information to accomplish the intended purpose. Our staff
            are required to comply with the policies and procedures designed to
            protect the confidentiality of your health information. Any staff
            member who violates our privacy policy is subject to disciplinary
            action.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              D. If you have questions about this Notice, please contact:
            </strong>
          </span>
        </span>
      </h4>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginLeft: "36pt",
          marginTop: "9pt",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Crossover Health
          </span>
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginLeft: "36pt",
          marginTop: "0pt",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            101 W. Avenida Vista Hermosa Suite 120
          </span>
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "9pt",
          marginLeft: "36pt",
          marginTop: "0pt",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            San Clemente, CA 92672
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              E. We may use and disclose your PHI in the following ways:
            </strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The following categories describe the different ways in which we may
            use and disclose your PHI.
          </span>
        </span>
      </p>
      <ol style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treatment. Our practice may use your PHI to treat you. For
              example, we may ask you to have laboratory tests (such as blood or
              urine tests), and we may use the results to help us reach a
              diagnosis. We might use your PHI in order to write a prescription
              for you, or we might disclose your PHI to a pharmacy when we order
              a prescription for you. Many of the people who work for our
              practice - including, but not limited to, our doctors and nurses
              (collectively, “Providers”) - may use or disclose your PHI in
              order to treat you or to assist others in your treatment. We also
              may receive from and disclose to other health care providers your
              PHI for purposes related to your treatment.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Payment. Our practice may use and disclose your PHI in order to
              bill and collect payment for the services and items you may
              receive from us. In addition, and by way of example of disclosures
              for payment purposes, we may disclose your PHI to other health
              care providers and entities to assist in their billing and
              collection efforts.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Health care operations. Our practice may use and disclose your PHI
              to operate our business. As examples of the ways in which we may
              use and disclose your information for our operations, our practice
              may use your PHI to evaluate the quality of care you received from
              us, or to conduct cost-management and business planning activities
              for our practice. We may disclose your PHI to other health care
              providers and entities to assist in their health care operations.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Appointment reminders. Our practice may use and disclose your PHI
              to contact you and remind you of an appointment.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treatment options. Our practice may use and disclose your PHI to
              inform you of potential treatment alternatives or other
              health-related benefits and services that may be of interest to
              you.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Health-related benefits and services. Our practice may use and
              disclose your PHI to inform you of health-related benefits or
              services that may be of interest to you.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Release of information to family/friends. Our practice may release
              your PHI to a friend or family member that is involved in your
              care, or who assists in taking care of you. However, any such
              disclosure will be subject to legal requirements and our HIPAA and
              HITECH Policy.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Disclosures required by law. Our practice will use and disclose
              your PHI when we are required to do so by federal, state or local
              law.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "10.5pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Organized Health Care Arrangements. Crossover Health participates
              in Organized Health Care Arrangements (“OHCA”s) at certain
              healthcare clinics it operates. An OHCA is: (i) a clinically
              integrated healthcare setting where patients may receive care from
              multiple healthcare providers or (ii) an organized system of
              healthcare where more than one healthcare provider participates.
              Under an OHCA, providers from different healthcare companies share
              medical and billing information with one another as necessary to
              carry out treatment, payment, and healthcare operations. Each
              member of the OHCA is responsible for their own activities,
              including compliance with all HIPAA privacy-related laws. View a
              list of the clinics, and participants where Crossover participates
              in an OHCA here:&nbsp;{" "}
            </span>
          </span>
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://crossoverhealth.com/legal/crossover-health-clinics-with-ohcas/"
          >
            <span
              style={{
                color: "#1155cc",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  WebkitTextDecorationSkip: "none",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <u>
                  https://crossoverhealth.com/legal/crossover-health-clinics-with-ohcas/
                </u>
              </span>
            </span>
          </a>
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . The participating providers are not employees of Crossover
              Health and are independent contractors who each exercise their own
              independent professional judgment in the provision of your
              healthcare.
            </span>
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginLeft: "36pt",
          marginTop: "0pt",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Crossover Health Medical Group, APC, a California professional
            corporation, and its affiliates Crossover Health Medical Group,
            P.A., a Kansas professional association; Crossover Health Medical
            Group, P.C., a New Jersey professional corporation; Crossover Health
            Medical Group Ezeji-Okoye, P.C., a Nevada professional corporation;
            and Crossover Health Medical Group, S.C., a Wisconsin service
            corporation also participate in an OHCA.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "10pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              F. Use and disclosure of your PHI in certain special
              circumstances:
            </strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The following categories describe unique scenarios in which we may
            use or disclose your identifiable health information:
          </span>
        </span>
      </p>
      <ol style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Public health risks. Our practice may disclose your PHI to public
              health authorities that are authorized by law to collect
              information for the purpose of:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Maintaining vital records, such as births and deaths;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Reporting child abuse or neglect;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Preventing or controlling disease, injury or disability;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notifying a person regarding potential exposure to a
                  communicable disease;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notifying a person regarding a potential risk for spreading or
                  contracting a disease or condition
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Reporting reactions to drugs or problems with products or
                  devices;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notifying individuals if a product or device they may be using
                  has been recalled;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notifying appropriate government agency(ies) and
                  authority(ies) regarding the potential abuse or neglect of an
                  adult patient (including domestic violence); however, we will
                  only disclose this information if the patient agrees or we are
                  required or authorized by law to disclose this information; or
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notifying your employer under limited circumstances related
                  primarily to workplace injury or illness or medical
                  surveillance.
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Health oversight activities. Our practice may disclose your PHI to
              a health oversight agency for activities authorized by law.
              Oversight activities can include, for example, investigations,
              inspections, audits, surveys, licensure and disciplinary actions;
              civil, administrative and criminal procedures or actions; or other
              activities necessary for the government to monitor government
              programs, compliance with civil rights laws and the health care
              system in general.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Lawsuits and similar proceedings. Our practice may use and
              disclose your PHI in response to a court or administrative order,
              if you are involved in a lawsuit or similar proceeding. We also
              may disclose your PHI in response to a discovery request, subpoena
              or other lawful process by another party involved in the dispute,
              but only if we have made an effort to inform you of the request or
              to obtain an order protecting the information the party has
              requested.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Law enforcement. We may release PHI if asked to do so by a law
              enforcement official:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Regarding a crime victim in certain situations, if we are
                  unable to obtain the person's agreement;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Concerning a death we believe has resulted from criminal
                  conduct;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Regarding criminal conduct at our offices;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In response to a warrant, summons, court order, subpoena or
                  similar legal process;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  To identify/locate a suspect, material witness, fugitive or
                  missing person; or
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In an emergency, to report a crime (including the location or
                  victim(s) of the crime, or the description, identity or
                  location of the perpetrator).
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Preemption by state laws. We will follow applicable state law when
              it is more stringent than the Federal law. This generally
              includes, but is not limited to, laws that:&nbsp;
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  give individuals greater rights regarding their PHI;&nbsp;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  prevent fraud and abuse in health care and payment for health
                  care;&nbsp;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  regulate controlled substances; required reporting by law;
                  and/or&nbsp;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  require health plans to report or give access to PHI.
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Deceased patients. Our practice may release PHI to a medical
              examiner or coroner to identify a deceased individual or to
              identify the cause of death. If necessary, we also may release
              information in order for funeral directors to perform their jobs.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Organ and tissue donation. Our practice may release your PHI to
              organizations that handle organ, eye or tissue procurement or
              transplantation, including organ donation banks, as necessary to
              facilitate organ or tissue donation and transplantation if you are
              an organ donor.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Research. Our practice may use and disclose your PHI for research
              purposes in certain limited circumstances. We will obtain your
              written authorization to use your PHI for research purposes except
              when an Internal Review Board or Privacy Board has determined that
              the waiver of your authorization satisfies all of the following
              conditions:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The use or disclosure involves no more than a minimal risk to
                  your privacy based on the following: (a) an adequate plan to
                  protect the identifiers from improper use and disclosure; (b)
                  an adequate plan to destroy the identifiers at the earliest
                  opportunity consistent with the research (unless there is a
                  health or research justification for retaining the identifiers
                  or such retention is otherwise required by law); and (c)
                  adequate written assurances that the PHI will not be re-used
                  or disclosed to any other person or entity (except as required
                  by law) for authorized oversight of the research study, or for
                  other research for which the use or disclosure would otherwise
                  be permitted;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The research could not practicably be conducted without the
                  waiver; and
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The research could not practicably be conducted without access
                  to and use of the PHI.
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Serious threats to health or safety. Our practice may use and
              disclose your PHI when necessary to reduce or prevent a serious
              threat to your health and safety or the health and safety of
              another individual or the public. Under these circumstances, we
              will only make disclosures to a person or organization able to
              help prevent the threat.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Military. Our practice may disclose your PHI if you are a member
              of U.S. or foreign military forces (including veterans) and if
              required by the appropriate authorities.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              National security. Our practice may disclose your PHI to federal
              officials for intelligence and national security activities
              authorized by law. We also may disclose your PHI to federal and
              national security activities authorized by law. We also may
              disclose your PHI to federal officials in order to protect the
              president, other officials or foreign heads of state, or to
              conduct investigations.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Inmates. Our practice may disclose your PHI to correctional
              institutions or law enforcement officials if you are an inmate or
              under the custody of a law enforcement official. Disclosure for
              these purposes would be necessary: (a) for the institution to
              provide health care services to you, (b) for the safety and
              security of the institution, and/or (c) to protect your health and
              safety or the health and safety of other individuals.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Workers' compensation. Our practice may release your PHI for
              workers' compensation and similar programs.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Change of ownership. In the event that our practice is sold or
              merged with another organization, your medical record will become
              the property of the new owner, although you will maintain the
              right to request that copies of your health information be
              transferred to another physician or medical group.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              De-Identified data. We may use or share your PHI once it has been
              "de-identified." PHI is considered de-identified when it has been
              processed in such a way that it can no longer personally identify
              you.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Limited Data Sets. We may also use a "limited data set" that does
              not contain any information that can directly identify you. This
              limited data set may only be used for the purposes of research,
              public health matters or health care operations. For example, a
              limited data set may include your city, county and zip code, but
              not your name or street address.
            </span>
          </span>
        </li>
      </ol>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              G. Receiving PHI from providers, insurance entities and their
              business associates:
            </strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We want to make you aware that, just as Crossover Health uses and
            discloses certain PHI in your treatment, our operations and
            management and certain payment practices, Crossover Health receives
            PHI from other healthcare entities and their business associates
            including but not limited to: medical files, charts, laboratory
            testing results, imagining results, and insurance claims data. PHI
            that is received and maintained by Crossover Health from outside
            entities is subject to the protections of relevant law and our HIPAA
            and HITECH policy.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>H. Your written permission:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Except as described in this Notice, or as otherwise permitted by
            law, we must obtain your written permission - called an
            authorization - prior to using or sharing health information that
            identifies you as an individual. If you provide an authorization and
            then change your mind, you may revoke your authorization in writing
            at any time. Once an authorization has been revoked, we will no
            longer use or share your health information as outlined in the
            authorization form; however you should be aware that we won't be
            able to retract a use or disclosure that was previously made in good
            faith based on what was then a valid authorization from you.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Except as specified above under the applicable state law of the
            practice location, we may not share your health information with
            your employer or benefit plan unless you provide us an authorization
            to do so.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>I. Other Restrictions:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Under the applicable state law of the practice location, there may
            be additional laws regarding the use and disclosure of health
            information related to HIV status, communicable diseases,
            reproductive health, genetic test results, substance abuse, mental
            health and intellectual disability. Generally, we will be bound by
            whatever law is more stringent and provides more protection for your
            privacy.
          </span>
        </span>
      </p>
      <h4
        style={{ lineHeight: "1.2", marginBottom: "2pt", marginTop: "12pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>J. Your rights regarding your PHI:</strong>
          </span>
        </span>
      </h4>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the following rights regarding the PHI that we maintain
            about you:
          </span>
        </span>
      </p>
      <ol style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Confidential communications. You have the right to request that
              our practice communicate with you about your health and related
              issues in a particular manner or at a certain location. For
              instance, you may ask that we contact you at home, rather than
              work. In order to request a type of confidential communication,
              you must make a written request to Crossover Health, 101 W.
              Avenida Vista Hermosa Suite 120, San Clemente, CA 92672 and inform
              us of the requested method of contact, or the location where you
              wish to be contacted. Our practice will accommodate reasonable
              requests. You do not need to give a reason for your request.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Requesting restrictions. You have the right to request a
              restriction in our use or disclosure of your PHI for treatment,
              payment or health care operations. Additionally, you have the
              right to request that we restrict our disclosure of your PHI to
              only certain individuals involved in your care or the payment for
              your care, such as family members and friends. We are not required
              to agree to your request; however, if we do agree, we are bound by
              our agreement except when otherwise required by law, in
              emergencies or when the information is necessary to treat you. In
              order to request a restriction in our use or disclosure of your
              PHI, you must make your request in writing to Crossover Health,
              101 W. Avenida Vista Hermosa Suite 120, San Clemente, CA 92672.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your request must describe in a clear and concise fashion:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The information you wish restricted;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Whether you are requesting to limit our practice's use,
                  disclosure or both; and/or
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  To whom you want the limits to apply.
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Inspection and copies. You have the right to inspect and obtain a
              copy of the PHI that may be used to make decisions about you,
              including patient medical records and billing records, but not
              including psychotherapy notes. You must submit your request in
              writing to Crossover Health, 101 W. Avenida Vista Hermosa Suite
              120, San Clemente, CA 92672 in order to inspect and/or obtain a
              copy of your PHI. Our practice may charge a fee for the costs of
              copying, mailing, labor and supplies associated with your request.
              Our practice may deny your request to inspect and/or copy in
              certain limited circumstances; however, you may request a review
              of our denial. Another licensed health care professional chosen by
              us will conduct reviews.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Amendment. You may ask us to amend your health information if you
              believe it is incorrect or incomplete, and you may request an
              amendment for as long as the information is kept by or for our
              practice. To request an amendment, your request must be made in
              writing and submitted to Crossover Health, 101 W. Avenida Vista
              Hermosa Suite 120, San Clemente, CA 92672.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You must provide us with a reason that supports your request for
              amendment. Our practice will deny your request if you fail to
              submit your request (and the reason supporting your request) in
              writing. Also, we may deny your request if you ask us to amend
              information that is in our opinion: (a) accurate and complete; (b)
              not part of the PHI kept by or for the practice; (c) not part of
              the PHI which you would be permitted to inspect and copy; or (d)
              not created by our practice, unless the individual or entity that
              created the information is not available to amend the information.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Accounting of disclosures. All of our patients have the right to
              request an "accounting of disclosures." An "accounting of
              disclosures" is a list of certain non-routine disclosures our
              practice has made of your PHI for purposes not related to
              treatment, payment or operations. Use of your PHI as part of the
              routine patient care in our practice is not required to be
              documented - for example, the doctor sharing information with the
              nurse; or the billing department using your information to file
              your insurance claim. In order to obtain an accounting of
              disclosures, you must submit your request in writing to Crossover
              Health, 101 W. Avenida Vista Hermosa Suite 120, San Clemente, CA
              92672.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              All requests for an "accounting of disclosures" must state a time
              period, which may not be longer than six (6) years from the date
              of disclosure. The first list you request within a 12-month period
              is free of charge, but our practice may charge you for additional
              lists within the same 12-month period. Our practice will notify
              you of the costs involved with additional requests, and you may
              withdraw your request before you incur any costs.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Right to a paper copy of this notice. You are entitled to receive
              a paper copy of our Notice. You may ask us to give you a copy of
              this Notice at any time. To obtain a paper copy of this Notice,
              contact Crossover Health at (949) 891-0328.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Right to file a complaint. If you believe your privacy rights have
              been violated, you may file a complaint with our practice or with
              the Secretary of the Department of Health and Human Services. To
              file a complaint with our practice, contact Crossover Health, 101
              W. Avenida Vista Hermosa Suite 120, San Clemente, CA 92672. All
              complaints must be submitted in writing. You will not be penalized
              for filing a complaint.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Right to provide an authorization for other uses and disclosures.
              Our practice will obtain your written authorization for uses and
              disclosures that are not identified by this Notice or permitted by
              applicable law. Any authorization you provide to us regarding the
              use and disclosure of your PHI may be revoked at any time in
              writing. After you revoke your authorization, we will no longer
              use or disclose your PHI for the reasons described in the
              authorization. Please note: we are required to retain records of
              your care.
            </span>
          </span>
        </li>
      </ol>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "10pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Again, if you have any questions regarding this Notice or our health
            information privacy policies, please contact Crossover Health at
            (949) 891-0328.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I acknowledge that I have received the Notice of Privacy Practices
            for Crossover Health, and have been provided an opportunity to
            review it. If you have any questions or would like a hard copy of
            this Notice, please ask for one at the front desk or contact
            Crossover Health at (949) 891-0328.
          </span>
        </span>
      </p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "9pt",
          marginTop: "9pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>NOTICE REGARDING MINORS: PRIVACY AND CONFIDENTIALITY</u>
            </strong>
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            While state laws generally allow a minor’s parents and legal
            guardians to obtain the minor’s medical information, certain state
            and federal laws protect minor confidentiality in certain
            circumstances. These laws also allow a minor to consent to certain
            types of care without a parent or guardian’s consent. In those
            circumstances, information concerning a minor’s medical care will
            not be available without permission from the minor.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Subject to state and federal law, our general policy is:
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>For minors under 12</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            , parents and legal guardians can access their child’s medical
            information without restriction.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Beginning at age 12</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            , parents and legal guardians will have reduced access to their
            child’s health information. Crossover is unable to partition
            patients’ electronic medical records to separate confidential
            information from the information a parent or legal guardian may
            obtain. Therefore parents and legal guardians will not have
            electronic access to their child’s clinical information, but may
            access scheduling requests and bill pay. Parents and guardians will
            still be able to receive their child’s medical information, other
            than the minor’s confidential information, by contacting Crossover
            Health.&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Where a minor requests a Crossover provider’s assistance in
            communicating with a parent or guardian regarding a medical issue,
            or when a provider determines that sharing information would be in
            the best interest of the minor, our providers will work with the
            minor on a communication plan and obtain their consent to share
            information, or explain to the minor what information the provider
            needs to share for their safety.&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Beginning at age 18,</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            {" "}
            parents and guardians will no longer have access to their child’s
            medical information held by Crossover Health.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              Non-custodial parent and incapacitated patient record access
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Except where limited by law or court order, and consistent with
            the guidelines above, a noncustodial parent has the same right to
            access a minor’s medical information as the custodial parent. In the
            case of mentally incapacitated patients, parents/legal guardians may
            access the patient’s information pursuant to state law.
          </span>
        </span>
      </p>
      <h2
        style={{
          lineHeight: "1.2",
          marginBottom: "4pt",
          marginTop: "18pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>GENERAL CONSENT</u>
            </strong>
          </span>
        </span>
      </h2>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I hereby give my consent for Crossover Health to receive, use and
            disclose Protected Health Information ("PHI") about me, including
            but not limited to medical charts, records, laboratory results,
            imaging results, insurance claims data and information, to carry out
            treatment, payment and health care operation ("TPO") as described in
            the Notice of Privacy Practices. I recognize the need for medical
            care; authorize the Crossover Health to render such medical and
            ancillary care, tests, procedures, drugs and other services and
            supplies under the general and specific instruction of the Crossover
            Health. Except for emergency or extraordinary circumstances, it is
            my understanding that additional consents will be obtained by my
            treating Provider if more invasive services are to be performed or
            if additional consents or authorizations are required by law. I
            understand and am aware that the practice of medicine is not an
            exact science and acknowledge that no guarantee has been made to me
            as to the result of treatment or examination. I understand that it
            is my right to consent, or to refuse consent, to any proposed
            procedure or therapeutic course.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to ask your Provider what type of treatment they
            are planning based on your history, diagnosis, symptoms and testing
            results.&nbsp; You may also discuss with your Provider what the
            potential risks and benefits of a specific treatment might be. You
            have the right to decline any portion of your treatment at any time
            or during your treatment session.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I understand that Crossover Health uses advanced technology to
            deliver quality care, and I consent to the use of this technology.
            If you have any questions regarding the technology used by Crossover
            Health, please contact Crossover Health at (949) 891-0328.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Also, with this consent Crossover Health may:
          </span>
        </span>
      </p>
      <ol style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Call my home or other alternative location and leave a message on
              voice mail or in person in reference to any items that assist the
              practice in carrying out TPO, such as appointment reminders,
              insurance items and any calls pertaining to my clinical care,
              including laboratory test results, among others.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mail to my home or other alternative location any items that
              assist the practice in carrying out TPO, such as appointment
              reminder cards, patient statements, and any items pertaining to my
              clinical care, including laboratory test results, among others.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              May e-mail to my home or other alternative location any items that
              assist the practice in carrying out TPO, such as appointment
              reminder cards, patient statements, and any items pertaining to my
              clinical care, including laboratory test results, among others.
            </span>
          </span>
        </li>
      </ol>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginTop: "10pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>CONSENTS FOR ANCILLARY SERVICES</u>
            </strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you are receiving the below services, your Acceptance includes
            your consent to the following:
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Physical Therapy Services:</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The nature of physical therapy</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Physical therapy involves the use of many different types of
            physical evaluation and treatment. The Crossover physical therapy
            Provider may use a variety of procedures and modalities that can be
            used to help try and improve your function. As with all forms of
            medical treatment, there are benefits and risks involved with
            physical therapy.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As a part of the analysis, examination, and treatment, you are
            consenting to the following possible procedures: therapeutic
            exercise, massage therapy, electrical stimulation, range of motion
            testing, orthopedic testing, muscle strength testing, postural
            analysis, testing, ultrasound, and heat/cold therapy.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              Material risks inherent in physical therapy services
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Therapeutic manipulation/mobilization and exercises are an
            integral part of most physical therapy treatment plans. This
            manipulation/mobilization and these exercises have inherent physical
            risks associated with them. If you have any questions regarding the
            type of exercise you are performing and any specific risks
            associated with your exercises or the manipulations/mobilization,
            your therapist will be glad to answer them.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The probability of those risks occurring</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Since the physical responses to a specific treatment can vary
            widely from person to person, it is not always possible to
            accurately predict your response to a certain therapy modality or
            procedure. We are not able to guarantee precisely what your reaction
            to a particular treatment might be, nor can we guarantee that our
            treatment will help the condition for which you are seeking
            treatment. There is also a risk that your treatment may cause pain
            or injury, or may aggravate previously existing conditions.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Chiropractic Services:</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As a part of the analysis, examination, and treatment, you are
            consenting to the following possible procedures: spinal manipulative
            therapy; palpation; vital signs; range of motion testing; orthopedic
            testing; basic neurological testing; muscle strength testing;
            postural analysis; testing; ultrasound; hot/cold therapy; EMS; and
            radiographic studies.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The nature of the chiropractic adjustment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . The primary treatment used by Crossover chiropractic Providers is
            spinal manipulative therapy. The Crossover Provider may use their
            hands or a mechanical instrument upon your body in such a way as to
            move your joints. That may cause an audible “pop” or “click,” much
            as you have experienced when you “crack” your knuckles. You may feel
            a sense of movement.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As a part of the analysis, examination, and treatment, you are
            consenting to the following possible procedures: spinal manipulative
            therapy; palpation; vital signs; range of motion testing; orthopedic
            testing; basic neurological testing; muscle strength testing;
            postural analysis; testing; ultrasound; hot/cold therapy; EMS; and
            radiographic studies.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The material risks inherent in chiropractic adjustment
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As with any health care procedure, there are certain complications
            which may arise during chiropractic manipulation and therapy. These
            complications include but are not limited to: fractures, disc
            injuries, dislocations, muscle strain, cervical myelopathy,
            costovertebral strains and separations, and burns. Some types of
            manipulation of the neck have been associated with injuries to the
            arteries in the neck leading to or contributing to serious
            complications including stroke. Some patients will feel some
            stiffness and soreness following the first few days of treatment.
            Your Provider will make reasonable efforts during the examination to
            screen for contraindications to care; however, if you have a
            condition that would otherwise not come to our attention, it is your
            responsibility to inform us.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The probability of those risks occurring</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Fractures are rare occurrences and generally result from some
            underlying weakness of the bone which will be checked for during the
            taking of your history and during examination and X-ray. Stroke has
            been the subject of tremendous disagreement. The incidences of
            stroke are exceedingly rare and are estimated to occur between one
            in one million and one in five million cervical adjustments. The
            other complications are also generally described as rare.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The potential benefits of chiropractic adjustment.</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            {" "}
            The vast majority of chiropractic patients tend to achieve
            improvement in their physical conditions with chiropractic care.
            Improvement can be measured in many different ways, including
            reduction in pain, increased range of motion, less stiffness,
            increased athletic performance, and other ways. Different people get
            different results; different people have different pre- existing
            conditions, and are of different ages and occupations (with
            different types of physical stress). Your situation is unique, and
            no guarantees are given. You will have to determine what results you
            get for yourself.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The availability and nature of other treatment options
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Other treatment options for your condition may include: rest,
            acupuncture, physical therapy, medical care, medications (both over
            the counter and prescribed), hospitalization, surgery, and
            others.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you chose to use one of the above noted “other treatment”
            options, you should be aware that there are risks and benefits of
            such options and you may wish to discuss these with your primary
            medical physician.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The risks and dangers attendant to remaining untreated
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Remaining untreated may allow the formation of adhesions and
            reduce mobility which may set up a pain reaction further reducing
            mobility. Over time this process may complicate treatment making it
            more difficult and less effective the longer it is postponed.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Acupuncture Services:</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; As a part of the analysis, examination, and treatment, you
            are consenting to the following possible procedures:&nbsp;
            acupuncture; Tui-Na (Chinese massage); moxibustion; Chinese herbal
            medicine; cupping; scraping techniques (i.e., Gua Sha); nutritional
            and lifestyle counseling; and electrical stimulation.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You understand that herbs may need to be prepared and the teas
            consumed according to the instructions provided orally and in
            writing. The herbs may have an unpleasant smell or taSuite You will
            immediately notify us of any unanticipated or unpleasant effects
            associated with the consumption of the herbs.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The material risks inherent in acupuncture</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; You have been informed that acupuncture is a generally safe
            method of treatment, but that it may have some side effects,
            including bruising, numbness, swelling, or tingling near the
            needling sites that may last a few days, and dizziness or fainting.
            Bruising is a common side effect of cupping and Gua Sha/scraping.
            Unusual risks of acupuncture include nerve damage and organ
            puncture, including lung puncture (pneumothorax).&nbsp; Although
            Crossover acupuncturists use sterile disposable needles and maintain
            a clean and safe environment, infection is another possible risk.
            Potential risks related to burns and/or scarring are unusual but may
            occur when treatment involves the use of infrared heat lamps. You
            understand that while this document describes the major risks of
            treatment, other side effects and risks may occur. The herbs and
            nutritional supplements (which are from plant, animal and mineral
            sources) that have been recommended are traditionally considered
            safe in the practice of Chinese Medicine, although some may be toxic
            in large doses.&nbsp; You understand that some herbs may be
            inappropriate during pregnancy.&nbsp; Some possible side effects of
            taking herbs are nausea, gas, stomach ache, vomiting, headache,
            diarrhea, rashes, hives, and tingling of the tongue.&nbsp; You will
            notify the Crossover Provider who is caring for you if you are or
            become pregnant, or are actively trying to conceive or are nursing.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The probability of those risks occurring</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; Since the physical responses to a specific treatment can
            vary widely from person to person, it is not always possible to
            accurately predict your response to a certain procedure.&nbsp;
            Crossover is not able to guarantee precisely what your reaction to a
            particular treatment might be, nor can we guarantee that our
            treatment will help the condition for which you are seeking
            treatment.&nbsp;&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "10pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Massage Therapy Services:</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The nature of massage therapy</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Massage therapy involves the rubbing and kneading of muscles and
            joints of the body. During your session, your body will be draped at
            all times for comfort, security and warmth. Massage therapy is for
            the purpose of stress reduction, pain reduction, relief from muscle
            tension, and increasing circulation.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As a part of the analysis, examination, and treatment, you are
            consenting to the following possible procedures: massage therapy
            procedures and techniques and heat/cold therapy.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The material risks inherent in massage therapy services.
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            {" "}
            You have been informed that massage therapy is safe, but that it may
            have some side effects, particularly with deep tissue massage, the
            influx of toxins such as lactic acid from your muscles can cause
            nausea, vomiting or soreness. These side effects are rare and should
            subside relatively quickly. However, Crossover massage therapists do
            not diagnose illness or disease, or any other disorder. Massage
            therapy is not a substitute for medical examinations or medical
            care, and it is recommended that you are concurrently working with
            your primary physician for any condition you may have.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The probability of those risks occurring</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Since the physical responses to a specific treatment can vary
            widely from person to person, it is not always possible to
            accurately predict your response to a certain treatment. We are not
            able to guarantee precisely what your reaction to a particular
            treatment might be, nor can we guarantee that our treatment will
            help the condition for which you are seeking treatment. There is
            also a risk that your treatment may cause pain or injury, or may
            aggravate previously existing conditions. Because massage should not
            be performed under certain medical conditions, you must inform your
            massage therapist of all your known physical conditions, medical
            conditions, and medications and keep the massage therapist updated
            of any changes. If you experience any pain or discomfort during the
            session, you agree to immediately inform the massage therapist of
            any discomfort, so the application of pressure or strokes may be
            adjusted accordingly to fit your level of comfort.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to ask your Crossover massage therapist what type
            of massage they are planning to perform based on your history,
            diagnosis, and symptoms. You may also discuss with your massage
            therapist what the potential risks and benefits of a specific
            massage or treatment might be. You have the right to request and
            require that any procedure or technique be modified, changed or
            stopped. You also have the right to have any part of your body not
            massaged and agree to let the therapist know. You have the right to
            decline any portion of your treatment at any time or during your
            treatment session.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Health Coaching Services:</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; As a part of the analysis, examination, and treatment, you
            are consenting to the following possible procedures:&nbsp;
            consultation and guidance about health factors within your own
            control such as your diet, nutrition, lifestyle, and nutritional
            evaluation or testing.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The material risks inherent in nutritional counseling
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; You have been informed that nutritional counseling is
            safe.&nbsp; However, you understand that the Crossover
            Dietitian/Health Coach is a Health Coach or Registered Dietitian not
            a medical physician and does not provide medical advice, nor will
            the Crossover Health Coach/Dietitian diagnose or treat any medical
            condition but will provide nutrition and lifestyle support and
            education for an already diagnosed condition. The Crossover Health
            Coach/Dietitian may provide education to enhance knowledge of health
            through the use of whole foods, lifestyle education on the benefits
            of movement, sleep and stress management and emotional
            awareness.&nbsp; While nutrition and lifestyle coaching&nbsp; can be
            an important complement to medical care, you understand these
            services are not a substitute for medical care.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Methods of nutrition and lifestyle evaluation or testing made
            available to you are not intended to diagnose disease.&nbsp; Rather,
            these assessment tests are intended as a guide to developing an
            appropriate health-supportive program for you, and to monitor your
            progress in achieving goals.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Understand that the Crossover Health Coach/Dietitian will keep notes
            as a record of your work together.&nbsp; These notes document the
            topics that you talk about, interventions used, and treatment plans
            or any other considerations that may be helpful to your work with
            your health coach/dietitian.&nbsp; Records will be stored in a
            secure location.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Medical records, personal information and history divulged in
            session to the Crossover Health Coach/Dietitian will be kept
            strictly confidential unless you consent to sharing your medical and
            nutritional information by way of a signed release.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The probability of those risks or benefits occurring
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp; Since the health factors are within your own control, the
            probability of the desired outcomes can vary widely from person to
            person, it is not always possible to accurately predict your
            response to a certain nutrition or lifestyle program.&nbsp; We are
            not able to guarantee what your results might be, nor can we
            guarantee that our consultation and evaluation will help the
            condition for which you are seeking treatment.&nbsp;&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              If receiving Mental Health Counseling and Therapy Services:
            </strong>
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              The nature of couples counseling, family counseling and
              psychotherapy.
            </strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            {" "}
            Therapy works best when you are an active partner in the process, so
            please know that Crossover Providers welcome your feedback or
            questions about our work at any time.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The material beneﬁts inherent in therapy</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Participating in therapy may result in beneﬁts including, but not
            limited to: reduced stress and anxiety; a decrease in negative
            thoughts and self-sabotaging behaviors; improved interpersonal
            relationships; increased comfort in social, work, and family
            settings; increased capacity for intimacy; increased self-conﬁdence
            and ability to experience life more fully; and deeper self-awareness
            and self-acceptance. Such beneﬁts may require substantial effort on
            your part, including active participation in the therapeutic
            process, honesty, and a willingness to change feelings, thoughts and
            behaviors as needed. There is no guarantee that therapy will yield
            any or all of the beneﬁts listed above.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The material risks inherent in therapy</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Participating in therapy may involve discomfort, including
            discussing difﬁcult feelings and experiences, and may evoke strong
            emotions, including anger, sadness, and fear. During the therapeutic
            process, many clients ﬁnd that they may feel worse before they feel
            better. This is generally a normal course of events. Personal growth
            and change may be easy and swift at times while slow or frustrating
            at other times. You may also at times feel conﬂicted about attending
            sessions. If this is the case, we urge you to bring up your concerns
            so that we can address them. The process of therapy may sometimes
            result in unanticipated outcomes, such as changes in personal or
            career relationships and goals. Please be aware that any decisions
            about your relationships, personal life, or work life are your
            responsibility.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Conﬁdentiality</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Therapy is designed to be a safe place for you to talk about any
            personal issues you choose to explore. The information disclosed by
            you is generally conﬁdential and will not be released to any third
            party outside of Crossover Health without written authorization from
            you, except where required or permitted by law. This means that we
            will not divulge anything you tell us to anyone except in either of
            the following conditions:
          </span>
        </span>
      </p>
      <ul style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You give us your permission to talk to another provider, pursuant
              to the Crossover Notices of Privacy Practices, such as a
              health-care professional who is providing you treatment.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You tell us something that your counselor is legally required to
              reveal to others.&nbsp;
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Professional consultation is an important component of a healthy
              psychotherapy practice. As such, your therapist regularly
              participates in clinical, ethical, and legal consultation with
              appropriate professionals. During such consultations, your
              therapist will not reveal any of your personally identifying
              information.
            </span>
          </span>
        </li>
      </ul>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Your therapist may take notes during a session, and will also
            produce other notes and records regarding treatment. These notes
            constitute clinical and business records, which by law, your
            therapist is required to maintain. Your therapist will not alter
            his/her normal record keeping process at the request of any patient.
            Any request of a copy of your records must be made in writing and
            done pursuant to the Crossover Notice of Privacy Practices. Your
            therapist may also reserve the right to refuse to produce a copy of
            the record under certain circumstances. As with all your medical
            records, your behavioral health records are securely stored and
            separate from your employment records. Patient conﬁdentiality
            includes a ﬁrewall between Crossover Health and your employer. As
            part of our integrated approach to whole person health and wellness,
            all Providers have access to the electronic medical record and
            notes, and will consult across disciplines to deliver the best
            possible care. This will occur exclusively on a need to know basis
            for the purpose of continuity and coordination of care.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you are seeing us for couples therapy or family therapy, we
            consider your relationship or the family unit to be the patient.
            During the course of our work, we may see one individual or multiple
            individuals for one or more sessions or for part of a session.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            These sessions should be seen as part of the work that we are doing
            with the couple or family, unless otherwise indicated. However,
            please know that anything we discuss when your partner or other
            family members are not present may be disclosed to them if, in the
            best judgment of your therapist, doing so is necessary to
            effectively help your relationship or family unit. This "no secrets"
            policy is intended to allow your therapist to continue to treat the
            patient (the couple or family unit) by preventing, to the extent
            possible, a conﬂict of interest to arise where an individual's
            interests may not be consistent with the interests of the unit being
            treated. For instance, information learned in the course of an
            individual session may be relevant or even essential to the proper
            treatment of the couple or the family. Other than that, your
            therapist will not disclose conﬁdential information about your
            treatment to anyone else unless all persons who participated in the
            treatment provide written authorization to release such information.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Psychotherapist-patient privilege</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . The information disclosed by you, as well as any records created,
            is subject to the psychotherapist-patient privilege. The
            psychotherapist-patient privilege results from the special
            relationship between a therapist and a patient in the eyes of the
            law. It is akin to the attorney-client privilege or the
            doctor-patient privilege. Typically, the patient is the holder of
            the psychotherapist-patient privilege. If your therapist receives a
            subpoena for records, deposition testimony, or testimony in a court
            of law, your therapist will assert the psychotherapist-patient
            privilege on your behalf until instructed, in writing, to do
            otherwise by you or your representative. You should be aware that
            you might be waiving the psychotherapist-patient privilege if you
            make your mental or emotional state an issue in a legal proceeding.
            You should address any concerns you might have regarding the
            psychotherapist-patient privilege with your attorney.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Patient litigation</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Your therapist will not voluntarily participate in any litigation,
            or custody dispute in which you and another individual, or entity,
            are parties. Your therapist has a policy of not communicating with
            your attorney and will generally not write or sign letters, reports,
            declarations, or afﬁdavits to be used in your legal matter. Your
            therapist will generally not provide records or testimony unless
            compelled to do so. Should your therapist be subpoenaed, or ordered
            by a court of law, to appear as a witness in an action involving
            you, you agree to reimburse Crossover for any time spent for
            preparation, travel, or other time in which your therapist has made
            him/herself available for such an appearance at then-current rates.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Completion of therapy.</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            {" "}
            The length of your therapy depends on the speciﬁcs of your situation
            and the progress achieved. As the completion of your goals is
            approaching, your therapist will discuss with you a plan for ending
            therapy. If during therapy you come to feel that the issues for
            which you are seeking therapy are not being satisfactorily addressed
            and you wish to see another therapist, referrals to other therapists
            will be given to you to assist in a smooth transition if you desire.
            If it becomes clear that you are not beneﬁtting from the therapy,
            your therapist is ethically bound to stop treating you and will
            provide you with referrals to other sources for therapy.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to ask your Provider what type of treatment they
            are planning based on your history, diagnosis, symptoms and testing
            results. You may also discuss with your therapist/counselor what the
            potential risks and beneﬁts of a speciﬁc treatment might be. You
            have the right to decline any portion of your treatment at any time
            or during your treatment session.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Your Provider reserves the right to terminate therapy at his/her
            discretion. Reasons for termination include, but are not limited to,
            failure to comply with treatment recommendations, conﬂicts of
            interest, and failure to participate in therapy, your needs are
            outside of your therapist's scope of competence or practice, or you
            are not making adequate progress in therapy. You have the right to
            terminate therapy at your discretion. Should you choose to end your
            therapy, one ﬁnal visit is generally recommended to facilitate a
            positive termination experience and give an opportunity to reﬂect on
            the work that has been done. Your Provider will also attempt to
            ensure a smooth transition to another therapist by offering
            referrals to you as appropriate to your needs at the point of
            termination.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Scope of Services</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Testing, forensic or court ordered counseling, education or
            learning disabilities, and custody evaluations are not covered as
            part of the services provided in the health center.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Licensure</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Your Provider is licensed as a mental health professional, and
            will provide you with licensure information at your request.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Emergency and After Hours</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Circumstances may arise when immediate assistance is required.
            Crossover Health is equipped with a conﬁdential voice mail system
            that allows you to leave a message at any time. Your Provider will
            make every effort to return calls within 24 hours (or by the next
            business day), but cannot guarantee the calls will be returned
            immediately. Crossover is unable to provide 24-hour crisis service.
            In the event that you are feeling unsafe or require immediate
            medical or psychiatric assistance, you should call 911, or go to the
            nearest emergency room.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>If receiving Optometric Services:</strong>
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "10pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The nature of the optometric services</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Optometric services can involve eye examinations for health and
            vision problems, pupillary dilation, and vision testing for
            prescription glasses or contact lens. A variety of tests and
            procedures can be used to help the clinical staff determine patient
            eye health and vision correction needs.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Analysis / Examination / Treatment</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . As a part of the analysis, examination, and treatment, you are
            consenting to the following possible procedures: pupillary dilation;
            vision testing; retinal photography, glaucoma screening and eye
            examination.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The material risks inherent in optometric services</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . The majority of examinations and procedures are pain-free and
            relatively easy. Objects such as lenses and medical equipment may
            come in close contact with the eyes during certain examinations.
            Pupillary dilation is pain-free, relatively easy, with minimal after
            effects. Evaluation of the retina/fundus without pupillary dilation
            may allow for sight and/or life threatening disease, hemorrhages,
            tumors, etc., to go undetected. Most patients suffer no loss of
            distance vision from the dilation drops, however, some experience a
            mild decrease. Near vision (arm length and closer) will be blurred
            2-4 hours afterwards. Bright lights and sunshine may be bothersome
            and a slight headache may occur. We will supply a pair of sunglasses
            if you did not bring your sun wear with you. Special precaution is
            recommended for driving, operation of heavy equipment, and in few
            cases, even walking.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The probability of those risks occurring</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Less than 1 in 40,000 patients suffer serious side effects from
            dilating drops. The symptoms are usually eye pain, intense headache
            and possible nausea and may not start immediately. If after leaving
            the office, you experience eye pain, cloudy or steamy vision or
            severe headache, call us immediately.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "9pt",
          marginTop: "9pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>CONSENT TO VIRTUAL SERVICES</u>
            </strong>
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            By Accepting this Universal Consent, you acknowledge that you
            understand and agree with the following:
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I have read and understand the information provided below regarding
            the use of virtual care, and all of my questions have been answered
            to my satisfaction. I have also read this document carefully, and
            understand the risks and benefits of virtual care and have had my
            questions regarding the care methods and technologies explained. By
            my Acceptance, including this specific informed consent for virtual
            services via Crossover Health Virtual technology (“XOV”), I hereby
            state that I have read, understood, consent to receive virtual care
            via XOV.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>The nature of the virtual services.&nbsp;</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Crossover provides virtual services, meaning you may not always see
            your provider in-person but rather will interact and receive care
            via certain available technologies included in the XOV as described
            below.&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you choose to receive virtual services via XOV, you should be
            aware of certain important information:
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The information obtained via XOV may be used for diagnosis, therapy,
            follow-up and/or education, and may include any of the following
            care delivery methods:&nbsp;
          </span>
        </span>
      </p>
      <ul style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Live 2-way audio and video technology
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Store-and-forward technology
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Medical images
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Patient medical records
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "disc",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Output data from medical devices and sound and video files
            </span>
          </span>
        </li>
      </ul>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            XOV will incorporate network and software security protocols
            designed to protect the confidentiality of patient identification
            and imaging data and will include measures designed to safeguard the
            data and to protect its integrity against intentional or
            unintentional corruption and in compliance with relevant state and
            federal laws.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Responsibility for patient care should remain with your local
            primary care clinician, if you have one, as should your patient
            medical records.&nbsp; However, if you choose to receive virtual
            care via XOV, you will receive such care as permitted in your state,
            and Crossover will maintain medical records and will provide them to
            your primary care provided as requested or required.&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prior to care being delivered via XOV, your Provider/s will ensure
            that the services and care delivery method/s are appropriate for
            management of your healthcare. Accordingly, all services may not be
            available virtually. You may decline to receive medical services via
            telehealth and may withdraw from such care at any time and to
            request services in an alternative format, such as through real-time
            telemedicine services or an in-person visit (which may require
            referral to a community provider).
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Benefits associated with virtual care</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Improved access to medical care by enabling a patient to remain in
            his/her local healthcare site (e.g. home) while the physician
            consults and obtains test results at distant/other sites; Efficient
            medical evaluation and management; and obtaining expertise of a
            specialist that may be difficult to obtain in person.
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>Possible Risks associated with virtual care.&nbsp;</strong>
          </span>
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            As with any medical procedure, there are potential risks associated
            with the use of virtual care via XOV. These risks include, but may
            not be limited to the following: (a) In rare cases, an additional
            in-person medical evaluation or rescheduled video consultation may
            be necessary in the event your healthcare provider is unable to
            gather all the clinical information necessary to formulate an
            appropriate plan of care (e.g., poor resolution of images); (b)
            Delays in evaluation or treatment could occur due to failures of the
            telehealth equipment. If this happens, you may be contacted by phone
            or other means of communication by the healthcare provider; (c) In
            rare cases, a lack of access to complete medical records may result
            in adverse drug interactions or allergic reactions or other judgment
            errors; and (d) Although the electronic systems we use will
            incorporate network and software security protocols to protect the
            privacy and security of health information, in rare instances,
            security protocols could fail, causing a breach of privacy of your
            personal health information.&nbsp;
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              DO NOT ACCEPT UNTIL YOU HAVE READ AND UNDERSTAND THE ABOVE.
            </strong>
          </span>
        </span>
      </p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to ask your Provider what type of treatment he or
            she is planning based on your history, diagnosis, symptoms and
            testing results. You may also discuss with your Provider what the
            potential risks and benefits of a specific treatment might be. You
            have the right to decline any portion of your treatment at any time
            or during your treatment session.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            In addition, you agree that you do not expect the Crossover
            Providers to be able to anticipate and explain all possible risks
            and complications of treatment, and you wish to rely on Crossover
            Providers to exercise judgment during the course of treatment, which
            Crossover Providers thinks at the time, based upon the facts then
            known, is in your best interest. You understand that results are not
            guaranteed.
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I have read or have had read to me the above explanation of the
            proposed ancillary services treatment. I have discussed it with my
            Crossover Provider and have had my questions answered to my
            satisfaction. By signing below I state that I have weighed the risks
            involved in undergoing treatment and have decided that it is in my
            best interest to undergo the treatment recommended. Having been
            informed of the risks, I hereby give my consent to treatment. I
            intend this consent form to cover the entire course of treatment for
            my present condition and for any future condition(s) for which I
            seek treatment.&nbsp;
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "0pt",
          marginTop: "0pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>COLLABORATING PROVIDER AGREEMENTS</u>
            </strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p
        style={{ lineHeight: "1.2", marginBottom: "0pt", marginTop: "0pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            When required by state laws, Advanced Practice Nurses and Physician
            Assistant Providers will collaborate with a Physician Provider in
            the delivery of care pursuant to a Collaboration
            Agreement.&nbsp;&nbsp;
          </span>
        </span>
      </p>
      <h2
        style={{
          lineHeight: "1.2",
          marginBottom: "4pt",
          marginTop: "18pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>PATIENT RIGHTS AND RESPONSIBILITIES</u>
            </strong>
          </span>
        </span>
      </h2>
      <p
        style={{ lineHeight: "1.2", marginBottom: "9pt", marginTop: "9pt" }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            As a partner in my health care, I have the following rights:
          </span>
        </span>
      </p>
      <ol style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to be treated with respect, consideration, and dignity.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to appropriate privacy while receiving care from
              Crossover Health.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to be provided, to the degree known, information
              concerning diagnosis, evaluation, treatment, and prognosis. When
              it is medically inadvisable to provide such information to me, the
              information will be provided to a person designated by me or to a
              legally authorized person.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to be provided an opportunity to participate in
              decisions involving my health care, except when such participation
              is contraindicated for medical reasons.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to change providers if other qualified providers are
              available.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to file a complaint or provide feedback regarding my
              care. To file a complaint with our practice, contact Crossover
              Health, 101 W. Avenida Vista Hermosa Suite 120, San Clemente, CA
              92672. All complaints must be submitted in writing. You will not
              be penalized for filing a complaint. It is your right.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to consent to or to refuse any treatment, telehealth
              consultation, or procedure or refuse to participate in research.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              When the need arises, a reasonable attempt will be made for health
              care professionals and other staff to communicate in the language
              or manner primarily used by me.
            </span>
          </span>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The right to the following information:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Services available from Crossover Health.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Provisions for after-hours and emergency care.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Fees for services.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Payment policies.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Advance directives, as required by state or federal law and
                  regulations.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The credentials of my health care provider.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The existence of malpractice insurance.
                </span>
              </span>
            </li>
          </ul>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "decimal",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: '"Helvetica Neue",sans-serif',
              fontSize: "12pt",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              As a partner in my health care, I have the following
              responsibilities:
            </span>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginTop: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will provide accurate health information to my health care
                  provider about any conditions that I may have and any
                  medications, including over-the-counter products and dietary
                  supplements I am taking, and any allergies or sensitivities
                  that I may have.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will schedule routine physical exams and other health
                  maintenance exams recommended to me by my health care provider
                  (pap smear, mammogram, bone density, colonoscopy, routine
                  blood tests, immunizations, etc.). I put myself at risk for
                  not detecting other medical diseases if I only see my health
                  care provider for immediate problems. I will make appointments
                  with my health care provider to discuss routine health
                  screenings.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will follow treatment plans recommended to me by my health
                  care provider, including completing testing, making an
                  appointment with a specialist, and taking my medications. I
                  will participate in my treatment plan and be sure to clearly
                  comprehend any treatment plan. I will ask questions when I do
                  not understand. I understand that not following my treatment
                  plans may put my health at risk.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will keep my appointments and reschedule any missed
                  appointments. I understand that my health care provider
                  schedules these appointments to follow up on my response to
                  treatment and to monitor my medical conditions. During these
                  appointments, my health care provider may order tests, refer
                  me to a specialist, change my medications, and diagnose a
                  medical problem. If I do not follow up, I may put my health at
                  risk and may have medical conditions go undetected.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I understand that the goal of Crossover Health is to provide
                  me with test results in a timely fashion. If I do not hear
                  from Crossover Health, I will call the office for test
                  results. I understand that not hearing from the office about a
                  particular test does not necessarily mean that the test result
                  is normal.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will inform my health care provider if my medical condition
                  changes, does not improve, or worsens. This will allow my
                  health care provider to re-evaluate my condition and make
                  changes in treatment. If I do not inform my health care
                  provider, I may put my health at risk.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will take charge of my health and try to make positive
                  choices for my health including not smoking, not using illegal
                  drugs, eating a healthy diet, and getting appropriate
                  exercise.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will take responsibility to understand the services provided
                  by Crossover Health and its limitations, and will ask
                  Crossover Health if I have any questions.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will provide a responsible adult to transport me home from
                  the Wellness Center and who will remain with me for
                  twenty-four (24) hours, if required by my healthcare provider.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will accept personal financial responsibility for any
                  charges not covered by my employer or the insurance provided
                  under the benefits plan through which I am eligible to access
                  Crossover Health’s services. I will ask questions if I have
                  any regarding coverage of services. I agree and understand
                  that Crossover Health is not a participating provider or
                  member of any third party insurance coverage other than the
                  benefits plan through which I am eligible to use Crossover
                  Health’s services (“Secondary Insurance”). I understand that
                  Crossover Health does not file insurance claims with Secondary
                  Insurance programs to obtain payment for services provided to
                  me.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "10.5pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I understand that almost no Crossover Health providers are
                  Medicare or Medicaid providers. Accordingly, neither Medicare
                  nor Medicaid will pay for services rendered by Crossover
                  Health even if such services when provided by other providers
                  are normally covered by Medicare or Medicaid. Prior to
                  receiving services, I will notify Crossover Health if I am a
                  Medicare or Medicaid beneficiary and will request pre-approval
                  should I desire reimbursement from a government payor.&nbsp;
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I will be respectful of all the health care professionals and
                  staff, as well as other patients.
                </span>
              </span>
            </li>
            <li
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "10.5pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                listStyleType: "circle",
                textDecoration: "none",
                verticalAlign: "baseline",
              }}
              dir="ltr"
              aria-level={2}
            >
              <span
                style={{
                  color: "#222222",
                  fontFamily: '"Helvetica Neue",sans-serif',
                  fontSize: "12pt",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: 400,
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  I understand Crossover Health includes certain primary health
                  services, vaccinations and point of care lab testing provided
                  or performed in a Crossover Health center at no cost to me
                  other than a copayment amount, if applicable under the health
                  benefits plan through which I am eligible to use Crossover
                  Health’s services. Depending upon my insurance coverage, I may
                  be responsible for costs of additional services I receive by
                  referral, such as outpatient diagnostic testing, vaccinations
                  not stocked at a Crossover Health center, and secondary care
                  or specialist providers. If I have any questions about my plan
                  coverage, I will contact my Employer benefits team or my
                  benefits administrator.
                </span>
              </span>
            </li>
          </ul>
        </li>
      </ol>
      <p
        style={{
          lineHeight: "1.2",
          marginBottom: "10pt",
          marginLeft: "36pt",
          marginTop: "10pt",
          textAlign: "center",
        }}
        dir="ltr"
      >
        <span
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "13.999999999999998pt",
          }}
        >
          <span
            style={{
              WebkitTextDecorationSkip: "none",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>
              <u>MISCELLANEOUS TERMS</u>
            </strong>
          </span>
        </span>
      </p>
      <ul style={{ marginBottom: 0, marginTop: 0, paddingInlineStart: "48px" }}>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Indemnity. You agree to indemnify, defend and hold harmless
                Crossover Health and their respective affiliates from and
                against all losses, liability, expenses, damages and costs,
                including reasonable attorney's fees, arising out of or related
                to any breach of this agreement, any negligent or wrongful
                action or omission by you related to your use of services
                through Crossover Health, or any negligent or wrongful use of
                the Crossover Health services (including, without limitation,
                infringement of third party intellectual property rights or
                negligent or wrongful conduct).
              </span>
            </span>
          </p>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Severability. The provisions of this Universal Consent are
                severable, and in the event any provision hereof is determined
                to be invalid or unenforceable, such invalidity or
                unenforceability shall not affect the validity or enforceability
                of the remaining provisions, but such provision shall be
                reformed, if reasonably possible, only to the extent necessary
                to make it enforceable.
              </span>
            </span>
          </p>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Entire Agreement. This Universal Consent, together with any
                Crossover Health rules or policies referred to herein,
                represents the complete agreement between you and Crossover
                Health concerning the subject matter hereof, and it replaces all
                prior oral or written communications concerning such subject
                matter. Crossover Health may modify this Universal Consent as
                permitted by law.
              </span>
            </span>
          </p>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Assignment. You may not assign, transfer or delegate this
                Universal Consent or any part of it without Crossover Health's
                prior written consent. Crossover Health may freely transfer,
                assign or delegate all or any part of this Agreement, and any
                rights and duties hereunder. The Universal Consent will be
                binding upon and inure to the benefit of the heirs, successors
                and permitted assignees of the parties.
              </span>
            </span>
          </p>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Waiver. Failure to exercise or delay in exercising any right
                hereunder, or failure to insist upon or enforce strict
                performance of any provision of this Universal Consent, shall
                not be considered waiver thereof, which can only be made by
                signed writing. No single waiver shall be considered a
                continuing or permanent waiver.
              </span>
            </span>
          </p>
        </li>
        <li
          style={{
            color: "#222222",
            fontFamily: '"Helvetica Neue",sans-serif',
            fontSize: "12pt",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            listStyleType: "circle",
            textDecoration: "none",
            verticalAlign: "baseline",
          }}
          dir="ltr"
          aria-level={2}
        >
          <p style={{ marginLeft: "36pt" }}>
            <span
              style={{
                color: "#222222",
                fontFamily: '"Helvetica Neue",sans-serif',
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: 400,
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Governing Law. This Universal Consent shall be governed by and
                construed in accordance with the laws of the state where the
                services are provided without giving effect to any choice of law
                rules or principles. Any cause of action or claim you may have
                with respect to Crossover Health must be commenced within one
                (1) year after it arises, except to the extent such limitation
                is not enforceable. To the fullest extent permitted by law, each
                party to this Universal Consent waives its or his or her right
                to a jury trial with respect to any dispute or other controversy
                arising from hereunder.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <div></div>
    </div>
  );
};

export default Markup;
