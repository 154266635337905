/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChallengeStringResponse,
    ChallengeStringResponseFromJSON,
    ChallengeStringResponseToJSON,
} from '../models/ChallengeStringResponse'
import {
    ClinicProviderConfigFetchResponse,
    ClinicProviderConfigFetchResponseFromJSON,
    ClinicProviderConfigFetchResponseToJSON,
} from '../models/ClinicProviderConfigFetchResponse'
import {
    EmployersResponse,
    EmployersResponseFromJSON,
    EmployersResponseToJSON,
} from '../models/EmployersResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    TimeSlotsFetchResponse,
    TimeSlotsFetchResponseFromJSON,
    TimeSlotsFetchResponseToJSON,
} from '../models/TimeSlotsFetchResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface DeleteAppointmentNoteRequest {
    id: string;
}
export interface GetBookableSlotsRequest {
    id: string;
    startDate: string;
    appointmentType: string;
    page?: string;
    endDate?: string;
    staffGlobalId?: string;
    timezone?: string;
    clinicId?: string;
    schedulingType?: GetBookableSlotsSchedulingTypeEnum;
}


export interface IDefaultApi {
      deleteAppointmentNote: (requestParameters: DeleteAppointmentNoteRequest) => Promise<void>
      getBookableSlots: (requestParameters: GetBookableSlotsRequest) => Promise<TimeSlotsFetchResponse>
      getChallengeStringBiometrics: () => Promise<ChallengeStringResponse>
      getClinicProviderConfigs: () => Promise<ClinicProviderConfigFetchResponse>
      getEmployers: () => Promise<EmployersResponse>
      deleteAppointmentNoteWithResponse: (requestParameters: DeleteAppointmentNoteRequest) => Promise<{ value: void, response: any}>
      getBookableSlotsWithResponse: (requestParameters: GetBookableSlotsRequest) => Promise<{ value: TimeSlotsFetchResponse, response: any}>
      getChallengeStringBiometricsWithResponse: () => Promise<{ value: ChallengeStringResponse, response: any}>
      getClinicProviderConfigsWithResponse: () => Promise<{ value: ClinicProviderConfigFetchResponse, response: any}>
      getEmployersWithResponse: () => Promise<{ value: EmployersResponse, response: any}>
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNoteRaw = async (requestParameters: DeleteAppointmentNoteRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAppointmentNote.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointment-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNote = async (requestParameters: DeleteAppointmentNoteRequest): Promise<void> => {
        const { runtime } = await this.deleteAppointmentNoteRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNoteWithResponse = async (requestParameters: DeleteAppointmentNoteRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteAppointmentNoteRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch all bookable slots
     * Fetch all bookable slots
     */
    getBookableSlotsRaw = async (requestParameters: GetBookableSlotsRequest): Promise<{ runtime: runtime.ApiResponse<TimeSlotsFetchResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookableSlots.');
        }

                        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBookableSlots.');
        }

                        if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
            throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getBookableSlots.');
        }

                                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.staffGlobalId !== undefined) {
            queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/timeslots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimeSlotsFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all bookable slots
     * Fetch all bookable slots
     */
    getBookableSlots = async (requestParameters: GetBookableSlotsRequest): Promise<TimeSlotsFetchResponse> => {
        const { runtime } = await this.getBookableSlotsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch all bookable slots
     * Fetch all bookable slots
     */
    getBookableSlotsWithResponse = async (requestParameters: GetBookableSlotsRequest): Promise<{ value: TimeSlotsFetchResponse, response: any}> => {
        const { runtime, response } = await this.getBookableSlotsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometricsRaw = async (): Promise<{ runtime: runtime.ApiResponse<ChallengeStringResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/challenge_string`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ChallengeStringResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometrics = async (): Promise<ChallengeStringResponse> => {
        const { runtime } = await this.getChallengeStringBiometricsRaw();
        return await runtime.value();
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometricsWithResponse = async (): Promise<{ value: ChallengeStringResponse, response: any}> => {
        const { runtime, response } = await this.getChallengeStringBiometricsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsRaw = async (): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigFetchResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/clinic_provider_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigs = async (): Promise<ClinicProviderConfigFetchResponse> => {
        const { runtime } = await this.getClinicProviderConfigsRaw();
        return await runtime.value();
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsWithResponse = async (): Promise<{ value: ClinicProviderConfigFetchResponse, response: any}> => {
        const { runtime, response } = await this.getClinicProviderConfigsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployersRaw = async (): Promise<{ runtime: runtime.ApiResponse<EmployersResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/employers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EmployersResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployers = async (): Promise<EmployersResponse> => {
        const { runtime } = await this.getEmployersRaw();
        return await runtime.value();
    }

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployersWithResponse = async (): Promise<{ value: EmployersResponse, response: any}> => {
        const { runtime, response } = await this.getEmployersRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetBookableSlotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}

export const fetchDefaultApi: IDefaultApi  = new DefaultApi();
