/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EnrollBiometricsRequestBody,
    EnrollBiometricsRequestBodyFromJSON,
    EnrollBiometricsRequestBodyToJSON,
} from '../models/EnrollBiometricsRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface EnrollDeviceBiometricsRequest {
    enrollBiometricsRequestBody?: EnrollBiometricsRequestBody;
}


export interface IEnrolledDeviceApi {
      enrollDeviceBiometrics: (requestParameters: EnrollDeviceBiometricsRequest) => Promise<void>
      enrollDeviceBiometricsWithResponse: (requestParameters: EnrollDeviceBiometricsRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class EnrolledDeviceApi extends runtime.BaseAPI {

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometricsRaw = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enroll_biometrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnrollBiometricsRequestBodyToJSON(requestParameters.enrollBiometricsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometrics = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<void> => {
        const { runtime } = await this.enrollDeviceBiometricsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometricsWithResponse = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.enrollDeviceBiometricsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchEnrolledDeviceApi: IEnrolledDeviceApi  = new EnrolledDeviceApi();
